import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-fuel-expense',
  templateUrl: './view-fuel-expense.component.html',
  styleUrls: ['./view-fuel-expense.component.css']
})
export class ViewFuelExpenseComponent implements OnInit {

  paramID: any;
  particularExpense: any;
  apiUrl = environment.domain;
  constructor(public activatedRoute: ActivatedRoute, public superAdminService: SuperAdminService, public toaster: ToastrService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramID = param.id
      this.getParticularFuelExpense(this.paramID)
    })

  }

  ngOnInit(): void {

  }

  getParticularFuelExpense(id: any) {
    this.superAdminService.getParticularFuelExpense(id).subscribe((res: any) => {
      if (res.status == true) {
        this.particularExpense = res.data[0]
        // console.log("checkinin",this.particularExpense)
      }
      else {
        if (res.status == 401) {
          this.toaster.error(res.message)
        } else {
          this.toaster.error(res.message)
        }
      }
    })
  }

  backTo() {
    window.history.back()
  }


}
