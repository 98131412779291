<div class="container-fluid">

    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">View E-way bill</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button (click)="backTo()"
                    ><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>

   
    <div class="contentbox mt-3">
        <div class="row customepadding  ">
            <div class="col-xl-3 ms-auto">
                <input class='search-box form-control' #data placeholder="Search" type="text" />
              </div>
            <div class="col-md-12">
               
                <div class="table-responsive">
                    <table mat-table matSort [dataSource]="dataSource" matSortActive="date" matSortDirection="desc"
                         class="row-border hover tablelist" (matSortChange)="sortData($event)">

                        <ng-container matColumnDef="LR_no">
                            <th mat-header-cell *matHeaderCellDef>System LR No.</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                > {{receipt.LR_no}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="e_way_bill_number">
                            <th mat-header-cell *matHeaderCellDef>E-way Bill Number</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                > {{receipt.road_permit_no}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="VehicleNo">
                            <th mat-header-cell *matHeaderCellDef>Vehicle No</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                               > {{receipt.vehicle_id}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ewaybillexpirydate">
                            <th mat-header-cell *matHeaderCellDef>E-way bill expire date</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                > {{receipt.ewaybillexpirydate  | date:'yyyy-MM-dd'}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let receipt; let i = index"
                                >{{Date(expiry.ewaybillexpirydate) >= currentDate ? 'Expire': 'Active' }}
                            </td>
                        </ng-container> -->

                        
                        <ng-container class="abcd" matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td class="text-danger" mat-cell *matCellDef="let receipt; let i = index"
                                >{{ this.ex[i][receipt.LR_no] }}
                                
                            </td>
                        </ng-container>

                        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>

                </div>
                <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>