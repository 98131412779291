import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LeftSideNavComponent } from './left-side-nav/left-side-nav.component';
import { TopSideNavComponent } from './top-side-nav/top-side-nav.component';



@NgModule({
  declarations: [LeftSideNavComponent, TopSideNavComponent],
  imports: [
    CommonModule,RouterModule
  ],
  exports:[
    TopSideNavComponent,
    LeftSideNavComponent
  ]

})
export class MycommonSuperAdminModule { }
