<div class="header">
    <h1>Other  Expense</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" >
            <div class="row">

                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Date of Expenses</label>
                        <input type="date" class="form-control" formControlName="dateOfExpenses"/>
                    </div>
                </div>
               
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Amount</label>
                        <input type="text" class="form-control" formControlName="fuelExpenseAmount" placeholder="Expense Amount" />
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Expense type</label>
                        <select name="" class="form-control form-select "id="" formControlName="paymentMode">
                            <option disabled value="">--Select Payment Mode--</option>
                            <option value="upi">UPI</option>
                            <option value="cash">Cash</option>
                            <option value="petroCard">Petro Card</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="form-group">
                        <label for="">Payment Mode</label>
                        <select name="" class="form-control form-select "id="" formControlName="paymentMode">
                            <option disabled value="">--Select Payment Mode--</option>
                            <option value="upi">UPI</option>
                            <option value="cash">Cash</option>
                            <option value="petroCard">Petro Card</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Description</label>
                        <textarea type="text" class="form-control" formControlName="fuelExpenseAmount" placeholder="Description"></textarea>
                    </div>
                </div>
               
              
                
                <!-- <div class="col-xl-12">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Fuel Slip</label>
                        <div class="d-flex align-items-center gap-3 podimgbox">
                            <input type="file" id="podImagectrl" hidden formControlName="podImage"   formControlName="fuelAttachment"  accept="image/*,.pdf,.doc"  (change)="fileChangeEventPODAttchment($event)" > -->
                            <!-- <button (click)="addPODFile()" mat-raised-button matTooltip="Upload"
                                                    class="btn btn-dark ms-2" type="submit"><i
                                                        class="fa-solid fa-upload"></i></button>-->
                                                        
                            <!-- <label for="podImagectrl">Choose File</label> -->
                            <!-- <p>No File Choosen</p> -->
                        <!-- </div>
                    </div>
                </div>
                <div class="col-xl-12 mb-3" *ngIf="url">
                    <div class="row">
                        <div class="col-xl-3 text-center">
                            <img src={{url}} class="img-fluid w-100 mb-3" alt="fuel Slip">
                            <a #showDoc [href]="docURL" target="_blank"></a> 
                            <p>{{this.fuelSlips[0].name}}</p>
                        </div>
    
                    </div>
                </div> -->


                <div class="col-lg-4 col-xl-4  col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Attachment </label>
                        <input type="file" accept="image/*,.pdf,.doc"
                            *ngIf="!fuelSlipPreview" id="cancelledChequectrl"
                            (change)="fileChangeEventPODAttchment($event)"
                            class="form-control shadow-none d-none"
                            placeholder="Enter Fuel Slip " formControlName="fuelAttachment">
                        <label class="uploadimgNewDesign" for="cancelledChequectrl"
                            *ngIf="!fuelSlipPreview">
                            <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                            <h5>Upload Fuel Slip </h5>
                        </label>
                        <div class="viewImage" *ngIf="fuelSlipPreview">
                            <div class="imgBoxp">
                                <button class="resetImg" (click)="resetimg('fuelSlip')"><i
                                        class="fa-solid fa-xmark"></i></button>
                                <img [id]="fuelSlipPreview"
                                    onClick="viewImages(this, 'fuelSlip')"
                                    [src]="fuelSlipPreview" alt="Fuel Slip" />
                            </div>
                        </div>
                    </div>

                </div>



                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5"
                    mat-raised-button (click)="fuelExpenseSubmit()" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
