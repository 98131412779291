import { TyreInventoryModule } from './superAdmin/tyre-inventory/tyre-inventory.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutSuperAdminComponent } from './superAdmin/main-layout/main-layout.component';
import { VehicleMaintenanceComponent } from './superAdmin/manage-expense-log/vehicle-maintenance/vehicle-maintenance.component';
import { OurownVehicleExpenseComponent } from './superAdmin/manage-expense-log/ourown-vehicle-expense/ourown-vehicle-expense.component';
import { ExpanceLayoutComponent } from './superAdmin/manage-expense-log/expance-layout/expance-layout.component';
import { ShipiingLayoutComponent } from './superAdmin/manage-shipping-parties/shipiing-layout/shipiing-layout.component';
import { LoadingSlipLayoutComponent } from './superAdmin/loading-slip/loading-slip-layout/loading-slip-layout.component';
import { ChallanListLayoutComponent } from './superAdmin/challan/challan-list-layout/challan-list-layout.component';
import { ReportPaymentLayoutComponent } from './superAdmin/report-payment/report-payment-layout/report-payment-layout.component';
import { RateChartLayoutComponent } from './superAdmin/rate-chart/rate-chart-layout/rate-chart-layout.component';
import { FuelLayoutComponent } from './superAdmin/fuel/fuel-layout/fuel-layout.component';
import { VehicleLayoutComponent } from './superAdmin/fuel/vehicle-layout/vehicle-layout.component';
import { TyreLayoutComponent } from './superAdmin/tyre-inventory/tyre-layout/tyre-layout.component';
import { TyreIssueLayoutComponent } from './superAdmin/tyre-issue/tyre-issue-layout/tyre-issue-layout.component';
const routes: Routes = [

  {
    path: 'superAdmin',
    component: MainLayoutSuperAdminComponent,
    //canActivate: [ActivateGuard],
    children: [
      {
        path: 'mydashboard',
        loadChildren: () => import('./superAdmin/mydashboard/mydashboard.module').then(m => m.MydashboardModule)
      },
      // {
      //   path: 'tyre-issue',
      //   loadChildren: () => import('./superAdmin/tyre-issue/tyre-issue.module').then(m => m.TyreIssueModule)
      // },
       {
        path: 'profile', 
        loadChildren: () => import('./superAdmin/profile/profile.module').then(m => m.ProfileModule)
      },
     
      {
        path: 'usermanagement',
        loadChildren: () => import('./superAdmin/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'lrmanagement',
        loadChildren: () => import('./superAdmin/LR-management/LR-management.module').then(m => m.LrManagementModule)
      },
      {
        path: 'manageExpense',
        loadChildren: () => import('./superAdmin/manage-expense/manage-expense.module').then(m => m.ManageExpenseModule)
      },
      {
        path: 'manageVehicle',
        loadChildren: () => import('./superAdmin/manage-vehicle/manage-vehicle.module').then(m => m.ManageVehicleModule)
      },
      {
        path: 'manageDriver',
        loadChildren: () => import('./superAdmin/manage-driver/manage-driver.module').then(m => m.ManageDriverModule)
      },

      {
        path: 'manageClient',
        loadChildren: () => import('./superAdmin/manage-client/manage-client.module').then(m => m.ManageClientModule)
      },
      {path:'miscellaneous-expenses', component:ExpanceLayoutComponent,
      loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)},
      // {
      //   path: 'officeExpense', component:ExpanceLayoutComponent,
      //   loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      // },
      // {
      //   path: 'officeExpense/AddOfficeExpense', component:OfficeExpenseComponent,
      //   loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      // },
      // {
      //   path: 'vehicleMaintenence', component:ExpanceLayoutComponent,
      //   loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      // },
      {
        path:'ownVehicleExpence', component:ExpanceLayoutComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
      {
        path: 'vehicleMaintenence/AddvehicleMaintenence', component:VehicleMaintenanceComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)

      },
      {
        path: 'ourownVehicleExpense', component:OurownVehicleExpenseComponent,
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)

      },
      {
        path: 'manageExpenseLog',
        loadChildren: () => import('./superAdmin/manage-expense-log/manage-expense-log.module').then(m => m.ManageExpenseLogModule)
      },
      {
        path: 'manageShippingParties', component:ShipiingLayoutComponent,
        loadChildren: () => import('./superAdmin/manage-shipping-parties/manage-shipping-parties.module').then(m => m.ManageShippingPartiesModule)
      },
      {
        path: 'loadingslips', component:LoadingSlipLayoutComponent,
        loadChildren: () => import('./superAdmin/loading-slip/loading-slip.module').then(m => m.LoadingSlipModule)
      },
      {
        path: 'challan', component:ChallanListLayoutComponent,
        loadChildren: () => import('./superAdmin/challan/challan.module').then(m => m.ChallanModule)
      },
      {
        path: 'reportPayment', component:ReportPaymentLayoutComponent,
        loadChildren: () => import('./superAdmin/report-payment/report-payment.module').then(m => m.ReportPaymentModule)
      },
      {
        path: 'rateChart', component:RateChartLayoutComponent,
        loadChildren: () => import('./superAdmin/rate-chart/rate-chart.module').then(m => m.RateChartModule)
      },
      {
        path: 'fuel', component:FuelLayoutComponent,
        loadChildren: () => import('./superAdmin/fuel/fuel.module').then(m => m.FuelModule)
      },
      // {
      //   path: 'tyreinventory', component:TyreLayoutComponent,
      //   loadChildren: () => import('./superAdmin/tyre-inventory/tyre-inventory.module').then(m => m.TyreInventoryModule)
      // },
      {
        path: 'tyreinventory', component:TyreLayoutComponent,
        loadChildren: () => import('./superAdmin/tyre-inventory/tyre-inventory.module').then(m => m.TyreInventoryModule)
      },
      {
        path: 'tyre-issue', component:TyreIssueLayoutComponent,
        loadChildren: () => import('./superAdmin/tyre-issue/tyre-issue.module').then(m => m.TyreIssueModule)
      },{
        path: 'fuel/vehicleList',component:VehicleLayoutComponent,
        loadChildren: () => import('./superAdmin/fuel/fuel.module').then(m => m.FuelModule)
      }
      
    ]
  },
  {
    path: 'superAuth',
    loadChildren: () => import('./superAdmin/auth/auth.module').then(m => m.AuthModule)
  },


  { path: '', pathMatch: 'full', redirectTo: '/superAuth/login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
