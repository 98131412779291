import { Component } from '@angular/core';

@Component({
  selector: 'app-vehicle-layout',
  templateUrl: './vehicle-layout.component.html',
  styleUrls: ['./vehicle-layout.component.css']
})
export class VehicleLayoutComponent {

}
