<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lsmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View LR</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">

            <div class="contentbox">
                <div class="text-center ">
                    <!--  [useExistingCss]="true"    styleSheetFile="../../../../assets/css/printcssn.css" -->
                    <button mat-raised-button [useExistingCss]="true" class="btn btn-outline-dark btn-sm px-4"
                        printSectionId="print-section2" ngxPrint>Print</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendEmail('print-section')">Email</button>
                </div>

                <div class="stgtinvoice" id="print-section2">
                    <table>
                        <tr>
                            <td>
                                <table class="textHead">
                                    <tr>
                                        <td>
                                            <p style="font-size: 16; font-weight: 600; color: #000; margin: 0;">GSTIN :
                                                06ATNPK1992P2ZU</p>
                                            <p style="font-size: 16; font-weight: 600; color: #000; margin: 0;">PAN NO :
                                                ATNPK1992P</p>
                                        </td>
                                        <td>
                                            <p class="subjecttext"
                                                style="font-weight: 400; text-decoration: underline;">SUBJECT TO
                                                FARIDABAD JURISDICTION</p>
                                        </td>
                                        <td>
                                            <p style="font-size: 16; font-weight: 600; color: #000; margin: 0;">
                                                (M)&nbsp;9899143886</p>
                                            <p style="font-size: 16; font-weight: 600; color: #000; margin: 0;">
                                                (M)&nbsp;9811376011</p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <table class="invoiceBrand">
                                                <tr>
                                                    <td>
                                                        <img src="../../../../assets/images/jpg/sgtc.png" alt="logo" />
                                                    </td>
                                                    <td>
                                                        <h1>Sarayyan Golden Transport Company</h1>
                                                        <h2>FLEET OWNERS & TRANSPORT CONTRACTORS</h2>
                                                        <h3>SHOP NO. 3, OPP PLOT NO. 80, SECTOR-25, NEAR BHARAT DHARAM
                                                            KANTA, FARIDABAD (HR)</h3>
                                                        <h4>E-mail : sgtcmajid@gmail.com</h4>
                                                    </td>

                                                </tr>
                                            </table>

                                        </td>
                                        <td></td>

                                    </tr>

                                </table>


                            </td>

                        </tr>
                        <tr>
                            <td>
                                <table class="noticeMaineBox">
                                    <tr>
                                        <td class="pt-0">
                                            <h1>AT OWNER'S RISK</h1>
                                            <div class="noticeBox">
                                                <h2>NOTICE</h2>
                                                <div class="textnotice p-2">
                                                    The consignment converted by this set of special lorry Receipt from
                                                    shall be stored at the destination under the control of the
                                                    Transport Operator and shall be delivered to or to the order of the
                                                    Consignee Bank Whose name is mentioned in the Lorry Receipt. If will
                                                    under no circumstances be delivered to anyone without the written
                                                    authority from the Consignee Bank of its order endorsed on the
                                                    Consignee Bank or on a seperate Letter of Authority.</div>
                                            </div>
                                        </td>
                                        <td class="px-4">
                                            <div class="noticeBox2">
                                                <h3>CONSIGNOR COPY</h3>
                                                <h4 class="text-start px-3">G.S.T. NO.</h4>
                                                <h5 class="text-start px-3">INSURANCE</h5>
                                                <div class="p-2">

                                                    <p>The costomer has stated that he has not insured the consignment
                                                    </p>
                                                    <p>or</p>
                                                    <p>he has insured the consignment</p>
                                                    <div class="insurancedetails">
                                                        <p>Company <span></span></p>
                                                    </div>
                                                    <div class="insurancedetails">
                                                        <p>Policy&nbsp;No <span></span></p>
                                                        <p>Date <span>{{this.particlarLR?.date |
                                                                date:'dd-MM-yyyy'}}</span></p>
                                                    </div>
                                                    <div class="insurancedetails">
                                                        <p>Amout<span></span></p>
                                                        <p>Date <span></span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="pt-0">
                                            <div class="insurancedetails justify-content-end">
                                                <p class="w-50">Date<span>{{this.particlarLR?.date |
                                                        date:'dd-MM-yyyy'}}</span></p>
                                            </div>
                                            <div class="noticeBox2 cautionBox">
                                                <h3>CAUTION</h3>

                                                <div class="px-2">

                                                    <p>This consignment will not be detained diverted re-routed or re
                                                        booking without Consingee Bank;s written permission will be
                                                        delivered at the destination.</p>

                                                </div>
                                                <h4 class="px-2">Address of the Delivery Office :</h4>
                                                <h5 class="mt-4 px-2">Truck No. - {{this.particlarLR?.vehicle_id}}</h5>
                                                <h5 class="border-0 px-2">Consignment Note:-
                                                    {{this.particlarLR?.manualLR_no}}</h5>

                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td class="printpadding" style="padding-top: 20px; padding-bottom: 20px; ">

                                <div class="noticeBox2">
                                    <table class="fromto">
                                        <tr>
                                            <td style="border-right: 1px solid #000;">From</td>
                                            <td style=" border-right: 1px solid #000;">{{this.particlarLR?.from}}
                                            </td>
                                            <td style="border-right: 1px solid #000; ">To</td>
                                            <td>{{this.particlarLR?.to}}</td>
                                        </tr>

                                    </table>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <table class="consignee_address">
                                    <tr>
                                        <td>
                                            <div class="insurancedetails flex-wrap">
                                                <p>Consignor's&nbsp;Name&nbsp;&&nbsp;Address
                                                    <span>{{this.particlarLR?.consignor_name ? this.particlarLR?.consignor_name : "N/A" }} &
                                                        {{this.particlarLR?.consignor_address ? this.particlarLR?.consignor_address : "N/A"}}</span></p>
                                                <p><span> </span></p>
                                            </div>
                                            <div class="insurancedetails flex-wrap">
                                                <p>Consignee's&nbsp;Name&nbsp;&&nbsp;Address
                                                    <span>{{this.particlarLR?.consignee_name ? this.particlarLR?.consignee_name : 'N/A'}} &
                                                        {{this.particlarLR?.consignee_address ? this.particlarLR?.consignee_address : "N/A"}}</span></p>
                                                <p><span></span></p>
                                            </div>
                                        </td>

                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td class="invoicetblemaine">
                                <div class="noticeBox2 border-0 p-1">
                                    <table class="invoiceTable">
                                        <thead>
                                            <tr>
                                                <td style="width: 100px; text-align: center;" rowspan="2">Method of
                                                    Packages</td>
                                                <td rowspan="2">Description (Said to Contain)</td>
                                                <td style="width: 100px; text-align: center;" rowspan="2">Weight Actual
                                                </td>
                                                <td style="width: 100px; text-align: center;" rowspan="2">Measurement
                                                    Charges</td>
                                                <td rowspan="2">Rate</td>
                                                <td colspan="2" style="border-bottom: 0;">Freight to Pay</td>
                                                <td colspan="2" style="border-bottom: 0;">Freight to Paid</td>

                                            </tr>
                                            <tr>

                                                <td style="border-top: 0;  border-right: 0;">Rs.</td>
                                                <td style="border-top: 0; border-left: 0;">P.</td>

                                                <td style="border-top: 0; border-right: 0;">Rs.</td>
                                                <td style="border-top: 0; border-left: 0;">P.</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="border-bottom: 0;">{{this.particlarLR?.no_of_package}}&nbsp;
                                                </td>
                                                <td style="border-bottom: 0; border-top: 0; ">
                                                    {{this.particlarLR?.description}}&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">
                                                    {{this.particlarLR?.quantity}}&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>Freight</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>Sr. Ch.</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>Hamali</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td rowspan="3" style="border-bottom: 0; border-top: 0; ">
                                                    <table border="0" class="invoiceinnerBox">
                                                        <tr>
                                                            <td>Booking is strictly at Owner's Risk and must be covered
                                                                through insurance Co. by Consignors/Consignees.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0">
                                                                    <tr>
                                                                        <td>Bill/Ch.No. :- {{this.particlarLR?.billchno}}</td>
                                                                        <td>Truck No. :-{{this.particlarLR?.vehicle_id}}
                                                                        </td>
                                                                        <td>Value</td>

                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>St.&nbsp;Charges</td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                            </tr>
                                            <tr>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>A.O.C.</td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                                <td class="text-end"></td>
                                            </tr>
                                            <tr>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td style="border-bottom: 0; border-top: 0; ">&nbsp;</td>
                                                <td>Kanta</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2" style=" border-top: 0; ">&nbsp;</td>
                                                <td rowspan="2" style=" border-top: 0; ">
                                                    <table class="gstwill">
                                                        <tr>
                                                            <td colspan="3"> GST WILL BE PAID BY</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Connsignor</td>
                                                            <td>Consignee</td>
                                                            <td>Transporter</td>

                                                        </tr>
                                                    </table>
                                                </td>
                                                <td rowspan="2" style=" border-top: 0; ">&nbsp;</td>
                                                <td rowspan="2" style=" border-top: 0; ">&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                            <tr>




                                                <td>G. Total</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                                <td class="text-end">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top: 20px;  ">
                                <div class="noticeBox2">
                                    <table class="fromto">
                                        <tr>
                                            <td style="border-right: 1px solid #000;">E-Way bill No. :-
                                                {{this.particlarLR?.road_permit_no}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <table class="signaturetble">
                                    <tr>
                                        <td>
                                            <p class="note">Carriers are not responsible for leakage & breakage, No-Re
                                                Booking Arrangement </p>
                                        </td>
                                        <td>
                                            <p class="payextra">Pay Extra Octroi as per Octroi Receipt if any</p>
                                        </td>
                                        <td>
                                            <h3 class="signature"><span>For</span> SARAYYAN GOLDEN TRANSPORT COMPANY
                                            </h3>
                                        </td>
                                    </tr>
                                </table>
                            </td>

                        </tr>
                    </table>
                </div>
                <div class="ahujaInvoice" hidden>
                    <table>
                        <tr>
                            <td style="width:80%;">
                                <table>
                                    <tr>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td style="padding-right: 5px;">
                                                        <img src="../../../../assets/images/ahujalogo.png"
                                                            class="img-fluid" width="150" height="150">
                                                    </td>
                                                    <td style="padding-left: 5px;">
                                                        <h1 style="font-size: 47px; font-weight: bold; color: #f8999f;">
                                                            Ahuja Transport Company</h1>
                                                        <h2
                                                            style="font-size:1.25rem; text-align: right; margin-bottom: 0;">
                                                            FLEET OWNER & TRANSPORT CONTRACTORS</h2>
                                                    </td>
                                                </tr>
                                            </table>

                                            <h3 style="font-size:13px; text-align: center; margin-bottom: 0;">DELHI,
                                                MAHARASHTRA, WEST BANGAL, HARYANA, PUNJAB, RAJASTHAN, BIHAR, HIMACHAL,
                                                U.P., M.P., GUJARAT & SOUTH</h3>
                                        </td>
                                    </tr>

                                </table>
                                <table class="addressBOx" style="width: 100%;">
                                    <tr>
                                        <td style="width:36%">
                                            <h4 style="font-size: 13px; text-align:left; margin-bottom: 0;">PAN:
                                                AAEPA0775C</h4>
                                            <h4 style="font-size: 13px; text-align:left;">GSTIN: 06AAEPA0775C1ZD</h4>
                                            <div class="serviceBox">
                                                <p style="font-size: 11px;">Service Tax@2.55(After abetment) on Gross
                                                    amount payble is to be paid extra by the consignor/consinee to the
                                                    govt. Deptt. directly for the consignment covered under this
                                                    document</p>
                                            </div>
                                        </td>
                                        <td style="width:64%" class="text-center">
                                            <h5 style="font-size: 16px;">Head Office: Plot No. 68, Sector-58,</h5>
                                            <h5 style="font-size: 16px;">Transport Nagar, Ballabhgarh, Faridabad</h5>
                                            <h5 style="font-size: 16px;">Contact Office: H.No. 21, Sector-15, Faridabad
                                                - 121007 Haryana</h5>
                                            <h5 style="font-size: 16px;">E-mail : ahuja.transport.co@gmail.com</h5>



                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <h4 style="font-size: 16px; font-weight:600; text-align:right;">(M) 9582260343,
                                    9711897196, 9810412342, 9810065143</h4>
                                <div class="undercarrigeBox">
                                    <h1>Under Carriage by Road Act, 2007</h1>
                                    <p>Registration N-o. : 498/STA/08/2016</p>
                                    <p>Issue Date : 19-08-2016</p>
                                    <p>Valid Upto : 18-08-2016</p>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td style="padding-top:5px" colspan="2">
                                <table class="noticeBoxMaine">
                                    <tr>
                                        <td>
                                            <div class="noticeBox">
                                                <h1 style="color:#f8999f; font-weight:700">NOTICE</h1>
                                                <p style="line-height: 18px;">The Consignment covered by this set to
                                                    Special Lorry Receipt Forms shall be stored at the destination under
                                                    the control of the Transport Operator and shall be delivered to or
                                                    to the order of the Consignee Bank or whose name is mentioned in the
                                                    Lorry Receipt it will under no circumstances be delivered to any one
                                                    without the written authority from the Consignee Bank or its order
                                                    endorsed on the Consignee copy or on a separate Letter</p>
                                                <hr>
                                                <p style="line-height: 18px;">No Credit on Inputs or Capital goods used
                                                    for provision of Service has been taken not the benefit of Ntfn No.
                                                    12/2003-ST. Service Tax as applicable by the Consignee /Consignor.
                                                </p>
                                            </div>
                                        </td>
                                        <td style="padding: 0 10px;">
                                            <div class="noticeBox drivercopy" style="border-color: #000;">
                                                <h1>DRIVER COPY</h1>
                                                <h2>At OWNER'S RISK</h2>
                                                <h3>INSURANCE</h3>
                                                <p>The customer has stated that:</p>
                                                <p><input type="checkbox"> He has not insured the consignment</p>
                                                <p><input type="checkbox"> He has Insured the consignment</p>
                                                <div class="insuImp">
                                                    <p style="margin-top: 3px;">Company <span
                                                            class="writeBox">{{this.companyName}}</span> </p>
                                                </div>
                                                <div class="insuImp">
                                                    <p style="flex: 0 0 190px;">Policy No. <span
                                                            class="writeBox"></span> </p>
                                                    <p>Date <span class="writeBox"></span> </p>
                                                </div>
                                                <div class="insuImp">
                                                    <p style="flex: 0 0 190px;">Amount <span class="writeBox"></span>
                                                    </p>
                                                    <p>Risk <span class="writeBox"></span> </p>
                                                </div>


                                            </div>
                                        </td>
                                        <td>
                                            <div class="noticeBox fromtoBOx" style="border-color: #000;">
                                                <h2>From :{{this.particlarLR?.from}}</h2>
                                                <h2>To :{{this.particlarLR?.to}}</h2>
                                                <h2>Truck No.:{{this.particlarLR?.vehicle_id}}</h2>
                                                <div class="insuImp">
                                                    <!-- <h2 style="padding:5px 0px 5px 15px; border: 0;">No.: ATC/23-24/608
                                                    </h2> -->
                                                    <h2 style="padding:5px 0px 5px 15px; font-weight: 600; border: 0;">
                                                        Manual LR No. : {{this.particlarLR?.manualLR_no}}
                                                    </h2>
                                                    <h2 style="padding:5px 0px 5px 15px; font-weight: 600; border: 0;">
                                                        Date : {{ particlarLR?.date | date:'dd-MM-yyyy' }}</h2>
                                                    <!-- <p style="color:#000">Date <span class="writeBox">06/03/2024</span>
                                                    </p> -->
                                                </div>
                                            </div>
                                        </td>


                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="nameaddressform">
                                    <tr>
                                        <td>Consignor's Name</td>
                                        <td>
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td style="width: 55%;">
                                                        <p>{{this.particlarLR?.consignor_name}} </p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex gap-3">
                                                            <h4>City</h4>

                                                            <p> {{ this.consignor_city}}</p>
                                                        </div>
                                                    </td>
                                                    <td style="padding-right: 0;">
                                                        <div class="d-flex gap-3">

                                                            <h4>GSTIN</h4>

                                                            <p>{{particlarLR?.consignor_GSTIN}} </p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Consignee Name</td>
                                        <td>
                                            <table style="width: 100%;">
                                                <tr>
                                                    <td style="width: 55%;">
                                                        <p>{{this.particlarLR?.consignee_name}} </p>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex gap-3">
                                                            <h4>City</h4>

                                                            <p> {{this.consignee_city}}</p>
                                                        </div>
                                                    </td>
                                                    <td style="padding-right: 0;">
                                                        <div class="d-flex gap-3">

                                                            <h4>GSTIN</h4>

                                                            <p>{{particlarLR?.GSTIN}} </p>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <table class="invoiceDetails">
                                    <thead>
                                        <tr>
                                            <td rowspan="2">No. of Pkgs.</td>
                                            <td rowspan="2">Description (Said to Contain)</td>
                                            <td colspan="2">Weight</td>
                                            <td rowspan="2">Rate</td>
                                            <td colspan="2">Freight to Pay</td>
                                            <td colspan="2">Freight to Pay</td>
                                        </tr>
                                        <tr>
                                            <td>Actual</td>
                                            <td>Charged</td>
                                            <td>Rs.</td>
                                            <td>P.</td>
                                            <td>Rs.</td>
                                            <td>P.</td>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td style="border-bottom:0;">&nbsp;{{this.particlarLR?.no_of_package}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.description}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.quantity}} , <br>
                                                {{this.particlarLR?.vehicleType}}</td>
                                            <td style="border-bottom:0;">&nbsp; {{this.particlarLR?.quantity}} , <br>
                                                {{this.particlarLR?.vehicleType}}</td>
                                            <td style="border-bottom:0;">&nbsp;</td>
                                            <td class="fw-semibold">Fraight</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">Surcharge</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">Hamil</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">St. Charge</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td style="border-top:0; border-bottom:0">&nbsp;</td>
                                            <td class="fw-semibold">A.O.C.</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-semibold" colspan="4">
                                                <table class="invoiceno" border="0">
                                                    <tr>
                                                        <td style="border-right: 1px solid #000; padding: 0;"
                                                            class="fw-semibold">Invoice No.
                                                            {{this.particlarLR?.InvoiceNo}}</td>
                                                        <td class="fw-semibold">E-way bill
                                                            No.{{this.particlarLR?.road_permit_no}}
                                                        <td>

                                                    </tr>
                                                </table>
                                            </td>
                                            <td style="border-top: 0; border-bottom: 0;">&nbsp;</td>
                                            <td class="fw-semibold">S.T.Ch.</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="fw-semibold" colspan="4">Rs. - {{this.particlarLR?.Frieght}}</td>

                                            <td style="border-top: 0;">&nbsp;</td>
                                            <td class="fw-semibold">G. Total</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <table class="signatureBox">
                                    <tr>
                                        <td>Subject to Faridabad Jurisdiction Only</td>
                                        <td class="text-end">Driver Signature</td>
                                        <td class="text-end">For Ahuja Transport Company</td>

                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>