import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expance-layout',
  templateUrl: './expance-layout.component.html',
  styleUrls: ['./expance-layout.component.css']
})
export class ExpanceLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
