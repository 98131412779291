import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.css']
})
export class ImagePreviewComponent implements OnInit {
  front = false;
  back = false
  imageURL:any
  imageURLback:any
  apiUrl= environment.domain;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any, public sanitizer: DomSanitizer) { }
  
  ngOnInit(): void {
    // console.log("preview$$$$$",this.data)
    if(typeof(this.data.file)=='string' || typeof(this.data.backFile)=='string'){
      console.log("KKKKKKK****",this.data.file)
      this.imageURL = this.apiUrl+'/'+this.data.file;
      console.log(this.imageURL)

      this.imageURLback = this.apiUrl+'/'+this.data.backFile;
      // console.log(this.imageURL, this.imageURLback);
      
    }
    else{
      const reader = new FileReader();
      reader.onload = () => {
        const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
        this.imageURL = value;
      }
      reader.readAsDataURL(this.data.file)
    }

    if(this.data.file){
      this.front = true
    }
    if(this.data.backFile) {
     
      this.back = true
    }

   
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
}

  

}
