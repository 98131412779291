import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  users:any = {};
  userdata: any;
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) { }
  ngOnInit(): void {
    this.getClientList();
  }
  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          let clientList = res.data;
          const userdatastringified = sessionStorage.getItem("userdata");
          this.userdata = JSON.parse(userdatastringified);
          let temp = [];
          this.userdata.clientList.map((x) => {
            clientList.filter(y => {
              if (y._id == x) temp.push(y.clientName)
            })
          })
          console.log("temptemptemp",temp)
          let clientName = temp;
          this.users = {
            title: this.userdata.title,
            firstname: this.userdata.first_name,
            lastname: this.userdata.last_name,
            email: this.userdata.email,
            mobile: this.userdata.mobile,
            status: this.userdata.isactive,
            clientList: clientName
          }
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  backTo() {
    window.history.back()
  }

}
