

<router-outlet></router-outlet>
<ngx-spinner></ngx-spinner>
<div class="imageGallary">
    <div class="innerGalary">
        <button class="btn closebtngal border-0" onClick="closeGalery()"><i class="fa-solid fa-xmark"></i></button>
        <h3 id="imageText">Deepak</h3>
       <div class="imgBoxgal" id="imgBoxgal">
       
        <!-- <img src="https://exploreapi.optimly.in/uploads\invoice\2024_02_17_6652WhatsApp Image 2023-12-21 at 13.03.04_feab65e6.jpg" class="img-fluid" alt="" srcset=""> -->
       </div>
    </div>
</div>