<div class="container-fluid">
    <div class="row py-2 justify-content-between px-3">
        <div class="col-2 col-md-1 mobileicon">
            <div class="navbarbtn"><img src="../../../assets/images/icons/mobile-expand.svg"></div>
        </div>
        <div class="col-md-11 col-8">
            <div class="row justify-content-end">
                <div class="col-md-1 col-3 my-auto text-center borderright">
                    <!-- <img src="../assets/images/icons/notification.svg" class="notificatios" alt=""
                        srcset="../assets/images/icons/notification.svg">
                    <div class="notificationblink"></div> -->
                </div>
                <div class="col-md-2 col-lg-1 col-5 col-sm-3 text-right customewidthtab m-0">
                    <!-- <div class="usersbx">
                        <div class="navuser">
                            <div class="userimg"><img src="../../../assets/images/icons/profile.svg"> </div> <img
                                src="../../../assets/images/icons/userdrop.svg">
                        </div>
                        <div class="user-details">
                            <div class="userdetailsinn">
                                <h6>Welcome!</h6>
                                <h5>User Name</h5>
                              
                                <ul>
                                    <li>
                                        <a routerLink="manageAccount/myProfile"><i class="fas fa-chevron-right"></i> My
                                            profile</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>