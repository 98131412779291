import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-vehicle',
  templateUrl: './date-wise-vehicle.component.html',
  styleUrls: ['./date-wise-vehicle.component.css']
})
export class DateWiseVehicleComponent implements OnInit {
  dtOptions: any = {};
  expenseArr: any = [];
  vehicleExpenseArray: any = [];
  myArr: any = [];
  paramId: any;
  users: any;
  month: any;
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCompany: any;
  paramSupervisor: any;
  year: any;
  supervisorID: any;
  veh_no: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      this.veh_no = param.vehicle_no
      console.log("param", param);



      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }

  ngOnInit(): void {
    this.getVehicleExpenseGroup();
    this.dtOptions = {
      dom: 'Bfrtip',
      
        buttons: [
          {
            extend: 'csv',
            text: 'Export'  
          }
          
        ]
      }
  }



  getVehicleExpenseGroup() {
    this.superadminService.getVehicleExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;

                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })

                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
                ///  console.log("myARR4", this.myArr)

                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })

                console.log("myARR4", this.myArr);

               

                console.log("myARR488998898767898765432345678987654345678", this.myArr)

                //debugger;
                // this.myArr = this.myArr.filter((x: any) => x._id.supervisorId === this.supervisorID._id);



                for (let i = 0; i < this.myArr.length; i++) {
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "LR_no": this.myArr[i]._id.LR_no, "TripID": this.myArr[i]._id.TripID, "balancePaid": this.myArr[i]._id.paymentReceived, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.actual_balance, "Date": this.myArr[i]._id.Date, "income": this.myArr[i]._id.totalIncome, "vehicleNo": this.myArr[i]._id.vehicleNo, "total_value": this.myArr[i]._id.total_value, "totalValue": this.myArr[i]._id.totalValue, "totalExpense": this.myArr[i]._id.totalExpense, "totalIncome": this.myArr[i]._id.totalIncome, "paymentDue": this.myArr[i]._id.paymentDue, "waitingCharge": this.myArr[i]._id.waitingCharge, "overLoadCharges": this.myArr[i]._id.overLoadCharges, "vehicle_Expense": this.myArr[i]._id.vehicle_Expense, "incomes": this.myArr[i]._id.incomes })

                }
                // console.log("MYARR", this.myArr)
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    })





                    this.vehicleExpenseArray = this.expenseArr;
                    console.log("this.vehicleExpenseArray", this.vehicleExpenseArray);

                    console.log("this.veh_no", this.veh_no);
                    
                    this.vehicleExpenseArray = this.vehicleExpenseArray.filter((item) => {
                      return item.vehicleNo === this.veh_no
                    })
                   
                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }

}
