import { Component, ViewChild } from '@angular/core';
import { SuperAdminService } from 'src/app/super-admin.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
@Component({
  selector: 'app-view-e-way-bill',
  templateUrl: './view-e-way-bill.component.html',
  styleUrls: ['./view-e-way-bill.component.css']
})
export class ViewEWayBillComponent {

  constructor(private superAdminService: SuperAdminService) { }
  receipts: any[] = [];
  expirydate: any = [];
  ex: any = [];
  exp: any[] = [];
  receipt: any;
  curr: any;
  expiredlr: any = []
  currentDate: any;
  dataSource: MatTableDataSource<any>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.getlrdetailss();
  }

  displayedColumns: any = ['LR_no', 'VehicleNo', 'e_way_bill_number', 'ewaybillexpirydate', 'status'];


  getlrdetailss() {
    this.superAdminService.getlrs().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        var rawdata = res.data;

        this.receipt = rawdata.map((x) => x.receipt)
        let currentDate = new Date();
        var nextday = currentDate.setDate(currentDate.getDate() + 1);
        //console.log("nextday",nextday);
        let expirydate = this.receipt.filter((x) => x.ewaybillexpirydate)
        //console.log("expirydateexpirydate",expirydate)
        // console.log("today", currentDate);
        for (let expiry of expirydate) {

          if (currentDate >= new Date(expiry.ewaybillexpirydate)) {
            //console.log("today =>", currentDate, new Date(expiry.ewaybillexpirydate));
            let data = { ...expiry };
            this.expiredlr.push(data);
            console.log("expiredlrexpiredlr", this.expiredlr);
          }
        }

        this.expirydate = this.expiredlr.map((x) => x.ewaybillexpirydate.split('T')[0])
        // console.log("jjjjj",this.expirydate)

        for (let i = 0; i <= this.expirydate.length; i++) {
          let currentDate = new Date();
          //  console.log("currentDatecurrentDate1234",currentDate.toISOString().split('T')[0]);
          this.currentDate = currentDate.toISOString().split('T')[0]
          // console.log("expirydateexpirydate1111",this.expirydate);

          //  for(let i = 0; i < this.expirydate.length; i++ ){

          //    if (this.expirydate[i] >= currentDate) {
          //          return "Expired";
          //        } else if (this.expirydate[i] < currentDate) {
          //          return "Active";
          //        } else {
          //          return "Active";
          //        }
          // }
          this.ex = this.expiredlr.map((x) => {
            let expires = x.ewaybillexpirydate.split('T')[0]
            // console.log("current Dtae", this.currentDate)
            let lr_NO = x.LR_no
            //console.log(lr_NO)
            return { [lr_NO]: expires >= this.currentDate ? "Active" : "Expired" }
          })
          //console.log("dsfds", this.ex);

        }

        this.dataSource = new MatTableDataSource(this.expiredlr);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })

  }

  getdatabydate() {
    console.log("expiry date", this.expiredlr)




  }

  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'LR_no':
          return compare(lower(a.LR_no), lower(b.LR_no), isAsc);
        case 'VehicleNo':
          return compare(lower(a.VehicleNo), lower(b.VehicleNo), isAsc);
        case 'e_way_bill_number':
          return compare(lower(a.e_way_bill_number), lower(b.e_way_bill_number), isAsc);
        case 'ewaybillexpirydate':
          return compare(a.ewaybillexpirydate, b.ewaybillexpirydate, isAsc);
        case 'status':
          return compare(lower(a.status), lower(b.status), isAsc);

        default:
          return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value) {
      return value ? value.toLowerCase() : value;
    }
  }



  backTo() {
    window.history.back()
  }

}
