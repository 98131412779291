<div class="header">
    <h1>Update POD</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" [formGroup]="editLR" (ngSubmit)="updateLRSubmit()">
            <div class="row">
                <!-- <div class="col-lg-2 col-xl-2 col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">POD Front</label>

                        <input type="file" id="podImagectrl" hidden formControlName="loadingSlipAttchment" class="form-control shadow-none d-none"
                            accept="image/*,.pdf,.doc" (change)="fileChangeEventtollAttachment($event)">
                            <label class="uploadimgNewDesign" for="permitctrl" >
                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                <h5>Upload POD Front</h5>
                            </label>
                        <button>
                            <label class="uploadimgNewDesign" for="podImagectrl">Choose File</label>
                        </button>

                    </div>
                </div>
                <div class="col-xl-12 mb-3" *ngIf="url">
                    <div class="row">
                        <div class="col-xl-3 text-center">
                            <img src={{url}} class="img-fluid w-100 mb-3" alt="fuel Slip">
                            <a #showDoc [href]="docURL" target="_blank"></a>
                            <p>{{this.Tollfiles[0].name}}</p>
                        </div>

                    </div>
                </div> -->

                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">POD Front</label>
                        <input type="file" accept="image/*,.pdf,.doc"
                        (change)="fileChangeEventtollAttachment($event)"
                        class="form-control me-1 shadow-none  d-none" id="podImagectrl"
                        formControlName="loadingSlipAttchment">
                        <label class="uploadimgNewDesign" for="podImagectrl"  *ngIf="!podFront">
                            <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                            <h5>Upload POD Front</h5>
                        </label>
                        <div class="viewImage" *ngIf="podFront">
                            <div class="imgBoxp">
                                <button class="resetImg" (click)="resetimg('podFront')"><i class="fa-solid fa-xmark"></i></button>
                                <img [id]="podFront" [src]="podFront" alt="POD Front" />
                            </div>
                        </div>
                    </div>
                </div>




                <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">POD Back</label>
                        <input type="file" accept="image/*,.pdf,.doc"
                        (change)="fileChangeEventloadingSlipBackAttchment($event)"
                        class="form-control me-1 shadow-none  d-none" id="loadingSlipAttchmentBackctrl"
                        formControlName="adharFront">
                        <label class="uploadimgNewDesign" for="loadingSlipAttchmentBackctrl"  *ngIf="!podBack">
                            <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                            <h5>Upload POD Back</h5>
                        </label>
                        <div class="viewImage" *ngIf="podBack">
                            <div class="imgBoxp">
                                <button class="resetImg" (click)="resetimg('podBack')"><i class="fa-solid fa-xmark"></i></button>
                                <img [id]="podBack" [src]="podBack" alt="POD Back" />
                            </div>
                        </div>
                    </div>
                </div>



                <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">POD Back</label>
                        <input type="file" id="loadingSlipAttchmentBackctrl" hidden
                            formControlName="loadingSlipAttchmentBack" accept="image/*,.pdf,.doc"
                            (change)="fileChangeEventloadingSlipBackAttchment($event)"> -->
                        <!-- <button (click)="addPODFile()" mat-raised-button matTooltip="Upload"
                                                    class="btn btn-dark ms-2" type="submit"><i
                                                        class="fa-solid fa-upload"></i></button>-->
                        <!-- <button>
                            <label class="uploadimgNewDesign" for="loadingSlipAttchmentBackctrl">Choose File</label>
                        </button> -->
                        <!-- <p>No File Choosen</p> -->

                    <!-- </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="row">
                        <div class="col-xl-3 text-center">
                            <div class="imgBoxp" *ngIf="backurl">
                                <img src={{backurl}} class="img-fluid w-100 mb-3" alt="fuel Slip">
                                <a #showDoc [href]="docURL" target="_blank"></a>
                                <p>{{this.loadingSlipsBack[0].name}}</p>
                            </div>
                        </div>

                    </div>
                </div> -->



                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5" mat-raised-button (click)="updateLRSubmit()"
                        [disabled]="submitBtn">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>