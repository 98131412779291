<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Our Own Vehicle Expense</h1>
                <button class="btn btn-dark btn-sm" matTooltip="Add Expense" matTooltipPosition="left" mat-raised-button routerLink="../addOwnVehicleExpence"><i class="fa-solid fa-plus"></i></button>
            </div>
            

        </div>
    </div>
    <div class="contentbox mt-3">
    <div class="row customepadding">
        <div class="col-md-12 table-responsive ">
           
            <table datatable class="row-border hover tablelist" >
                <thead>
                    <tr>
                        <td>Driver Name</td>
                        <td>Contact Number</td>
                       
                        <td>Father Name</td>
                        <td>License Number</td>

                        <td>Guarantor Name</td>
                        <td>Guarantor Contact Number</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <!-- <tbody>
                    <tr *ngFor="let user of users">
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.driver_name}}</td>
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.contactNumber}}</td>
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.fatherName}}</td>
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.licenseNumber}}</td>
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.guarantorName}}</td>
                        <td  [routerLink]="['/superAdmin/manageDriver/edit-driver', user._id]">{{user.guarantorContact}}</td>
                       
                        <td><i (click)="deleteDriver(user._id)" matTooltip="Delete" class="fa fa-trash" aria-hidden="true"></i></td>
                 

                    </tr>

                </tbody> -->
            </table>

        </div>
       
    </div>
    </div>
</div>