import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  Chart,
  PieController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  SubTitle,
  ArcElement,
  LineController,
  LineElement,
  PointElement,
  BarController,
  BarElement
} from 'chart.js';
import { TotalVehicleComponent } from '../total-vehicle/total-vehicle.component';
import { MatDialog } from '@angular/material/dialog';

Chart.register(
  ArcElement,
  LineController,
  LineElement,
  PieController,
  BarController,
  BarElement,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
  SubTitle
);

@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.css']
})
export class MydashboardComponent implements OnInit {
  vehicleExpenseArray = [];
  newbie: any = [];
  lrExAmountSum: number;
  sum: number = 0;
  sum2: number = 0;
  isRole4 = true;
  userroleid;
  generatedSum: any = [];
  invoiceArray = [];
  arrays = []
  dialogbtn: boolean = false;
  dispenseArray = [];
  expenseArray = [];
  lrExpenseArrays = [];
  lrAdminExpenseArrays = {};
  tripExpenseArray = [];
  tripDispenseArray = [];
  categorybiseExpenseAmountArray = [];
  view: any[] = [900, 400];
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Months';
  showYAxisLabel = true;
  yAxisLabel = 'Amount';
  legendTitle: string = 'Amount Type';
  colorScheme = {
    domain: ['#48c9b0', '#146c43', '#AAAAAA']
  };
  dispenseAmount = [];
  totalDispenseAmount = 0;
  ExpenseAmount = [];
  totalExpenseAmount = 0;
  monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  monthArrayNo = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  catArray = ["Office Expense", "Vehicle Maintenance"];
  monthbisetotaldispenseAmount = 0;
  monthbisetotalExpenseAmount = 0;
  abc: any;
  summary: any = {};
  vehicles: any = {
    totalVehicles: 0,
    documentsExpired: 0
  };
  monthExpenses: any = [];
  monthDispenses: any = [];
  @ViewChild('yearFilter') yearFilter: ElementRef;
  dtOptions: {};
  currentDate: any;
  years: Array<String> = [];
  year: number;
  ExpenseAmountCategoryWise: any;
  lrExpenseAmount: any;
  tripExpenseAmount: any;
  vehicleExpenseAmount: any;
  invoices: any;
  lorry_receipts: any;
  chart: Chart<"bar", any, string>;
  pageName: any;
  res = sessionStorage.getItem('shown');
  lrex: any = {};
  monthExpens: any = [];
  supLR: number;
  Total_lr_exp: any;
  general_expense_amount: any;
  sum_general_amount: number;
  new_general_amont: any = [];
  total_bar_sum: any = [];
  gen_exp_amount: any;
  temp_sum: any;
  totalAmoount: any;
  totalAmoount_lr: any;
  totalAmoount_lr_admin: any;
  totalAmoount_admin: any;
  total_admin_ExpenseAmount: Number = 0;


  constructor(private router: Router, private superadminService: SuperAdminService, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialog: MatDialog) { }


  ngOnInit() {
    this.spinner.show();
    this.getall_data()
    this.getGeneratedSumm();
    this.getLRExpenseMonthWise();
    this.get_gen_exp()



    this.userroleid = this.userSessionData.user_role_id[0];

    if (this.userroleid === 4) {
      this.isRole4 = false;
    }


    if (this.res === '1' && this.isRole4) {
      this.openDialog();
      sessionStorage.setItem('shown', '8')
    }
    for (let y = 2023; y <= 2024; y++) {
      this.years.push(String(y))
    }
    this.spinner.show();
    this.dtOptions = {
      order: false,
      paging: false,
      dom: "Bfrtip",

      buttons: [
        {
          extend: "csv",
          text: "Export",
        },
      ],
    },
      this.lrex = {
        order: false,
        paging: false,
        dom: "Bfrtip",

        buttons: [
          {
            extend: "csv",
            text: "Export",
          },
        ],
      };
    this.year = new Date().getFullYear();
    this.getDispense();

    // this.getSummary();
    this.getMasterDataCategoryGroup();
    this.getTripExpenseMonthWise();
    this.getVehicleExpenseMonthWise();
    this.spinner.show();
    this.superadminService.getVehicle().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status === true) {

        this.spinner.hide();
        this.vehicles.totalVehicles = res.data.length;
        let documentsExpired = 0;
        let currentDate = new Date();
        for (let vehicle of res.data) {
          if (new Date(vehicle['permitDueDate']) < currentDate ||
            new Date(vehicle['permitYearsDueDate']) < currentDate ||
            new Date(vehicle['pollutionExpiryDate']) < currentDate ||
            new Date(vehicle['roadTaxDate']) < currentDate ||
            new Date(vehicle['insuranceDueDate']) < currentDate ||
            new Date(vehicle['RCTaxDate']) < currentDate ||
            new Date(vehicle['fitnessDueDate']) < currentDate) {
            documentsExpired += 1;
          }
        }

        this.vehicles.documentsExpired = documentsExpired;
        // this.toastr.warning('Document Expired',`${this.vehicles.documentsExpired}`)
        const vehicals = new Chart("vehicals", {

          type: 'doughnut',
          data: {
            labels: ['Total Vehicles', 'Vehicles Document Expired'],
            datasets: [
              {
                data: [this.vehicles.totalVehicles, this.vehicles.documentsExpired],
                backgroundColor: ['#d9b02b', '#702bd9'],
                borderWidth: 3,
                borderColor: 'transparent',
                borderRadius: 0,
              }
            ]
          },
          options: {
            cutout: 100,
            layout: {
              padding: 0
            },
            plugins: {
              title: {
                display: false,
                text: 'Value each Currency in EUR'
              },

              legend: {
                display: true,
                position: 'bottom',

              },

            },
            maintainAspectRatio: false,
            responsive: true,


          }
        });
        this.spinner.hide();
      }
      else {
        alert(res.message);
      }
    });
  }
  ngAfterViewInit() {
    this.yearFilter.nativeElement.value = this.year
  }
  changeYear() {
    this.year = this.yearFilter.nativeElement.value;
  }



  get_gen_exp() {

    this.spinner.show();
    this.getExpense();
    this.spinner.hide();
  }

  getVehicleExpenseMonthWise() {
    this.spinner.show();
    this.superadminService.getVehicleExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.vehicleExpenseAmount = rawdata;
          console.log("this.vehicleExpenseAmount", this.vehicleExpenseAmount);
          this.changeYearVehicleExpense();

        } else {
          alert(res.message)
        }
      }, error => {

      })
  }






  changeYearDispense() {
    let dispenseAmountParticularYear = this.dispenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.dispenseArray = [];
    this.monthDispenses = [];
    this.monthArray.forEach((item) => {
      this.dispenseArray.push({
        "month": item,
        "amount": 0
      })
    });
    if (this.userSessionData.user_role_id[0] != 1) {
      dispenseAmountParticularYear = dispenseAmountParticularYear.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }
    dispenseAmountParticularYear.forEach(item => {
      this.dispenseArray[item._id.month - 1].amount += item.amount
    });
    this.dispenseArray.forEach(item => {
      this.monthDispenses.push(item.amount);
    });
    this.totalDispenseAmount = this.monthDispenses.reduce((acc, curr) => acc + curr, 0);
  }
  changeYearDispenseTrip() {
    let dispenseAmountParticularYear = this.dispenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.tripDispenseArray = [];
    this.monthDispenses = [];
    this.monthArray.forEach((item) => {
      this.tripDispenseArray.push({
        "month": item,
        "amount": 0
      })
    });
    if (this.userSessionData.user_role_id[0] != 1) {
      dispenseAmountParticularYear = dispenseAmountParticularYear.filter((item) => {
        return item._id.TripID == this.userSessionData._id
      })
    }
    dispenseAmountParticularYear.forEach(item => {
      this.tripDispenseArray[item._id.month - 1].amount += item.amount
    });
    this.tripDispenseArray.forEach(item => {
      this.monthDispenses.push(item.amount);
    })
    this.totalDispenseAmount = this.monthDispenses.reduce((acc, curr) => acc + curr, 0);
  }

  getLRExpenseMonthWise() {
    this.spinner.show();
    this.superadminService.getLRExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.lrExpenseAmount = rawdata;
          let lr_exp_amount = []
          lr_exp_amount = this.lrExpenseAmount.filter((x) => x.amount)

          for (let i = 0; i < this.lrExpenseAmount.length; i++) {
            let temps = []
            this.lrExpenseAmount.map((x) => {
              temps.push({ "id": x._id.supervisorId, "amount": x.amount })
              temps = temps.filter((x) => x.amount);
              const totalAmount_lr_admin = temps.reduce((sum, item) => sum + item.amount, 0);
              this.totalAmoount_lr_admin = totalAmount_lr_admin
            })
          }

          for (let i = 0; i < this.lrExpenseAmount.length; i++) {
            let temp = []
            if (this.userSessionData._id === this.lrExpenseAmount[i]._id.supervisorId) {
              this.lrExpenseAmount.map((x) => {
                if (this.userSessionData._id === x._id.supervisorId) {
                  temp.push({ "id": x._id.supervisorId, "amount": x.amount })
                  temp = temp.filter((x) => x.amount);
                  const totalAmount_lr = temp.reduce((sum, item) => sum + item.amount, 0);
                  this.totalAmoount_lr = totalAmount_lr
                }

              })

              temp.reduce((x) => {
                return this.temp_sum += x.amount
              }
              )

              this.gen_exp_amount = this.ExpenseAmount[i]

            }
          }



          this.newbie = this.lrExpenseAmount.map((x) => x.amount)
          this.newbie.forEach((e) => {
            return this.sum += e

          });
          this.changeYearLrExpense();
          this.spinner.hide();
        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }





  changeYearVehicleExpense() {
    let expenseAmountParticularYear = this.vehicleExpenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.vehicleExpenseArray = [];
    this.monthExpens = [];
    if (this.userSessionData.user_role_id[0] != 1) {
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }




    setTimeout(() => {


      this.chart?.destroy();
      this.chart = new Chart("expanceAmount", {
        type: 'bar',
        data: {
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: "Cash Dispense",
              data: this.monthDispenses,
              borderColor: 'transparent',
              backgroundColor: '#702bd9',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            }, {
              label: 'Expense',
              data: this.monthExpenses,
              borderColor: 'transparent',
              backgroundColor: '#d9b02b',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            }
          ]
        },
        options: {
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: false,
              text: 'Value each Currency in EUR'
            },
            legend: {
              display: true,
              position: 'bottom',
            },
          },
          maintainAspectRatio: false,
          responsive: true,

          scales: {
            x: {
              grid: {
                display: false,
                offset: true,
                color: '#575E7B',
              },
              ticks: {
                color: '#575E7B'
              }
            },
            y: {
              grid: {
                display: false,
                offset: false,
                color: '#575E7B',
              },
              ticks: {

                color: '#575E7B'
              }
            }
          }
        }
      });
      this.spinner.hide();
    }, 500);


    if (this.userroleid === 1) {
      this.vehicleExpenseArray = [];
      this.monthArray.forEach((item) => {
        this.vehicleExpenseArray.push({
          "month": item,
          "amount": 0
        })
      });

      expenseAmountParticularYear.forEach((item) => {
        this.vehicleExpenseArray[item._id.month - 1].amount += item.amount;
        // this.monthExpenses[item._id.month - 1] += item.amount;
      })
    }

    if (this.userroleid != 1) {
      this.vehicleExpenseArray = [];
      this.monthArray.forEach((item) => {
        this.vehicleExpenseArray.push({
          "month": item,
          "amount": 0
        })
      });

      expenseAmountParticularYear.forEach((item) => {
        this.vehicleExpenseArray[item._id.month - 1].amount += item.amount;
        // this.monthExpenses[item._id.month - 1] += item.amount;
      })

    }


  }





  changeYearExpense() {
    let expenseAmountParticularYear = this.ExpenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.expenseArray = [];
    this.monthExpenses = [];
    if (this.userSessionData.user_role_id[0] != 1) {
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }
    this.monthArray.forEach((item) => {
      this.expenseArray.push({
        "month": item,
        "amount": 0
      })
    });
    expenseAmountParticularYear.forEach(item => {
      this.expenseArray[item._id.month - 1].amount += item.amount
    });
    this.expenseArray.forEach(item => {
      this.monthExpenses.push(item.amount);
    });
    this.totalExpenseAmount = this.monthExpenses.reduce((acc, curr) => acc + curr, 0);
    //this.sum_general_amount = this.totalExpenseAmount

  }

  changeYearExpenseCategoryWise() {
    let expenseAmountParticularYearCategoryWise = this.ExpenseAmountCategoryWise.filter((item) => {
      return item._id.year == this.year
    })
    this.categorybiseExpenseAmountArray = []
    if (this.userSessionData.user_role_id[0] != 1) {
      expenseAmountParticularYearCategoryWise = expenseAmountParticularYearCategoryWise.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }
    const officeExpenseData = expenseAmountParticularYearCategoryWise.filter((item) => {
      return item._id.category[0] == 'Office Expense'
    })

    const vehicleMaintenanceData = expenseAmountParticularYearCategoryWise.filter((item) => {
      return item._id.category[0] == 'Vehicle Maintenance'
    })
    let temp = [];
    officeExpenseData.forEach((item) => {
      temp.push(item.totalExpenseAmount);
    })
    const officeExpenseTotal = temp.reduce((accumulator, currentvalue) => accumulator + currentvalue, 0);
    temp = [];
    vehicleMaintenanceData.forEach((item) => {
      temp.push(item.totalExpenseAmount);
    })
    const vehicleMaintenanceTotal = temp.reduce((accumulator, currentvalue) => accumulator + currentvalue, 0);
    this.categorybiseExpenseAmountArray.push(
      { "expenseCategory": "Office Expense", "amount": officeExpenseTotal },
      { "expenseCategory": "Vehicle Maintenance", "amount": vehicleMaintenanceTotal }
    );
  }
  changeSummary() {
    let invoices = this.invoices.filter((item => {
      return item.created_date.split('T')[0].split('-')[0] == this.year
    }))
    let lorry_receipts = this.lorry_receipts.filter((item => {
      return item.receipt.date.split('T')[0].split('-')[0] == this.year
    }))
    if (this.userSessionData.user_role_id[0] != 1) {
      invoices = invoices.filter(item => {
        return this.userSessionData.clientList.includes(item.clientId)
      })
      lorry_receipts = lorry_receipts.filter(item => {
        return this.userSessionData.clientList.includes(item.receipt.clientId)
      })
    }
    let runningLR = 0;
    lorry_receipts.forEach(lr => {
      if (lr.receipt.tripEndDate === null)
        runningLR += 1;
    });
    const totalLR = lorry_receipts.length
    const closedLR = totalLR - runningLR;
    let generatedInvoice = 0;

    invoices.forEach(element => {
      if (element.clientName !== null)
        generatedInvoice += element.invoice[0]['clientName'].length
    })
    const totalInvoice = closedLR;
    const pendingInvoice = totalInvoice - generatedInvoice;
    this.summary = {
      totalLR: totalLR,
      runningLR: runningLR,
      closedLR: closedLR,
      totalInvoice: totalInvoice,
      generatedInvoice: generatedInvoice,
      // pendingInvoice: pendingInvoice

    };
  }
  getSummary() {
    this.spinner.show();
    this.superadminService.getInvoice().subscribe((invoicesRes: { status: any, message: any, data: any }) => {
      if (invoicesRes.status === true) {
        this.spinner.hide();
        this.invoices = invoicesRes.data ? invoicesRes.data : [];
        this.spinner.show();
        this.superadminService.getlrs().subscribe((lrRes: { status: any, message: any, data: any }) => {
          if (lrRes.status === true) {
            this.spinner.hide();
            this.lorry_receipts = lrRes.data ? lrRes.data : [];
            this.changeSummary();

            // this.runningReceipts();
            // this.invoice();
            // this.compare(this.arrays,this.invoiceArray)

          }
          else {
            alert(lrRes.message);
          }
        });
      }
      else {
        alert(invoicesRes.message);
      }
    });
  }
  getDispense() {
    this.spinner.show();
    this.superadminService.getDispenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.dispenseAmount = rawdata;
          this.changeYearDispense();

        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }

  getDispenseTripWise() {
    this.spinner.show();
    this.superadminService.getTripDispenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.dispenseAmount = rawdata;
          this.changeYearDispense();

        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }



  getTripExpenseMonthWise() {
    this.spinner.show();
    this.superadminService.getTripExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.tripExpenseAmount = rawdata;
          console.log(" this.tripExpenseAmount", this.tripExpenseAmount);
          this.changeYearTripExpense();
        }
        else {
          alert(res.message);
        }

      }, error => {
      });
  }

  changeYearTripExpense() {
    let expenseAmountParticularYear = this.tripExpenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.tripExpenseArray = [];
    this.monthExpens = [];
    if (this.userSessionData.user_role_id[0] != 1) {
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }

    setTimeout(() => {
      this.chart?.destroy();
      this.chart = new Chart("expanceAmount", {
        type: 'bar',
        data: {
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Cash Dispense',
              data: this.monthDispenses,
              borderColor: 'transparent',
              backgroundColor: '#702bd9',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            },
            {
              label: 'Expense',
              data: this.monthExpenses,
              borderColor: 'transparent',
              backgroundColor: '#d9b02b',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            }
          ]

        },
        options: {
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: false,
              text: 'Value each Currency in EUR'
            },

            legend: {
              display: true,
              position: 'bottom',
            },

          },
          maintainAspectRatio: false,
          responsive: true,

          scales: {
            x: {

              grid: {
                display: false,
                offset: true,
                color: '#575E7B',
              },
              ticks: {

                color: '#575E7B'
              }
            },
            y: {
              grid: {
                display: false,
                offset: false,
                color: '#575E7B',
              },
              ticks: {

                color: '#575E7B'
              }
            }
          }
        }
      });
      this.spinner.hide();
    }, 500);


    setTimeout(() => {
      if (this.userroleid === 1) {
        this.tripExpenseArray = [];
        this.monthArray.forEach((item) => {
          this.tripExpenseArray.push({
            "month": item,
            "amount": 0
          })
        });

        expenseAmountParticularYear.forEach((item) => {
          this.tripExpenseArray[item._id.month - 1].amount += item.amount;
          this.monthExpenses[item._id.month - 1] += item.amount;
          this.totalExpenseAmount += item.amount;
        })



      }

      if (this.userroleid != 1) {
        this.tripExpenseArray = [];
        this.monthArray.forEach((item) => {
          this.tripExpenseArray.push({
            "month": item,
            "amount": 0
          })
        });

        expenseAmountParticularYear.forEach((item) => {
          this.tripExpenseArray[item._id.month - 1].amount += item.amount;
          this.monthExpenses[item._id.month - 1] += item.amount;
          this.totalExpenseAmount += item.amount;
        })

      }
    }
    )
  }



  getTripDispenseMonthWise() {
    this.superadminService
  }

  getExpense() {
    this.spinner.show();
    this.superadminService.getExpenseMonthWise()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          // this.spinner.hide();
          var rawdata = res.data;
          this.ExpenseAmount = rawdata;
          // let gen_ex_amount = this.ExpenseAmount;
          // gen_ex_amount.forEach

          for (let i = 0; i < this.ExpenseAmount.length; i++) {
            let temps = []
            this.ExpenseAmount.map((x) => {
              temps.push({ "id": x._id.supervisorId, "amount": x.amount })
              temps = temps.filter((x) => x.amount);
              const totalAmount_admin = temps.reduce((sum, item) => sum + item.amount, 0);
              this.totalAmoount_admin = totalAmount_admin

            })

          }


          for (let i = 0; i < this.ExpenseAmount.length; i++) {
            let temp = []
            if (this.userSessionData._id === this.ExpenseAmount[i]._id.supervisorId) {
              this.ExpenseAmount.map((x) => {
                if (this.userSessionData._id === x._id.supervisorId) {
                  temp.push({ "id": x._id.supervisorId, "amount": x.amount })
                  temp = temp.filter((x) => x.amount);
                  const totalAmount = temp.reduce((sum, item) => sum + item.amount, 0);
                  this.totalAmoount = totalAmount
                }

              })

              temp.reduce((x) => {
                return this.temp_sum += x.amount
              }
              )

              this.gen_exp_amount = this.ExpenseAmount[i]

            }
          }

          this.new_general_amont = this.ExpenseAmount.map((x) => x.amount);
          this.new_general_amont.forEach((e) => {
            return this.sum2 += e
          });



          //  this.total_bar_sum = this.sum + this.sum2
          this.changeYearLrExpense();





          this.changeYearExpense();

          
        }
        else {

          alert(res.message)
        }

      }, error => {

        this.toastr.error("Something went wrong! Please contact us")
      });
  }

  changeYearLrExpense() {
    // console.log("this.lrExpenseAmount1122334455", this.lrExpenseAmount)
    let expenseAmountParticularYear = this.lrExpenseAmount.filter((item) => {
      return item._id.year == this.year
    })
    this.lrExpenseArrays = [];
    this.monthExpens = [];
    if (this.userSessionData.user_role_id[0] != 1) {
      expenseAmountParticularYear = expenseAmountParticularYear.filter((item) => {
        return item._id.supervisorId == this.userSessionData._id
      })
    }
    //console.log("Month Array", this.monthArray)





    setTimeout(() => {
      this.chart?.destroy();
      this.chart = new Chart("expanceAmount", {
        type: 'bar',
        data: {
          labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          datasets: [
            {
              label: 'Cash Dispense',
              data: this.monthDispenses,
              borderColor: 'transparent',
              backgroundColor: '#702bd9',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            },
            {
              label: 'Expense',
              data: this.monthExpens,
              borderColor: 'transparent',
              backgroundColor: '#d9b02b',
              borderWidth: 3,
              barThickness: 15,
              borderRadius: 15,
              borderSkipped: false,
              barPercentage: 0.5,
            }
          ]

        },
        options: {
          layout: {
            padding: 0
          },
          plugins: {
            title: {
              display: false,
              text: 'Value each Currency in EUR'
            },

            legend: {
              display: true,
              position: 'bottom',
            },

          },
          maintainAspectRatio: false,
          responsive: true,

          scales: {
            x: {

              grid: {
                display: false,
                offset: true,
                color: '#575E7B',
              },
              ticks: {

                color: '#575E7B'
              }
            },
            y: {
              grid: {
                display: false,
                offset: false,
                color: '#575E7B',
              },
              ticks: {

                color: '#575E7B'
              }
            }
          }
        }
      });
      this.spinner.hide();
    }, 500);
    setTimeout(() => {

      this.general_expense_amount = expenseAmountParticularYear[0]?.amount;
      this.Total_lr_exp = expenseAmountParticularYear[0]?.amount
      if (this.userroleid === 1) {
        debugger;
        this.lrExpenseArrays = [];
        //  console.log("Himan shoe", this.lrExpenseArrays);
        this.monthArray.forEach((item) => {
          this.lrExpenseArrays.push({
            "month": item,
            "amount": 0
          })
        });
        // console.log("Himan", this.lrExpenseArrays);

        expenseAmountParticularYear.forEach(item => {
          const { month } = item._id;
          this.lrExpenseArrays[month - 1].amount += item.amount;
          // this.monthExpens[item._id.month - 1] += item.amount;
          // this.total_admin_ExpenseAmount += item.amount;
        });
        this.lrExpenseArrays.forEach(i => {
          this.monthExpenses.push(i.amount)
        })
        this.totalExpenseAmount = this.monthExpenses.reduce((acc, curr) => acc + curr)
      }

      //  console.log("expenseAmountParticularYear", expenseAmountParticularYear);


      if (this.userroleid != 1) {
        this.lrExpenseArrays = [];
        this.monthArray.forEach((item) => {
          this.lrExpenseArrays.push({
            "month": item,
            "amount": 0
          })
        });
        expenseAmountParticularYear.forEach(item => {
          // this.lrExpenseArrays[item._id.month - 1].amount = item.amount;
          // this.monthExpens[item._id.month - 1] += item.amount;
          const { month } = item._id;
          this.lrExpenseArrays[month - 1].amount = item.amount;

          this.totalExpenseAmount = this.monthDispenses.reduce((acc, curr) => acc + curr, 0);
        })
        this.lrExpenseArrays.forEach(i => {
          this.monthExpenses.push(i.amount)
        })

        // this.lrExpenseArrays.forEach(item => {
        //   let temp = []
        //   temp.push(item.amount)
        //   this.monthExpens.push(temp);
        // });

      }



    }, 1000)

  }

  getMasterDataCategoryGroup() {
    this.spinner.show();
    this.superadminService.getMasterDataCategoryGroup()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          //alert(res.message)
          this.ExpenseAmountCategoryWise = res.data;
          this.changeYearExpenseCategoryWise();

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
        this.toastr.error('internal server error');
      })
  }
  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }
  backTo() {
    window.history.back()
  }

  totalVehicle(value: any) {
    let vehicle = value

    switch (vehicle) {
      case 'TotalVehicle':
        return this.router.navigate(['totalVehicles/Total Vehicles/totalVehicles'], { queryParams: { param: value } });

      case 'ExpiredDocuments':
        return this.router.navigate(['totalVehicles/Expired Documents/expiredDocuments'], { queryParams: { param: value } })
    }

  }

  invoice() {
    this.invoices = this.invoices.filter(e => {
      if (this.invoices[0].invoice[0]['LR_no'][0])
        return e
    })
    this.invoiceArray.push(this.invoices[0].invoice[0]['LR_no'][0])
  }
  changeLRParam(value: any) {
    let lr = value

    switch (lr) {

      case 'totalLR':
        return this.router.navigate(['totalLR/Total LR/totalLR'], { queryParams: { param: value } });

      case 'runningLR':
        return this.router.navigate(['totalLR/Running LR/runningLR'], { queryParams: { param: value } });

      case 'closedLR':
        return this.router.navigate(['totalLR/Closed LR/closedLR'], { queryParams: { param: value } })

    }
  }

  changeInvoiceParam(value: any) {
    this.spinner.show();
    let invoice = value

    switch (invoice) {

      case 'totalInvoice':
        return this.router.navigate(['totalLR/Total Invoice/closedLR'], { queryParams: { param: value } });

      case 'generatedInvoiceUsingCompanyName':
        return this.router.navigate(['totalInvoice/Generated Invoice Using Company Name/generatedInvoice'], { queryParams: { param: value } });

      case 'generatedInvoiceUsingTripID':
        return this.router.navigate(['totalInvoiceTripID/Generated Invoice Using TripID/generatedInvoice'], { queryParams: { param: value } })

      case 'pendingInvoiceUsingCompanyName':
        return this.router.navigate(['totalInvoice/Pending Invoice Using Company Name/closedLR'], { queryParams: { param: value } });

      case 'pendingInvoiceUsingTripID':
        return this.router.navigate(['totalInvoice/Pending Invoice Using TripID/closedLR'], { queryParams: { param: value } });
    }
    this.spinner.hide();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TotalVehicleComponent, {
      data: { data: 'dialogBtn', heading: 'Document Expired' },
      width: "80%",
      maxHeight: "90vh",


    });


    dialogRef.afterClosed().subscribe(result => {

    });

  }
  compare(first, second) {
    for (var i = 0; i < second.length; i++) {
      var arrlen = first.length;

      for (var j = 0; j < arrlen; j++) {
        if (second[i] == first[j]) {
          first = first.slice(0, j).concat(first.slice(j + 1, arrlen))
        }
      }
    }

  }

  getGeneratedSumm() {
    this.spinner.show();
    this.superadminService.getSummary()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data
          this.generatedSum = rawdata



        }
      })
  }
  getall_data() {
    this.spinner.show();
    this.superadminService.getlrs().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.spinner.hide();
        let rawdata = res.data

        rawdata = rawdata.map((x) => x.receipt)
        let supervisor_id = this.userSessionData._id
        let temps: any[] = rawdata.filter((x: any) => x.supervisorId == supervisor_id)
        this.supLR = temps.length

      }
    }
    )
  }

}
