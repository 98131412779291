import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuperAdminService } from 'src/app/super-admin.service';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { PODStatusComponent } from '../pod-status/pod-status.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-lr-list',
  templateUrl: './lr-list.component.html',
  styleUrls: ['./lr-list.component.css']
})
export class LRListComponent implements OnInit {
  Tollfiles: Array<File> = [];
  cc: any;
  TollfilesString = [];
  particlarLR: any;
  previewLoadingSlipURL: any;
  isViewShown: true;
  dtOptions: DataTables.Settings = {};
  filterform = new FormGroup({
    ClientName: new FormControl(''),
    month: new FormControl('')
  });
  @ViewChild("showLoadingSlipPreview") showLoadingSlipPreview: ElementRef;
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  apiUrl = environment.domain;
  displayedColumns: any = ['manualLR_no', 'LR_no', 'TripID', 'vehicle_id', 'clientName', 'consignee_name', 'consignor_name', 'POD_Status', 'date', 'from', 'to','total_value', 'vehicleRate', 'advance', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  receipts: any;
  userroleid;
  clientList;
  userSessionData;
  editLR = new FormGroup({
    loadingSlipAttchment: new FormControl(""),
  })

  rawdatas: any;
  onlyReceipts: any[];
  finalData: any[];
  rawdata: any;
  userdata: any;
  finalClientName: any[];
  dattaaa: any;
  dattaaas: any;
  dat: any;
  forclientID: any[];
  forclientname: any;
  cliname: any[];

  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner: NgxSpinnerService, private sanitizer: DomSanitizer, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    this.clientList = this.userSessionData?.clientList;
    this.userroleid = this.userSessionData.user_role_id[0];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: []
    };
    this.spinner.show();
    this.superAdminService.getlrs()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
         console.log("res data",res)

          let unsorted_data = res.data?.map((x: any) => x.receipt) || [];
          //console.log("unsorted_data",unsorted_data)
          this.receipts = unsorted_data.sort(function (a: any, b: any) {
            return <any>new Date(b.created_date) - <any>new Date(a.created_date);
          });
         console.log("this.receipts@#@#@#",this.receipts)

          this.superAdminService.getClientList()
            .subscribe((clients: { status: any, message: any, data: any }) => {
              if (res.status == true) {
                console.log("res data1111",res.data)
                const name_list = clients.data
                console.log("name_list",name_list)
                let main_data = this.receipts.map((ele: any) => {
                  let clientName: any = name_list.find((e: any) => ele.clientId == e._id)?.clientName || ''
                  return ({ ...ele, "clientName": clientName })
                })
                 console.log("total_value",main_data.total_value)

                if (this.userSessionData.user_role_id[0] !== 2) {
                  this.dataSource = new MatTableDataSource(main_data);
                } else {
                  let supervisor_id = this.userSessionData._id
                  let temps: any[] = main_data.filter((x: any) => x.supervisorId == supervisor_id)
                   console.log("workinnn22", temps)
                  this.dataSource = new MatTableDataSource(temps);
                }
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.spinner.hide();

              }
              else {
                if (res.status == 401) {
                  this.spinner.hide();
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');
                } else {
                  this.toastr.error(res.message);
                  this.spinner.hide();
                }
              }

            }, error => {
            });
        }
        else {
          if (res.status == 401) {
            this.spinner.hide();
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }

      }, error => {
        this.toastr.error('Something went wrong! Please contact us');
        this.spinner.hide();
      });

  }

  isArray = false;

  filterdata() {
    this.isArray = true;           // to check if data is in array or not.
    var form = {
      "ClientName": this.filterform.get("ClientName").value,
      "month": this.filterform.get("month").value
    }

    this.superAdminService.filterData({ form })
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.receipts = [];
          this.receipts = rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });


  }

  emitdata(params) {
    sessionStorage.setItem("userdata", JSON.stringify(params));
  }

  deleteuser(email) {
    this.superAdminService.deleteuser(email)
      .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          alert(res.message)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  filterData(value) {
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'consignee_name':
          return compare(lower(a.consignee_name), lower(b.consignee_name), isAsc);
        case 'consignor_name':
          return compare(lower(a.consignor_name), lower(b.consignor_name), isAsc);
        case 'clientName':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'from':
          return compare(lower(a.from), lower(b.from), isAsc);
        case 'to':
          return compare(lower(a.to), lower(b.to), isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value) {
      return value ? value.toLowerCase() : value;
    }
  }

  previewLoadingSlip(imageUrl: any, backImageUrl: any) {
    const url = imageUrl;
    const backUrl = backImageUrl;
    const dialogRef = this.dialog.open(ImagePreviewComponent, {
      width: "80%",
      // height: "90vh",
      data: { file: url, backFile: backUrl },
    });
  }

  podStatus(id: any) {
    const dialogRef = this.dialog.open(PODStatusComponent, {
      width: '40%',
      panelClass: 'addexpenceLoading',
      // height: '25vh',
      data: { id },
    })
  }

  fileChangeEventtollAttachment(fileInput: any) {
    if (fileInput.target.files.length) {
      this.Tollfiles = [];
      for (var i = 0; i < fileInput.target.files.length; i++) {
        this.Tollfiles.push(fileInput.target.files[i]);
      }
    }

    // this.filesToUpload.push(fileInput.target.files[0] );
  }


  getlr(){
    this.spinner.show();
    this.superAdminService.getlrs()
    .subscribe((res: { status: any, message: any, data: any }) => {

      if (res.status == true) {
       //console.log("res data",res)
       this.spinner.hide();

        let unsorted_data = res.data?.map((x: any) => x.receipt) || [];
        //console.log("unsorted_data",unsorted_data)
        this.receipts = unsorted_data.sort(function (a: any, b: any) {
          return <any>new Date(b.created_date) - <any>new Date(a.created_date);
        });
       // console.log("this.receipts",this.receipts)
       this.spinner.show();
        this.superAdminService.getClientList()
          .subscribe((clients: { status: any, message: any, data: any }) => {
            if (res.status == true) {
              this.spinner.hide();
              console.log("res data1111",res.data)
              const name_list = clients.data
              console.log("name_list",name_list)
              let main_data = this.receipts.map((ele: any) => {
                let clientName: any = name_list.find((e: any) => ele.clientId == e._id)?.clientName || ''
                return ({ ...ele, "clientName": clientName })
              })
               console.log({ main_data })

              if (this.userSessionData.user_role_id[0] !== 2) {
                this.dataSource = new MatTableDataSource(main_data);
              } else {
                let supervisor_id = this.userSessionData._id
                let temps: any[] = main_data.filter((x: any) => x.supervisorId == supervisor_id)
                // console.log("workinnn22", temps)
                this.dataSource = new MatTableDataSource(temps);
              }
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.spinner.hide();

            }
            else {
              if (res.status == 401) {
                this.spinner.hide();
                this.toastr.error(res.message);
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');
              } else {
                this.toastr.error(res.message);
                this.spinner.hide();
              }
            }

          }, error => {
          });
      }
      else {
        if (res.status == 401) {
          this.spinner.hide();
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');

        } else {
          this.toastr.error(res.message);
          this.spinner.hide();
        }
      }

    }, error => {
      this.toastr.error('Something went wrong! Please contact us');
      this.spinner.hide();
    });

  }
  

  deleteLR(_id) {
    console.log("working", _id)
    if(this.superAdminService.deleteDailogBox()){
    this.superAdminService.deleteLR(_id)
      .subscribe((res: { status: any, message: any }) => {

        if (res.status == true) {
          // alert("Are you sure you want to delete")
          this.toastr.success(res.message)
          this.getlr();
         // window.sessionStorage.clear();
          
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
  }z

 
}
