<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Add New Vehicle</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/manageVehicle">Manage Vehicle</li>
                    <li class="breadcrumb-item active" aria-current="page">Add New Vehicle</li>

                </ol>
            </nav>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addNewVehicle" (ngSubmit)="addNewVehicleSubmit()" enctype="multipart/form-data">
                <div class="contentbox">
                    <div class="row account mb-3">
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Vehicle Number</label>
                                <input type="text" id="vehicleNumberctrl" class="form-control shadow-none"
                                    placeholder="Enter Vehicle Number" formControlName="vehicleNumber">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Vehicle Type</label>
                                <input type="text" id="vehicleTypectrl" class="form-control shadow-none"
                                    placeholder="Enter Vehicle Type" formControlName="vehicleType"
                                    [matAutocomplete]="autoVehicleType">
                                <mat-autocomplete autoActiveFirstOption #autoVehicleType="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredVehicleTypes | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- <select id="vehicleTypectrl" formControlName="vehicleType"
                                    placeholder="Select Vehicle Type" class="form-control shadow-none">
                                    <option disabled value="">--Select Vehicle Type--</option>
                                    <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                        {{c.vehicletype_name}}</option>

                                </select> -->

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Owner Name</label>
                                <input type="text" id="ownerNamectrl" class="form-control shadow-none"
                                    placeholder="Enter Owner Name" formControlName="ownerName">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Driver Name</label>
                                <input type="text" id="driverNamectrl" class="form-control shadow-none"
                                    placeholder="Enter Driver Name" formControlName="driverName"
                                    [matAutocomplete]="autoDriverName">
                                <mat-autocomplete autoActiveFirstOption #autoDriverName="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredDriverName | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                                <!-- <select id="driverNamectrl" formControlName="driverName"
                                    placeholder="Select Driver Name" class="form-control shadow-none">
                                    <option disabled value="">--Select Driver Name--</option>
                                    <option *ngFor="let d of driver" value="{{d.driver_name}}">{{d.driver_name}}
                                    </option>

                                </select> -->

                            </div>

                        </div>

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Maker Name</label>
                                <input type="text" id="makerNamectrl" class="form-control shadow-none"
                                    placeholder="Enter Maker Name" formControlName="makerName">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Chassis Number</label>
                                <input type="text" id="chassisNumberctrl" class="form-control shadow-none"
                                    placeholder="Enter Chassis Number" formControlName="chassisNumber">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Engine Number</label>
                                <input type="text" id="engineNumberctrl" class="form-control shadow-none"
                                    placeholder="Enter Engine Number" formControlName="engineNumber">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Permit Number</label>
                                <input type="text" id="permitNumberctrl" class="form-control shadow-none"
                                    placeholder="Enter Permit Number" formControlName="permitNumber">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Policy Number</label>
                                <input type="text" id="policyNumberctrl" class="form-control shadow-none"
                                    placeholder="Enter Policy Number" formControlName="policyNumber">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">City</label>
                                <input type="text" id="cityctrl" class="form-control shadow-none"
                                    placeholder="Enter City" formControlName="city">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Permit Due Date</label>
                                <input type="date" id="permitDueDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Permit Due Date" formControlName="permitDueDate">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Permit 5 Years Due Date</label>
                                <input type="date" id="permitYearsDueDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Permit 5 Years Due Date" formControlName="permitYearsDueDate">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Purchase Date</label>
                                <input type="date" id="purchaseDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Purchase Date" formControlName="purchaseDate">

                            </div>

                        </div>

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Road Tax Date</label>
                                <input type="date" id="roadTaxDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Road Tax Date" formControlName="roadTaxDate">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Insurance Due Date</label>
                                <input type="date" id="insuranceDueDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Insurance Due Date" formControlName="insuranceDueDate">

                            </div>

                        </div>
                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Fitness Due Date</label>
                                <input type="date" id="fitnessDueDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Fitness Due Date" formControlName="fitnessDueDate">

                            </div>

                        </div>

                        <div class="col-lg-3 col-xl-3 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Pollution Expiry Date</label>
                                <input type="date" id="pollutionExpiryDatectrl" class="form-control shadow-none"
                                    placeholder="Enter Pollution Expiry Date" formControlName="pollutionExpiryDate">

                            </div>

                        </div>
                        <div class="col-xl-12 mb-3">
                            <div class="detailsBox">
                                <h4>Upload Document</h4>
                                <div class="row">
                                    <div class="col-lg-2 col-xl-2 col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Permit</label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!permitPreview"
                                                (change)="fileChangeEventPermit($event)" id="permitctrl"
                                                class="form-control shadow-none d-none" placeholder="Enter Permit"
                                                formControlName="permit">
                                            <label class="uploadimgNewDesign" for="permitctrl" *ngIf="!permitPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Permit</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="permitPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('permit')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="permitPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="permitPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">RC Copy</label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!rcPreview"
                                                id="RCCopyctrl" (change)="fileChangeEventRC($event)"
                                                class="form-control shadow-none d-none" placeholder="Enter RC Copy"
                                                formControlName="RCCopy">
                                            <label class="uploadimgNewDesign" for="RCCopyctrl" *ngIf="!rcPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload RC</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="rcPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('rc')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="rcPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="rcPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Insurance </label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!insuracePreview"
                                                id="insurancectrl" (change)="fileChangeEventInsurance($event)"
                                                class="form-control shadow-none d-none" placeholder="Enter Insurance "
                                                formControlName="insurance">
                                            <label class="uploadimgNewDesign" for="insurancectrl"
                                                *ngIf="!insuracePreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Insurance</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="insuracePreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('insurance')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="insuracePreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="insuracePreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Permit 5 Years</label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!permit5yPreview"
                                                id="permitYearsctrl" (change)="fileChangeEventPermitYears($event)"
                                                class="form-control shadow-none d-none"
                                                placeholder="Enter Permit 5 Years" formControlName="permitYears">
                                            <label class="uploadimgNewDesign" for="permitYearsctrl"
                                                *ngIf="!permit5yPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Permit 5 Years</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="permit5yPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('permit5y')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="permit5yPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="permit5yPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Road Tax</label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!roadTexPreview"
                                                id="roadTaxctrl" (change)="fileChangeEventRoadTax($event)"
                                                class="form-control shadow-none d-none" placeholder="Enter Road Tax"
                                                formControlName="roadTax">
                                            <label class="uploadimgNewDesign" for="roadTaxctrl" *ngIf="!roadTexPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Road Tax</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="roadTexPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('roadtax')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="roadTexPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="roadTexPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Fitness </label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!fitnessPreview"
                                                id="fitnessctrl" (change)="fileChangeEventFitness($event)"
                                                class="form-control shadow-none d-none" placeholder="Enter Fitness "
                                                formControlName="fitness">
                                            <label class="uploadimgNewDesign" for="fitnessctrl" *ngIf="!fitnessPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Fitness</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="fitnessPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('fitness')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="fitnessPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="fitnessPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Owner Pancard </label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!ownerpanPreview"
                                                id="ownerPancardctrl" (change)="fileChangeEventOwnerPancard($event)"
                                                class="form-control shadow-none d-none"
                                                placeholder="Enter Owner Pancard " formControlName="ownerPancard">
                                            <label class="uploadimgNewDesign" for="ownerPancardctrl"
                                                *ngIf="!ownerpanPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Owner Pancard</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="ownerpanPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('ownerpan')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="ownerpanPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="ownerpanPreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Owner Aadhar Card </label>
                                            <input type="file" accept="image/*,.pdf,.doc" *ngIf="!owneradharPreview"
                                                id="ownerAadharCardctrl"
                                                (change)="fileChangeEventOwnerAadharcard($event)"
                                                class="form-control shadow-none d-none"
                                                placeholder="Enter Owner Aadhar Card" formControlName="ownerAadharCard">
                                            <label class="uploadimgNewDesign" for="ownerAadharCardctrl"
                                                *ngIf="!owneradharPreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Owner Aadhar Card</h5>
                                            </label>
                                            <div class="viewImage" *ngIf="owneradharPreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('owneradhar')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="owneradharPreview" onClick="viewImages(this, 'Permit')"
                                                        [src]="owneradharPreview" alt="POD Front" />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-lg-2 col-xl-2  col-sm-6 col-12 col-md-4">
                                        <div class="form-group accountsinp  mb-3">
                                            <label for="">Cancelled Cheque </label>
                                            <input type="file" accept="image/*,.pdf,.doc"
                                                *ngIf="!cancelledChequePreview" id="cancelledChequectrl"
                                                (change)="fileChangeEventCancelledCheque($event)"
                                                class="form-control shadow-none d-none"
                                                placeholder="Enter Cancelled Cheque " formControlName="cancelledCheque">
                                            <label class="uploadimgNewDesign" for="cancelledChequectrl"
                                                *ngIf="!cancelledChequePreview">
                                                <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                                <h5>Upload Cancelled Cheque </h5>
                                            </label>
                                            <div class="viewImage" *ngIf="cancelledChequePreview">
                                                <div class="imgBoxp">
                                                    <button class="resetImg" (click)="resetimg('cancheck')"><i
                                                            class="fa-solid fa-xmark"></i></button>
                                                    <img [id]="cancelledChequePreview"
                                                        onClick="viewImages(this, 'Permit')"
                                                        [src]="cancelledChequePreview" alt="POD Front" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <a #showSlip [href]="slipURL" target="_blank"></a>
                        <div class="col-md-12 text-end">
                            <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                                mat-raised-button>Reset</button>
                            <button class="btn btn-dark ms-2" type="submit" mat-raised-button>Add
                                Now</button>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>