import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import * as $ from 'jquery'
import { FONT_STYLE } from 'html2canvas/dist/types/css/property-descriptors/font-style';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { async } from 'rxjs/internal/scheduler/async';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  model: any = {}
  clientList: any[] = [];
  title = null;
  dropdownList = [];
  flag=false
  dropdownList2 = [];
  selectedItems = [];
  selectedItems2 = [];
  filtered=[]
  dropdownSettings = {};
  status = null;
  visible1: boolean = true;
  clientEmail =[]
  changetype1: boolean = true;
  actualData;
  extractFromActualData=[];
  submitClicked = false;
  // form = new FormGroup({
  //   title: new FormControl('null', Validators.required),})
  // companyName: new FormControl('', Validators.required),
  addNewUser = new FormGroup({
    title: new FormControl(null, Validators.required),
    // companyName: new FormControl('', Validators.required),
    firstname: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{0,25}$/)])),
    email: new FormControl('', [Validators.required, Validators.pattern(config.emailregExp)]),
    password: new FormControl('', [Validators.required, Validators.pattern(config.passwordregExp)]),
    lastname: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{0,25}$/)])),
    mobile: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    status: new FormControl(null, Validators.required),
    clientList: new FormControl(null, Validators.required),
    // grocery: new FormControl('')
  })

  get ff() {
    return this.addNewUser.controls
  }
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) { }

  ngOnInit(): void {
    this.getClientList();
  }
  async getClientList() {
    await this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          this.clientList.map((x, index)=>{
            this.dropdownList2.push({
              item_id:x._id, item_text: x.clientName,
            })
          })
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All'
          };
          this.dropdownList=this.dropdownList2
       
          
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }

  // multiselect=====================================
  onItemSelect($event: any) {
    this.clientEmail=[]
    this.selectedItems.push({item_id:$event.item_id, item_text:$event.item_text})
    this.onKeyClient();
  }
  onItemDeselect($event: any) {
    let clientList = [];
    this.selectedItems.forEach((item)=>{
      if(item.item_id!=$event.item_id){
        clientList.push(item);
      }
    })
    this.selectedItems = clientList
    this.onKeyClient();
  }

  onItemDeselectAll($event: any) {
    this.selectedItems = [];
    this.onKeyClient();
    this.addvalidatorcss("clientListCtrl");
  }

  onItemSelectAll($event: any) {
    this.selectedItems = this.dropdownList;
    this.onKeyClient();
    this.removevalidatorcss("clientListCtrl");
  }

  // multiselect end =================================
  getObjectListFromData(ids: any) {
    return this.dropdownList.filter(item => ids.includes(item.item_id))
    }
  addNewUserSubmit() {
    this.submitClicked = true;
    if (this.addNewUser.valid) {
      this.actualData = this.getObjectListFromData(this.addNewUser.value.clientList.map(item => item.item_id))
      this.extractFromActualData = []
      this.actualData = this.addNewUser.value.clientList.map(item => item['item_text'])
      this.extractFromActualData = this.actualData.map((x) => {
        let client =  this.clientList.filter(y => {
          if (y.clientName == x) return true
        })
        return client[0].clientEmail;
      })
      let clientIds = this.selectedItems.map((item)=> item.item_id);
      this.removevalidatorcss("titlectrl");
      this.removevalidatorcss("firstnamectrl");
      this.removevalidatorcss("passwordctrl");
      this.removevalidatorcss("statusctrl");
      this.removevalidatorcss("emailctrl");
      this.removevalidatorcss("lastnamectrl");
      this.removevalidatorcss("mobilectrl");
      this.removevalidatorcss("clientListCtrl");


      var form = {
        "title": this.addNewUser.get('title').value,
        "firstname": this.addNewUser.get('firstname').value,
        "email": this.addNewUser.get('email').value,
        "password": this.addNewUser.get('password').value,
        "lastname": this.addNewUser.get('lastname').value,
        "mobile": this.addNewUser.get('mobile').value,
        "status": this.addNewUser.get('status').value,
        // "clientList": this.addNewUser.get('clientList').value,
        // "clientList": this.clientEmail,
        "clientList": clientIds,
      }
      this.superAdminService.createUser({ form })
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['/superAdmin/usermanagement']);
            this.addNewUser.reset()
          }
          else {
            // if (res.status == 401) {
            //   this.toastr.error(res.message);
            //   window.sessionStorage.clear();
            //   this.router.navigateByUrl('superAuth/login');
              
            // }else {
              this.toastr.error(res.message);
            // }
            //this.addNewUser.reset()
          }

        }, error => {
        });
    }
    else {
      if (this.addNewUser.get('title').invalid) {
        this.addvalidatorcss("titlectrl");
      } else {
        this.removevalidatorcss("titlectrl");
      }

      if (this.addNewUser.get('firstname').invalid) {
        this.addvalidatorcss("firstnamectrl");
      } else {
        this.removevalidatorcss("firstnamectrl");
      }
      if (this.addNewUser.get('email').invalid) {
        this.addvalidatorcss("emailctrl");
      } else {
        this.removevalidatorcss("emailctrl");
      }
      if (this.addNewUser.get('password').invalid) {
        this.addvalidatorcss("passwordctrl");
      } else {
        this.removevalidatorcss("passwordctrl");
      }
      if (this.addNewUser.get('lastname').invalid) {
        this.addvalidatorcss("lastnamectrl");
      } else {
        this.removevalidatorcss("lastnamectrl");
      }
      if (this.addNewUser.get('mobile').invalid) {
        this.addvalidatorcss("mobilectrl");
      } else {
        this.removevalidatorcss("mobilectrl");
      }
      if (this.addNewUser.get('status').invalid) {
        this.addvalidatorcss("statusctrl");
      } else {
        this.removevalidatorcss("statusctrl");
      }
      if (this.addNewUser.get('clientList').invalid) {
        this.addvalidatorcss("clientListCtrl");
      } else {
        this.removevalidatorcss("clientListCtrl");
      }

    }
  }

  resetForm() { 
    this.addNewUser.reset();
    this.submitClicked=false;
    this.removevalidatorcss("titlectrl");
    this.removevalidatorcss("firstnamectrl");
    this.removevalidatorcss("passwordctrl");
    this.removevalidatorcss("statusctrl");
    this.removevalidatorcss("emailctrl");
    this.removevalidatorcss("lastnamectrl");
    this.removevalidatorcss("mobilectrl");
    this.removevalidatorcss("clientListCtrl");

  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }
  onKeyTitle(event: any) {
    if (this.addNewUser.get('title').invalid) {
      this.addvalidatorcss("titlectrl");
    } else {
      this.removevalidatorcss("titlectrl");
    }
  }
  onKeyFirstName(event: any) {
    if (this.addNewUser.get('firstname').invalid) {
      this.addvalidatorcss("firstnamectrl");
    } else {
      this.removevalidatorcss("firstnamectrl");
    }
  }
  onKeyEmail(event: any) {
    if (this.addNewUser.get('email').invalid) {
      this.addvalidatorcss("emailctrl");
    } else {
      this.removevalidatorcss("emailctrl");
    }
  }
  onKeyPass(event: any) {
    if (this.addNewUser.get('password').invalid) {
      this.addvalidatorcss("passwordctrl");
    } else {
      this.removevalidatorcss("passwordctrl");
    }
  }
  onKeyLastName(event: any) {
    if (this.addNewUser.get('lastname').invalid) {
      this.addvalidatorcss("lastnamectrl");
    } else {
      this.removevalidatorcss("lastnamectrl");
    }
  }
  onKeyMobile(event: any) {
    if (this.addNewUser.get('mobile').invalid) {
      this.addvalidatorcss("mobilectrl");
    } else {
      this.removevalidatorcss("mobilectrl");
    }
  }
  onKeyStatus(event: any) {
    if (this.addNewUser.get('status').invalid) {
      this.addvalidatorcss("statusctrl");
    } else {
      this.removevalidatorcss("statusctrl");
    }
  }
  onKeyClient() {
    if (this.addNewUser.get('clientList').invalid) {
      this.addvalidatorcss("clientListCtrl");
    } else {
      this.removevalidatorcss("clientListCtrl");
    }
  }
  // get email(){
  //   return this.addNewUser.get('email')
  // }
  // get firstname(){
  //   return this.addNewUser.get('firstname')
  // }
  // get lastname(){
  //   return this.addNewUser.get('lastname')
  // }
  // get mobile(){
  //   return this.addNewUser.get('mobile')
  // }

  // get password(){
  //   return this.addNewUser.get('password')
  // }

  backTo() {
    window.history.back()
  }

  viewpass1() {
    this.visible1 = !this.visible1;
    this.changetype1 = !this.changetype1
  }

  // clickAnywaredropDownClose() {
  //   document.addEventListener('mouseup', function (e) {
  //     var container: any = document.querySelector('.multiSelectDrop');
  
  //     if (!container?.contains(e.target)) {
  //       $(container).slideUp()

  //     }
  //   });
  // }
  // setDefaultSelection() {
  //   let item = this.dropdownList[0];
  //   this.addNewUser.patchValue({
  //     grocery: [{
  //       item_id: item['item_id'],
  //       item_text: item['item_text']
  //     }]
  //   })
  // }
}
