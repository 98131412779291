import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-gst-invoice',
  templateUrl: './gst-invoice.component.html',
  styleUrls: ['./gst-invoice.component.css']
})
export class GstInvoiceComponent implements OnInit {
  invtot: any;
  users: any;
  fivePercent: any;
  eightPercent: any;
  totalInvValueWithGST: any;
  paramId;
  lrdetails: any;
  detailsinvoice: any;
  sys_gen_no: any;
  total_amount: any;
  cgst: number;
  sgst: number;
  igst: number;
  over_all_amount: any;
  allclientdata: any;
  for_clie_name: any;
  for_clie_names: (arg0: string, invoiceclientdata: any) => void;
  dataaa: any;
  companystatecode: any;
  all_invoice_data: any;
  Inv_created_date: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private toastr: ToastrService, private spinner: NgxSpinnerService,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
    })
  }

  ngOnInit(): void {

  }
  // getParticularInvoice(id: string) {
  //   this.superAdminService.getParticularInvoice(id)
  //     .subscribe((res: { status: any, message: any, data: any }) => {

  //       if (res.status == true) {
  //         var rawdata = res.data;
  //         this.users = rawdata[0].invoice;
  //         this.lrdetails = this.users.map((x)=>x.InvoiceNo)
  //        // this.lrdetails = this.users[0]
  //        console.log("#@#@#@#@",this.lrdetails[0])
  //         console.log("this.usersthis.users",this.users)
  //         console.log("asdasdasd@@@@@",this.users.InvoiceNo[0])
  //         //this.users = this.users[0].invoice;
  //         this.users.forEach( (value:any)=> {
  //           let balancepaidArray = value.vehicleRate;
  //           this.invtot =  balancepaidArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)
  //         }); 
  //         this.fivePercent = (this.invtot*6)/100;
  //         this.eightPercent = (this.invtot*8)/100;
  //         // this.totalInvValueWithGST = this.invtot + 2*(this.fivePercent) + this.eightPercent;
  //         this.totalInvValueWithGST = this.invtot + 2*(this.fivePercent);
  //         console.log("this.totalInvValueWithGST",this.totalInvValueWithGST)
  //       }
  //       else {
  //         alert(res.message)
  //       }

  //     }, error => {
  //     });
  // }


  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          console.log("rawdatarawdatarawdata", rawdata);
          this.all_invoice_data = rawdata
          console.log("this.all_invoice_data", this.all_invoice_data[0].created_date
          )
          this.Inv_created_date =  this.all_invoice_data[0].created_date
          this.getClientList()
          this.sys_gen_no = rawdata.map((x) => x.sys_invoice);
          // console.log("this.sys_gen_no",this.sys_gen_no)

          this.detailsinvoice = rawdata
          this.detailsinvoice = this.detailsinvoice.map((x) => x.invoice);
          // console.log("this.detailsinvoicethis.detailsinvoice",this.detailsinvoice);
          this.users = rawdata[0].invoice;
          this.total_amount = this.users[0].total_invoice_amount
          // console.log("this.users",this.users);
          // console.log(" this.total_amount", this.total_amount);

          this.users.forEach((value: any) => {
            this.invtot = this.total_amount
              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
            // console.log("this.invtot",this.invtot);

            this.invtot = this.total_amount
              .reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);
            this.cgst = (this.invtot * 6) / 100;
            // console.log("%%%%%%",this.cgst)
            this.sgst = (this.invtot * 6) / 100;

            this.igst = (this.invtot * 12) / 100;
            this.over_all_amount = this.invtot + this.cgst + this.sgst + this.igst

          }
          )
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }


  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          // this.spinner.hide();
          var rawdata = res.data;
          this.allclientdata = rawdata;
          this.for_clie_name = this.allclientdata;
          console.log("this.for_clie_name", this.for_clie_name);
          console.log("this.all_invoice_data", this.all_invoice_data);
          this.for_clie_names = this.invoiceclientdata;
          for (let i = 0; i < this.for_clie_name.length; i++) {
            if ((this.for_clie_name[i]._id) === (this.all_invoice_data[0]?.clientName)) {
              this.dataaa = this.for_clie_name[i]
              console.log("this.dataaaaaaaa", this.dataaa);
            }
          }

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.success(res.message);
            // this.spinner.hide();
          }
        }

      }, error => {
      });

  }
  invoiceclientdata(arg0: string, invoiceclientdata: any) {
    throw new Error('Method not implemented.');
  }

  // getParticularInvoice(id: string) {
  //   this.superAdminService.getParticularInvoice(id)
  //     .subscribe((res: { status: any, message: any, data: any }) => {
  //       if (res.status == true) {
  //         var rawdata = res.data;

  //       }
  //       else {
  //         if (res.status == 401) {
  //           this.toastr.error(res.message);
  //           window.sessionStorage.clear();
  //           this.router.navigateByUrl('superAuth/login');

  //         } else {
  //           this.toastr.success(res.message);
  //         }
  //       }

  //     }, error => {
  //     });
  // }



  backTo() {
    window.history.back()
  }

}
