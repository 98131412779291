import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'
@Component({
  selector: 'app-left-side-nav-super-admin',
  templateUrl: './left-side-nav.component.html',
  styleUrls: ['./left-side-nav.component.css']
})
export class LeftSideNavComponent implements OnInit {
  userroleid;
  userSessionData;
  isRole1 = true;
  isRole2 = true;
  isRole3 = true;
  isRole4 = true;
  constructor(private router: Router) { }

  ngOnInit() {
    this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
    this.userroleid = this.userSessionData.user_role_id[0];
    if (this.userroleid === 2) {
      this.isRole2 = false;
    }else if(this.userroleid === 3){
      // console.log(this.userroleid)
      this.isRole3 = false

    }else if(this.userroleid === 4){
      // console.log(this.userroleid)
      this.isRole4 = false
    }else if(this.userroleid === 1){
      this.isRole1 = true
    }
    

     }

  logout() {
    window.sessionStorage.clear();
    this.router.navigateByUrl('superAuth/login');
  }

  removeactiveclass() {

  }



  

}
