import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
export interface DialogData {
  vehicle_no: any,
  type: any,
  ownerName: any,
  policyNumber: any,
  roadTaxDate: any,
  insuranceDueDate: any,
  fitnessDueDate: any;
}

@Component({
  selector: 'app-total-vehicle',
  templateUrl: './total-vehicle.component.html',
  styleUrls: ['./total-vehicle.component.css']
})
export class TotalVehicleComponent implements OnInit {
  @Input() childData: string;
  @ViewChild('paginator') paginator: MatPaginator;
  isButtonDisabled: boolean = true;
  pageName: any;
 
 
  
  users: any=[];
  innerWidth: number;
  heading: any;

  vehicles: any = {
    totalVehicles: 0,
    documentsExpired: 0
  };
  loginbtn:any
  dataNew:any
  expiredDocs:any =[]
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['vehicle_no', 'type', 'ownerName', 'policyNumber', 'roadTaxDate', 'insuranceDueDate', 'fitnessDueDate', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  

  
 

  constructor(private router: Router,private superAdminService: SuperAdminService, private spinner:NgxSpinnerService, private toastr: ToastrService, @Inject(MAT_DIALOG_DATA) public data: any,private route: ActivatedRoute)  { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pageName = params['param3'];

      // console.log("data  vehicles from route parameters", this.pageName)
    })  

    // this.representation(this.pageName);
    this.getUsers();
    this.dataNew = this.data.data
    this.heading = this.data.heading
    // console.log("heading",this.heading);
    // this.loginbtn = this.data
    
    
   }

   getUsers() {
    
    this.superAdminService.getVehicle().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status === true) {
        this.vehicles = res.data;
        let documentsExpired = 0;
        let currentDate = new Date();
        for (let vehicle of res.data) {
          // let f = currentDate.toISOString()
          //  console.log(f.split("T")[0])
          //  console.log(vehicle['permitDueDate'].split("T")[0]) 
          //  console.log(this.vehicles)
          
          if (new Date(vehicle['permitDueDate']) < currentDate ||
          new Date(vehicle['permitYearsDueDate']) < currentDate ||
          new Date(vehicle['pollutionExpiryDate']) < currentDate ||
          new Date(vehicle['roadTaxDate']) < currentDate ||
          new Date(vehicle['insuranceDueDate']) < currentDate ||
          new Date(vehicle['RCTaxDate']) < currentDate ||
          new Date(vehicle['fitnessDueDate']) < currentDate) {
            documentsExpired += 1;
           
            let data3 = {...vehicle, docExpired:true}
            this.expiredDocs.push(data3)
            
          } else{
            let data3 = {...vehicle, docExpired:false}
            this.expiredDocs.push(data3)
          }
        }
        if(this.dataNew || this.pageName == 'Expired Documents'){
          this.expiredDocs =  this.expiredDocs.filter((res:any) =>  res.docExpired === true)
        } else {
          this.expiredDocs = this.expiredDocs
        }
        this.dataSource = new MatTableDataSource(this.expiredDocs)
        this.dataSource.paginator = this.paginator;

        this.vehicles.documentsExpired = documentsExpired;
        
    }})
}


filterData(value) {
  this.dataSource.filter = value;
}

sortData(sort: Sort) {
  const data = this.dataSource.filteredData;
  if (!sort.active || sort.direction === "") {
    return this.dataSource;
  }
  this.dataSource.filteredData = data.sort((a, b) => {
    const isAsc = sort.direction === "asc";
    switch (sort.active) {
      case "consignee_name":
        return compare(
          lower(a.consignee_name),
          lower(b.consignee_name),
          isAsc
        );
      case "consignor_name":
        return compare(
          lower(a.consignor_name),
          lower(b.consignor_name),
          isAsc
        );
      case "clientName":
        return compare(lower(a.clientName), lower(b.clientName), isAsc);
      case "date":
        return compare(a.date, b.date, isAsc);
      case "from":
        return compare(lower(a.from), lower(b.from), isAsc);
      case "to":
        return compare(lower(a.to), lower(b.to), isAsc);
      default:
        return 0;
    }
  });
  function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  function lower(value) {
    return value ? value.toLowerCase() : value;
  }
}
backTo() {
  window.history.back();
}

deleteuser(_id) {
  if (this.superAdminService.deleteDailogBox()) {
    this.superAdminService.deleteVehicle(_id)
      .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          //this.router.navigateByUrl('/user/userManagement');
          this.getUsers();
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
}





}

