<div class="header">
    <h1>{{this.data.heading}} Fuel Expense</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" [formGroup]="fuel_expense" (ngSubmit)="fuelExpenseSubmit()">
            <div class="row">


                <div class="col-xl-12 mb-3">
                    <div class="form-group accountsinp  mb-3">
                        <label for="fuelPumpctrl">Fuel Pump</label>
                        <input type="text" id="fuelPumpctrl" class="form-control shadow-none"
                            placeholder="Enter Fuel Pump" formControlName="fuelPump"
                            [matAutocomplete]="autofuelPump">
                        
                        <mat-autocomplete autoActiveFirstOption #autofuelPump="matAutocomplete">
                            <mat-option *ngFor="let option of filteredfuelPump | async"
                                [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                        <!-- <span class="text-danger"
                            *ngIf="ff.driverName.invalid && (ff.driverName.touched || submitLRClicked)">
                            Please Select Fuel Pump
                        </span> -->
                    </div>
                </div>


                <!-- <div class="col-xl-12 mb-3">
                    <div class="form-group" >
                        <label for="">Fuel Pump</label>
                        <select name="" class="form-control form-select "id="" formControlName="fuelPump">
                            <option value="">--Fuel Pump--</option>
                            <option *ngFor="let pump of this.fuelPump" value="{{pump.fuel_pump}}">{{pump.fuel_pump}} </option>
                        </select>
                    </div>
                </div> -->
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Date of Expenses</label>
                        <input type="date" class="form-control" formControlName="dateOfExpenses"/>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Fuel Expense Amount</label>
                        <input type="text" class="form-control" formControlName="fuelExpenseAmount" placeholder="Expense Amount" />
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Payment Mode</label>
                        <select name="" class="form-control form-select "id="" formControlName="paymentMode">
                            <option disabled value="">--Select Payment Mode--</option>
                            <option value="upi">UPI</option>
                            <option value="cash">Cash</option>
                            <option value="petroCard">Petro Card</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Fuel Quantity</label>
                        <input type="text" class="form-control" formControlName="fuelQuantity" placeholder="Fuel Quantity"/>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Unit</label>
                        <select name="" class="form-control form-select "id="" formControlName="unit">
                            <option disabled value="">--Select Unit--</option>
                            <option value="Litre">Litre</option>
                            <option value="Kilogram">Kilogram</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Fuel Type</label>
                        <select name="" class="form-control form-select "id="" formControlName="fuelType">
                            <option disabled value="">--Select Fuel Type--</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Kilogram">CNG</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Driver Name</label>
                        <input type="text" class="form-control" name="" id="" formControlName="driverName">
                    </div>
                </div>
                <!-- <div class="col-xl-12">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Fuel Slip</label>
                        <div class="d-flex align-items-center gap-3 podimgbox">
                            <input type="file" id="podImagectrl" hidden formControlName="podImage"   formControlName="fuelAttachment"  accept="image/*,.pdf,.doc"  (change)="fileChangeEventPODAttchment($event)" > -->
                            <!-- <button (click)="addPODFile()" mat-raised-button matTooltip="Upload"
                                                    class="btn btn-dark ms-2" type="submit"><i
                                                        class="fa-solid fa-upload"></i></button>-->
                                                        
                            <!-- <label for="podImagectrl">Choose File</label> -->
                            <!-- <p>No File Choosen</p> -->
                        <!-- </div>
                    </div>
                </div>
                <div class="col-xl-12 mb-3" *ngIf="url">
                    <div class="row">
                        <div class="col-xl-3 text-center">
                            <img src={{url}} class="img-fluid w-100 mb-3" alt="fuel Slip">
                            <a #showDoc [href]="docURL" target="_blank"></a> 
                            <p>{{this.fuelSlips[0].name}}</p>
                        </div>
    
                    </div>
                </div> -->


                <div class="col-lg-4 col-xl-4  col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Fuel Slip </label>
                        <input type="file" accept="image/*,.pdf,.doc"
                            *ngIf="!fuelSlipPreview" id="cancelledChequectrl"
                            (change)="fileChangeEventPODAttchment($event)"
                            class="form-control shadow-none d-none"
                            placeholder="Enter Fuel Slip " formControlName="fuelAttachment">
                        <label class="uploadimgNewDesign" for="cancelledChequectrl"
                            *ngIf="!fuelSlipPreview">
                            <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                            <h5>Upload Fuel Slip </h5>
                        </label>
                        <div class="viewImage" *ngIf="fuelSlipPreview">
                            <div class="imgBoxp">
                                <button class="resetImg" (click)="resetimg('fuelSlip')"><i
                                        class="fa-solid fa-xmark"></i></button>
                                <img [id]="fuelSlipPreview"
                                    onClick="viewImages(this, 'fuelSlip')"
                                    [src]="fuelSlipPreview" alt="Fuel Slip" />
                            </div>
                        </div>
                    </div>

                </div>



                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5"
                    mat-raised-button (click)="fuelExpenseSubmit()" [disabled]="submitBtn">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
