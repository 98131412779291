<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Date Wise TripID Expense Report : {{this.month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist " *ngIf='LrExpenseArray.length > 0'>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <!-- <td>Company Name</td> -->
                            <td>Supervisor Name</td>
                            <td>TripID</td>
                            <td>LR Number</td>
                            <td>Total Value </td>
                            <td>Vehicle Rate</td>
                            <td>Total Expense</td>
                            <td>Income</td>
                            <td>Payment Received</td>   
                            <td>Payment Due</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of LrExpenseArray;let i = index">
                            <td>{{user1.Date | date:"dd/MM/yyyy"}}</td>
                            <!-- <td>{{user1.companyName}}</td> -->
                            <td>{{user1.SupervisorName}}</td>
                            <td>{{user1.TripID}}</td>
                            <td>{{user1.LR_no}}</td>
                            <td>{{user1.total_value}}</td>
                            <td>{{user1.vehicleRate}}</td>
                            <td>{{user1.expense}}</td>
                            <td>{{user1.income}}</td>
                            <td>{{user1.advance}}</td>
                            <td>{{paymentDue[i][user1.TripID]}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

