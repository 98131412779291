import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ImagePreviewComponent } from '../../LR-management/image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-vechile',
  templateUrl: './add-vechile.component.html',
  styleUrls: ['./add-vechile.component.css']
})
export class AddVechileComponent implements OnInit {

  vehicletypes;
  driver;
  vehicleTypesNames = [];
  DriverNames = [];
  filteredVehicleTypes: Observable<string[]>;
  filteredDriverName: Observable<string[]>;
  fileSource: string[] = [];
  // filesToUpload: Array<File> = [];
  @ViewChild('showSlip') showSlip: ElementRef;
  apiUrl = environment.domain;
  slipURL: any;
  permitPreview: any
  rcPreview: any;
  insuracePreview: any;
  permit5yPreview: any;
  roadTexPreview: any;
  fitnessPreview: any;
  ownerpanPreview: any;
  owneradharPreview: any;
  cancelledChequePreview: any
  fieldsUploaded: any = {
    permit: null,
    RCCopy: null,
    insurance: null,
    permitYears: null,
    roadTax: null,
    fitness: null,
    ownerPancard: null,
    ownerAadharCard: null,
    cancelledCheque: null
  };
  addNewVehicle = new FormGroup({
    vehicleNumber: new FormControl('', [Validators.required]),
    vehicleType: new FormControl('', [Validators.required]),
    ownerName: new FormControl(''),
    driverName: new FormControl(''),
    makerName: new FormControl(''),
    chassisNumber: new FormControl(''),
    engineNumber: new FormControl(''),
    permitNumber: new FormControl(''),
    policyNumber: new FormControl(''),
    city: new FormControl(''),
    permitDueDate: new FormControl(''),
    permitYearsDueDate: new FormControl(''),
    purchaseDate: new FormControl(''),
    roadTaxDate: new FormControl(''),
    insuranceDueDate: new FormControl(''),
    fitnessDueDate: new FormControl(''),
    pollutionExpiryDate: new FormControl(''),
    permit: new FormControl(''),
    RCCopy: new FormControl(''),
    insurance: new FormControl(''),
    permitYears: new FormControl(''),
    roadTax: new FormControl(''),
    fitness: new FormControl(''),
    ownerPancard: new FormControl(''),
    ownerAadharCard: new FormControl(''),
    cancelledCheque: new FormControl('')
  })
  get ff() {
    return this.addNewVehicle.controls
  }
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, public dialog: MatDialog, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getDriverName();
    this.getVehicleType();
  }

  getDriverName() {
    this.superAdminService.getDriverName()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.driver = rawdata;
          this.driver.forEach((item) => {
            if (item.driver_name) {
              this.DriverNames.push(item.driver_name)
            }
          })
          this.filteredDriverName = this.ff.driverName.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDriverName(value || '')),
          );
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  private _filterDriverName(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNames.filter(option => option.toLowerCase().includes(filterValue));
  }
  getVehicleType() {
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          this.vehicletypes.forEach((item) => {
            this.vehicleTypesNames.push(item.vehicletype_name)
          })
          this.filteredVehicleTypes = this.ff.vehicleType.valueChanges.pipe(
            startWith(''),
            map(value => this._filterVehicleType(value || '')),
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });

  }
  private _filterVehicleType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehicleTypesNames.filter(option => option.toLowerCase().includes(filterValue));
  }
  resetimg(image: any) {
    switch (image) {
      case 'permit':
        this.permitPreview = ''
        break;
      case 'rc':
        this.rcPreview = ''
        break;
      case 'insurance':
        this.insuracePreview = ''
        break;
      case 'permit5y':
        this.permit5yPreview = ''
        break;
      case 'roadtax':
        this.roadTexPreview = ''
        break;
      case 'fitness':
        this.fitnessPreview = ''
        break;
      case 'ownerpan':
        this.ownerpanPreview = ''
        break;
      case 'owneradhar':
        this.owneradharPreview = ''
        break;
      case 'cancheck':
        this.cancelledChequePreview = ''
        break;
      default:

    }
  }
  fileChangeEventPermit(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['permit'] = fileInput.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.permitPreview = reader.result;
      // console.log(this.permitPreview)
    }

  }
  fileChangeEventRC(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    //this.product.photo = fileInput.target.files[0]['name'];
    this.fieldsUploaded['RCCopy'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.rcPreview = reader.result;
      // console.log(this.permitPreview)
    }
  }
  fileChangeEventInsurance(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['insurance'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.insuracePreview = reader.result;
      // console.log(this.insuracePreview)
    }
  }
  fileChangeEventPermitYears(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['permitYears'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.permit5yPreview = reader.result;
    }
  }
  fileChangeEventRoadTax(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['roadTax'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.roadTexPreview = reader.result;
    }
  }
  fileChangeEventFitness(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['fitness'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.fitnessPreview = reader.result;
    }
  }
  fileChangeEventOwnerPancard(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['ownerPancard'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.ownerpanPreview = reader.result;
    }
  }
  fileChangeEventOwnerAadharcard(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['ownerAadharCard'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.owneradharPreview = reader.result;
    }
  }
  fileChangeEventCancelledCheque(fileInput: any) {
    // this.filesToUpload.push(fileInput.target.files[0]);
    this.fieldsUploaded['cancelledCheque'] = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileInput.target.files[0])
    reader.onload = () => {
      const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.cancelledChequePreview = reader.result;
    }
  }
  addNewVehicleSubmit() {
    // console.log('addNewVehicle', this.addNewVehicle.value);

    let fieldKeys: Array<String> = []
    let fieldValues: Array<File> = []
    for (let i in this.fieldsUploaded) {
      let file = this.fieldsUploaded[i]
      if (file) {
        fieldKeys.push(i);
        fieldValues.push(file);
      }
    }
    if (this.addNewVehicle.valid) {
      this.removevalidatorcss("vehicleNumberctrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("ownerNamectrl");
      this.removevalidatorcss("driverNamectrl");
      this.removevalidatorcss("makerNamectrl");
      this.removevalidatorcss("chassisNumberctrl");
      this.removevalidatorcss("engineNumberctrl");
      this.removevalidatorcss("permitNumberctrl");
      this.removevalidatorcss("policyNumberctrl");
      this.removevalidatorcss("cityctrl");
      this.removevalidatorcss("permitDueDatectrl");
      this.removevalidatorcss("permitYearsDueDatectrl");
      this.removevalidatorcss("purchaseDatectrl");
      this.removevalidatorcss("roadTaxDatectrl");
      this.removevalidatorcss("insuranceDueDatectrl");
      this.removevalidatorcss("fitnessDueDatectrl");
      this.removevalidatorcss("pollutionExpiryDatectrl");
      this.removevalidatorcss("permitctrl");
      this.removevalidatorcss("RCCopyctrl");
      this.removevalidatorcss("insurancectrl");
      this.removevalidatorcss("permitYearsctrl");
      this.removevalidatorcss("roadTaxctrl");
      this.removevalidatorcss("fitnessctrl");
      this.removevalidatorcss("ownerPancardctrl");
      this.removevalidatorcss("ownerAadharCardctrl");
      this.removevalidatorcss("cancelledChequectrl");


      var form = {
        "vehicleNumber": this.addNewVehicle.get('vehicleNumber').value,
        "vehicleType": this.addNewVehicle.get('vehicleType').value,
        "ownerName": this.addNewVehicle.get('ownerName').value,
        "driverName": this.addNewVehicle.get('driverName').value,
        "makerName": this.addNewVehicle.get('makerName').value,
        "chassisNumber": this.addNewVehicle.get('chassisNumber').value,
        "engineNumber": this.addNewVehicle.get('engineNumber').value,
        "permitNumber": this.addNewVehicle.get('permitNumber').value,
        "policyNumber": this.addNewVehicle.get('policyNumber').value,
        "city": this.addNewVehicle.get('city').value,
        "permitDueDate": this.addNewVehicle.get('permitDueDate').value,
        "permitYearsDueDate": this.addNewVehicle.get('permitYearsDueDate').value,
        "purchaseDate": this.addNewVehicle.get('purchaseDate').value,
        "roadTaxDate": this.addNewVehicle.get('roadTaxDate').value,
        "insuranceDueDate": this.addNewVehicle.get('insuranceDueDate').value,
        "fitnessDueDate": this.addNewVehicle.get('fitnessDueDate').value,
        "pollutionExpiryDate": this.addNewVehicle.get('pollutionExpiryDate').value,
        // "permit": this.addNewVehicle.get('permit').value,
        // "RCCopy": this.addNewVehicle.get('RCCopy').value,
        // "insurance": this.addNewVehicle.get('insurance').value,
        // "permitYears": this.addNewVehicle.get('permitYears').value,
        // "roadTax": this.addNewVehicle.get('roadTax').value,
        // "fitness": this.addNewVehicle.get('fitness').value,
        // "ownerPancard": this.addNewVehicle.get('ownerPancard').value,
        // "ownerAadharCard": this.addNewVehicle.get('ownerAadharCard').value,
        // "cancelledCheque": this.addNewVehicle.get('cancelledCheque').value,
      }
      const formData = new FormData();
      // const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < fieldValues.length; i++) {
        // i < this.filesToUpload.length
        // formData.append("uploads[]", this.filesToUpload[i]);
        formData.append("uploads[]", fieldValues[i]);
      }

      // for (let i = 0; i < this.filesToUpload.length; i++) {
      //   formData.append("uploads[]", this.filesToUpload[i]);
      //   // formData.append("uploads[]", fieldValues[i]);
      // }



      //formData.append("uploads[]",JSON.stringify(files));
      // const formData = new FormData();

      // for (var i = 0; i < this.fileSource.length; i++) { 
      //   formData.append("file[]", this.fileSource[i]);
      // }
      formData.append('myFormData', JSON.stringify(form));
      formData.append('updatedFields', JSON.stringify(fieldKeys));
      this.superAdminService.addVehicle(formData)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            //alert(res.message)
            this.toastr.success(res.message);
            this.router.navigate(['/superAdmin/manageVehicle']);
            //this.addNewDispense.reset()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
            //this.addNewUser.reset()
          }

        }, error => {
        });
    }
    else {
      if (this.addNewVehicle.get('vehicleNumber').invalid) {
        this.addvalidatorcss("vehicleNumberctrl");
      } else {
        this.removevalidatorcss("vehicleNumberctrl");
      }

      if (this.addNewVehicle.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addNewVehicle.get('ownerName').invalid) {
        this.addvalidatorcss("ownerNamectrl");
      } else {
        this.removevalidatorcss("ownerNamectrl");
      }
      if (this.addNewVehicle.get('driverName').invalid) {
        this.addvalidatorcss("driverNamectrl");
      } else {
        this.removevalidatorcss("driverNamectrl");
      }
      if (this.addNewVehicle.get('makerName').invalid) {
        this.addvalidatorcss("makerNamectrl");
      } else {
        this.removevalidatorcss("makerNamectrl");
      }
      if (this.addNewVehicle.get('chassisNumber').invalid) {
        this.addvalidatorcss("chassisNumberctrl");
      } else {
        this.removevalidatorcss("chassisNumberctrl");
      }
      if (this.addNewVehicle.get('engineNumber').invalid) {
        this.addvalidatorcss("engineNumberctrl");
      } else {
        this.removevalidatorcss("engineNumberctrl");
      }
      if (this.addNewVehicle.get('permitNumber').invalid) {
        this.addvalidatorcss("permitNumberctrl");
      } else {
        this.removevalidatorcss("permitNumberctrl");
      }
      if (this.addNewVehicle.get('policyNumber').invalid) {
        this.addvalidatorcss("policyNumberctrl");
      } else {
        this.removevalidatorcss("policyNumberctrl");
      }
      if (this.addNewVehicle.get('city').invalid) {
        this.addvalidatorcss("cityctrl");
      } else {
        this.removevalidatorcss("cityctrl");
      }
      if (this.addNewVehicle.get('permitDueDate').invalid) {
        this.addvalidatorcss("permitDueDatectrl");
      } else {
        this.removevalidatorcss("permitDueDatectrl");
      }
      if (this.addNewVehicle.get('permitYearsDueDate').invalid) {
        this.addvalidatorcss("permitYearsDueDatectrl");
      } else {
        this.removevalidatorcss("permitYearsDueDatectrl");
      }
      if (this.addNewVehicle.get('purchaseDate').invalid) {
        this.addvalidatorcss("purchaseDatectrl");
      } else {
        this.removevalidatorcss("purchaseDatectrl");
      }
      if (this.addNewVehicle.get('roadTaxDate').invalid) {
        this.addvalidatorcss("roadTaxDatectrl");
      } else {
        this.removevalidatorcss("roadTaxDatectrl");
      }
      if (this.addNewVehicle.get('insuranceDueDate').invalid) {
        this.addvalidatorcss("insuranceDueDatectrl");
      } else {
        this.removevalidatorcss("insuranceDueDatectrl");
      }
      if (this.addNewVehicle.get('fitnessDueDate').invalid) {
        this.addvalidatorcss("fitnessDueDatectrl");
      } else {
        this.removevalidatorcss("fitnessDueDatectrl");
      }
      if (this.addNewVehicle.get('pollutionExpiryDate').invalid) {
        this.addvalidatorcss("pollutionExpiryDatectrl");
      } else {
        this.removevalidatorcss("pollutionExpiryDatectrl");
      }
      if (this.addNewVehicle.get('permit').invalid) {
        this.addvalidatorcss("permitctrl");
      } else {
        this.removevalidatorcss("permitctrl");
      }
      if (this.addNewVehicle.get('RCCopy').invalid) {
        this.addvalidatorcss("RCCopyctrl");
      } else {
        this.removevalidatorcss("RCCopyctrl");
      }
      if (this.addNewVehicle.get('insurance').invalid) {
        this.addvalidatorcss("insurancectrl");
      } else {
        this.removevalidatorcss("insurancectrl");
      }
      if (this.addNewVehicle.get('permitYears').invalid) {
        this.addvalidatorcss("permitYearsctrl");
      } else {
        this.removevalidatorcss("permitYearsctrl");
      }
      if (this.addNewVehicle.get('roadTax').invalid) {
        this.addvalidatorcss("roadTaxctrl");
      } else {
        this.removevalidatorcss("roadTaxctrl");
      }
      if (this.addNewVehicle.get('fitness').invalid) {
        this.addvalidatorcss("fitnessctrl");
      } else {
        this.removevalidatorcss("fitnessctrl");
      }
      if (this.addNewVehicle.get('ownerPancard').invalid) {
        this.addvalidatorcss("ownerPancardctrl");
      } else {
        this.removevalidatorcss("ownerPancardctrl");
      }
      if (this.addNewVehicle.get('ownerAadharCard').invalid) {
        this.addvalidatorcss("ownerAadharCardctrl");
      } else {
        this.removevalidatorcss("ownerAadharCardctrl");
      }
      if (this.addNewVehicle.get('cancelledCheque').invalid) {
        this.addvalidatorcss("cancelledChequectrl");
      } else {
        this.removevalidatorcss("cancelledChequectrl");
      }


    }
  }
  preview(field) {
    let file = this.fieldsUploaded[field];
    if (file) {
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(file.type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: file }
        });
      }
      else {
        let url = URL.createObjectURL(file);
        this.slipURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showSlip.nativeElement.click();
        })
      }
    }
    else {
      this.toastr.error('Please upload document')
    }
  }
  resetForm() {
    this.addNewVehicle.reset();
    this.fieldsUploaded = {
      permit: null,
      RCCopy: null,
      insurance: null,
      permitYears: null,
      roadTax: null,
      fitness: null,
      ownerPancard: null,
      ownerAadharCard: null,
      cancelledCheque: null
    };
  }
  backTo() {
    window.history.back()
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }

}
