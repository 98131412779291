import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPrintModule } from 'ngx-print';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    NgxSpinnerModule,
  ],
  exports: [MaterialModule, NgxSpinnerModule,],
  providers: []
})
export class sharedModule { }
