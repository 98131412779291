<div class="container-fluid ">
  <div class="row justify-content-between manageaccount mt-3">
    <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
      <div class="d-flex align-items-baseline justify-content-between">
        <h1 class="fs-4 fw-bold mb-0">View TripID</h1>
        <button class="btn btn-dark btn-sm" mat-raised-button matTooltip="Back" matTooltipPosition="left"
          (click)="backTo()">
          <i class="fa-solid fa-arrow-left"></i>
        </button>

      </div>

      <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item active" aria-current="page">Manage LR</li>

          <li class="breadcrumb-item active" aria-current="page">
            View TripID
          </li>
        </ol>
      </nav>

    </div>

  </div>

  <div class="contentbox w-90vw mt-3">
    <form [formGroup]="filterform">
      <div class="row">
        
        <div class="col-md-4">
          <div class="form-group accountsinp mb-3">
            <label for="">Trip ID.<sup class="text-danger">*</sup></label>
            <select id="tripID" formControlName="TripID" placeholder="Select Trip" class="form-control shadow-none"
              (change)="onFocusGetLr($event)">
              <option value="">--Select Trip ID--</option>
              <option *ngFor="let datan of uniqueRes" value="{{ datan }}">
                {{ datan }}
              </option>
            </select>
          </div>
        </div>





        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
          <label for="">LR NO.<sup class="text-danger">*</sup></label>
          <div class="form-group accountsinp mb-3">
            <ng-multiselect-dropdown formControlName="LR_no" placeholder="Select LR_no" [settings]="dropdownSettings"
              [data]="lrNumber" (onSelect)="onItemSelect($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>


        <div class="col-md-4">
          <div class="form-group accountsinp mb-3">
            <label for="">Invoice Type</label>
            <select id="invoiceTypectrl" formControlName="invoiceType" placeholder="Select Invoice Type"
              class="form-control shadow-none">
              <option value="">--Select Invoice Type--</option>
              <option *ngFor="let s of invoiceType" value="{{ s.invoiceTypeName }}">
                {{ s.invoiceTypeName }}
              </option>
            </select>
          </div>
        </div>
      </div>


      <div class="col-xl-12 text-end">
        <button class="btn btn-dark ms-2" type="submit" mat-raised-button (click)="generateInvoice()">
          Invoice Generate
        </button>



      </div>


    </form>

  </div>
  <div class="contentbox w-90vw mt-3">
    <div class="row">
      <div class="col-xl-3 ms-auto">
        <input class="form-control"  #data placeholder="Search" type="text" (keyup)="filterData(data.value)" />
      </div>
      <div class="col-md-12">
        <div class="table-responsive">
          <!-- <table id="example" datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='invoiceList.length > 0'>
                        <thead>
                            <tr>

                                <td>Client Name</td>
                                <td>Date</td>
                                <td class="text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let client of clientName">
                                <td>
                                    {{client.clientName}}</td>
                                <td>
                                    {{client.date | date:'yyyy-MM-dd'}}</td>
                                <td>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button [routerLink]="['/superAdmin/lrmanagement/invoice', client._id]"
                                        class="btn btn-block"><i matTooltip="View" class="fa-solid fa-eye"></i></button>
                                        <button (click)="deleteInvoice(client._id)"
                                        class="btn btn-block"><i matTooltip="Delete" class="fa fa-trash"></i></button>
                                    </div>
                                </td>
                            
                            </tr>


                        </tbody>
                    </table> -->
          
          <table mat-table [dataSource]="dataSource" matSort matSortActive="created_date" matSortDirection="desc"
            (matSortChange)="sortData($event)" class="row-border hover tablelist">
            <ng-container matColumnDef="sno">
              <th mat-header-cell *matHeaderCellDef>S.no</th>
              <td mat-cell *matCellDef="let ids; let i = index">
                {{ paginator.pageIndex * paginator.pageSize + (i + 1) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="tripID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                TripID
              </th>
              <td mat-cell *matCellDef="let ids">{{ ids.TripID ?ids.TripID:'Generated By Company'}}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                TripID
              </th>
              <td mat-cell *matCellDef="let ids">{{ ids.TripID === null ? 'Generated By Company'  : '' }}</td>
            </ng-container> -->

            <ng-container matColumnDef="created_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let ids">
                {{ ids.created_date | date : "yyyy-MM-dd" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
              <td mat-cell *matCellDef="let receipt">
                <div class="d-flex justify-content-center align-items-center gap-3">
                  <button [routerLink]="['/superAdmin/lrmanagement/invoice', receipt._id]"
                  class="btn btn-block p-0  shadow-none"> <i matTooltip="Detail" class="fas fa-eye"></i>
                </button>
                  <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"
                    class="btn btn-block p-0 shadow-none"><i matTooltip="Print" class="fas fa-print"></i></button>
                 

                  <button (click)="deleteInvoice(receipt._id)" class="btn btn-block p-0">
                    <i matTooltip="Delete" class="fa fa-trash"></i>
                  </button>

                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
         
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>



</div>