import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-side-nav-super-admin',
  templateUrl: './top-side-nav.component.html',
  styleUrls: ['./top-side-nav.component.css']
})
export class TopSideNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
