import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-trip-id',
  templateUrl: './date-wise-trip-id.component.html',
  styleUrls: ['./date-wise-trip-id.component.css']
})
export class DateWiseTripIDComponent implements OnInit {
  dtOptions: any = {};
  expenseArr: any = [];
  LrExpenseArray: any = [];
  totalValue: any = [];
  income: any = [];
  paymentDue: any = [];
  myArr: any = [];
  paramId: any;
  users: any;
  month: any;
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  paramCompany: any;
  paramSupervisor: any;
  year: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      //console.log(param)
      this.paramId = param.id;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      console.log("param", param);


      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }

  ngOnInit(): void {
    this.getTripIDExpenseGroup();
    this.dtOptions = {
      dom: 'Bfrtip',
      
        buttons: [
          {
            extend: 'csv',
            text: 'Export'  
          }
          
        ]
      }
  }


  getTripIDExpenseGroup() {
    this.superadminService.getTripIDExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {

          // console.log("resresres", res);
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;
                // console.log("this.myArr", this.myArr);

                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })

                this.myArr = this.myArr.filter((item) => {
                  return item._id.Date.split('T')[0].split('-')[0] == this.year
                })

                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })
                console.log("this.paramSupervisor", this.paramSupervisor);
                console.log("myARR4", this.myArr);
                this.myArr = this.myArr.filter((x: any) => x._id.TripID === this.paramSupervisor)
                console.log("this.myArr5", this.myArr);

                for (let i = 0; i < this.myArr.length; i++) {
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "TripID": this.myArr[i]._id.TripID, "LR_no": this.myArr[i]._id.LR_no, "balancePaid": this.myArr[i]._id.BalancePaid, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.balanceDue, "Date": this.myArr[i]._id.Date, "income": this.myArr[i]._id.totalIncome, "expense": this.myArr[i]._id.totalExpense, "advance": this.myArr[i]._id.Advance, "waitingCharge": this.myArr[i]._id.waitingCharge, "total_value": this.myArr[i]._id.totalValue, "overLoadCharges": this.myArr[i]._id.overLoadCharges })

                }

                console.log("expenseArrexpenseArr", this.expenseArr)



                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    })
                    this.LrExpenseArray = this.expenseArr;
                    // console.log("LrExpenseArrayLrExpenseArray", this.LrExpenseArray);

                    // this.income = this.LrExpenseArray.map((x) => {
                    //   let tripId = x.TripID
                    //   let totalV = x.vehicleRate + x.waitingCharge - x.expense

                    //   return {[tripId]: totalV}
                    // })

                    this.paymentDue = this.LrExpenseArray.map((x) => {
                      let tripId = x.TripID
                      let totalV = x.vehicleRate + x.waitingCharge + x.overLoadCharges - x.advance
                      return { [tripId]: totalV }

                    })
                    this.totalValue = this.LrExpenseArray.map((x) => {
                      let tripId = x.TripID
                      return { [tripId]: x.vehicleRate + x.waitingCharge }
                    })

                    // console.log("TripID%%%%%%%%%%%%%%%%%", this.totalValue)


                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }



}
