import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-monthly-trip-id',
  templateUrl: './monthly-trip-id.component.html',
  styleUrls: ['./monthly-trip-id.component.css']
})
export class MonthlyTripIDComponent implements OnInit {

  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  paramId: any;
  totalVehicleRate: any = [];
  income: any = [];
  paymentDue: any = [];
  totalSum: any;
  month: any;
  count: any = []
  removeDuplicates: any;
  tripData: any;
  LrExpenseArray: any = [];
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  myArr: any;
  lrCount: any;
  expenseArr: any = [];
  year: any;
  G_total_value: any;
  gtotalvalue: any;
  veh_rate: any;
  gtotal_exp: any;
  gtotalExpense: any;
  gtotalIncome: any;
  gAdvance: any;
  gpaymentDue: any;
  dtOptions: any = {};

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService, private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.month;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }



  ngOnInit(): void {
    this.getTripIDExpenseGroup()
    this.gettrididData()
    this.dtOptions = {
      dom: 'Bfrtip',
      
        buttons: [
          {
            extend: 'csv',
            text: 'Export'  
          }
          
        ]
      }
  }

  getTripIDExpenseGroup() {
    this.spinner.show();
    this.superadminService.getTripIDExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;
               // console.log("this.myArrthis.myArr", this.myArr)
                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })
                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
               // console.log("@this.myArr#", this.myArr);
                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })
                for (let i = 0; i < this.myArr.length; i++) {
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "TripID": this.myArr[i]._id.TripID, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.balanceDue, "income": this.myArr[i]._id.totalIncome, "LR_no": this.myArr[i]._id.LR_no, "totalValue": this.myArr[i]._id.totalValue, "totalExpense": this.myArr[i]._id.totalExpense, "totalIncome": this.myArr[i]._id.totalIncome, "paymentDue": this.myArr[i]._id.paymentDue, "waitingCharge": this.myArr[i]._id.waitingCharge, "overLoadCharges": this.myArr[i]._id.overLoadCharges })
                }
               // console.log("%this.expenseArr%", this.expenseArr)
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    this.spinner.show()
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    })

                    //console.log("^^^^^%%%%%", this.LrExpenseArray)
                    this.lrCount = this.myArr.map((x: any) => x._id.TripID)
                    this.removeDuplicates = this.expenseArr.filter(({ TripID }, index) =>
                      !this.lrCount.includes(TripID, index + 1)
                    )
                   // console.log("this.lrCount", this.lrCount);
                   // console.log("this.removeDuplicates", this.removeDuplicates)
                    this.lrCount.forEach((x) => {

                      if (this.count[x]) {
                        this.count[x] += 1;

                      } else {
                        this.count[x] = 1;

                      }
                    })
                    // console.log("number of count", this.count)

                    let result = [];

                    this.expenseArr.forEach(function (a) {
                      if (!this[a.TripID]) {
                        this[a.TripID] = { TripID: a.TripID, supervisorId: a.SupervisorName, totalValue: 0, vehicleRate: 0, waitingCharge: 0, totalExpense: 0, Advance: 0, totalIncome: 0, paymentDue: 0 };
                        result.push(this[a.TripID]);
                      }

                      this[a.TripID].vehicleRate += a.vehicleRate;
                      this[a.TripID].totalValue += a.totalValue;
                      this[a.TripID].waitingCharge += a.waitingCharge;
                      this[a.TripID].totalExpense += a.totalExpense;
                      this[a.TripID].Advance += a.Advance;
                      this[a.TripID].totalIncome += a.totalIncome;
                      this[a.TripID].paymentDue += a.paymentDue;
                    }, Object.create(null));
                    //console.log("result", result);
                    this.totalVehicleRate = result


                   // console.log("this.totalVehicleRate", this.totalVehicleRate)
                    // this.gtotalvalue = this.totalVehicleRate[0].totalValue;
                    // this.veh_rate = this.totalVehicleRate[0].vehicleRate;
                    // this.gtotal_exp = this.totalVehicleRate[0].vehicleRate;
                    // this.gtotalExpense = this.totalVehicleRate[0].totalExpense;
                    // this.gtotalIncome = this.totalVehicleRate[0].totalIncome;
                    // this.gAdvance = this.totalVehicleRate[0].Advance;
                    // this.gpaymentDue = this.totalVehicleRate[0].paymentDue;


                    const map = new Map()

                    //debugger;
                    this.gtotalvalue = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.totalValue }

                    })




                    //console.log("1122this.totalVehicleRate", this.totalVehicleRate)
                    this.veh_rate = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.vehicleRate }

                    })

                    this.gtotal_exp = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.totalExpense }

                    })


                    this.gtotalIncome = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.totalIncome }

                    });


                    this.gAdvance = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.Advance }

                    });


                    this.gpaymentDue = this.totalVehicleRate.map((x) => {
                      // this.totalSum.TripID = x.TripID
                      let tripId = x.TripID
                      return { [tripId]: x.paymentDue }

                    });
                    // console.log("this.gpaymentDue", this.gpaymentDue);
                    // console.log("this.gAdvance", this.gAdvance);
                    // console.log("this.gtotalIncome", this.gtotalIncome);
                    // console.log("this.gtotal_exp", this.gtotal_exp);
                    // console.log("this.veh_rate", this.veh_rate);
                    // console.log("this.gtotalvalue@#@#", this.gtotalvalue);










                    // this.income = this.totalVehicleRate.map((x) => {
                    //   let tripId = x.TripID
                    //   let totalValue = x.vehicleRate + x.waitingCharge + x.overLoadCharges
                    //   let expensees = x.expense
                    //   return { [tripId]: totalValue - expensees }


                    // })
                    // console.log("this.income", this.income);

                    this.paymentDue = this.totalVehicleRate.map((x) => {
                      let tripId = x.TripID
                      let totalValue = x.vehicleRate + x.waitingCharge
                      let advance = x.Advance
                      // console.log("advance", advance)
                      return { [tripId]: totalValue - advance }
                    })
                    this.spinner.show()
                    this.LrExpenseArray = this.expenseArr;
                    this.spinner.hide()
                    // console.log(this.LrExpenseArray);
                    // console.log("total sum", this.totalSum)
                    // console.log("income", this.paymentDue)


                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }

  gettrididData() {
    this.spinner.show();
    this.superadminService.getTripidData().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.spinner.hide()
        this.tripData = res.data
         console.log("datassssss^^^^^&&&", this.tripData)
      } else {
        this.toastr.error(res.message)
      }
    })
  }

}
