<div class="mainebox">
    <div class="leftpart">
       <app-left-side-nav-super-admin></app-left-side-nav-super-admin>
    </div>
    <div class="rightpart">
        <div class="rightheader d-block d-sm-block d-md-none d-lg-none">
            <div class="row">
                <div class="col-md-9 col-9 py-2 ps-4">
                    <div class="logonav" [routerLink]="['/superAdmin/mydashboard']">
                        <img src="../../../assets/images/logobig.png"  width="220" alt="Radha Swami">
                     </div>
                </div>
                <div class="col-md-3 col-3 my-auto text-center">
                    <button class="btn btn-primary position-static menuExtande open"><i class="fa-solid fa-bars"></i></button>
                </div>
            </div>
        </div>
        <div class="mainpart">
            <router-outlet></router-outlet>

        </div>
    </div>
</div>