import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MydashboardComponent } from './mydashboard.component';
import { ViewComponent } from './view/view.component';
import { MonthlyExpenseComponent } from './monthly-expense/monthly-expense.component';
import { CategoryExpenseComponent } from './category-expense/category-expense.component';
import { DateWiseDispenseComponent } from './date-wise-dispense/date-wise-dispense.component';
import { DateWiseCategoryComponent } from './date-wise-category/date-wise-category.component';
import { DateWiseExpenseComponent } from './date-wise-expense/date-wise-expense.component';
import { MonthlyLrExpenseComponent } from './monthly-lr-expense/monthly-lr-expense.component';
import { DateWiseLrExpenseComponent } from './date-wise-lr-expense/date-wise-lr-expense.component';
import { TotalLRComponent } from '../total-lr/total-lr/total-lr.component';
import { TotalInvoiceComponent } from '../total-Invoice/total-invoice/total-invoice.component';
import { TotalVehicleComponent } from '../total-vehicle/total-vehicle.component';
import { TotalInvoiceTripIDComponent } from '../total-invoice-trip-id/total-invoice-trip-id.component';
import { MonthlyTripIDComponent } from './monthly-trip-id/monthly-trip-id.component';
import { MonthlyVehicleComponent } from './monthly-vehicle/monthly-vehicle.component';
import { DateWiseTripIDComponent } from './date-wise-trip-id/date-wise-trip-id.component';
import { DateWiseVehicleComponent } from './date-wise-vehicle/date-wise-vehicle.component';


const routes: Routes = [
  { path: '', component: MydashboardComponent },
  { path: 'monthlyDispense/:id/:year', component:  ViewComponent },
  { path: 'monthlyExpense/:id/:year', component:  MonthlyExpenseComponent },
  { path: 'monthlyLrExpense/:month/:year', component:  MonthlyLrExpenseComponent },
  { path: 'categoryExpense/:category/:year', component:  CategoryExpenseComponent },
  { path: 'dateWiseDispense/:id/:company/:supervisor/:year', component:  DateWiseDispenseComponent },
  { path: 'dateWiseCategory/:category/:company/:supervisor/:month/:year', component:  DateWiseCategoryComponent },
  { path: 'dateWiseExpense/:id/:company/:supervisor/:year', component:  DateWiseExpenseComponent },
  { path: 'dateWiseLrExpense/:id/:company/:supervisor/:year', component:  DateWiseLrExpenseComponent },
  {path: 'totalLR/:param1/:filterType', component: TotalLRComponent},
  {path: 'totalInvoice/:param2/:filterInvoice', component: TotalInvoiceComponent},
  {path: 'totalInvoiceTripID/:param2/:filterInvoice', component: TotalInvoiceTripIDComponent},
  {path: 'totalVehicles/:param3/:filterVehicles', component: TotalVehicleComponent},
  {path: 'monthlyTripID/:month/:year', component: MonthlyTripIDComponent},
  {path: 'monthlyVehicle/:month/:year', component: MonthlyVehicleComponent},
  {path: 'dateWiseTripExpense/:id/:company/:supervisor/:year', component: DateWiseTripIDComponent},
  {path: 'dateWiseVehicle/:id/:company/:supervisor/:year/:vehicle_no', component: DateWiseVehicleComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes )],
  exports: [RouterModule]
})
export class MydashboardRoutingModule { }