import { Component } from '@angular/core';

@Component({
  selector: 'app-fuel-layout',
  templateUrl: './fuel-layout.component.html',
  styleUrls: ['./fuel-layout.component.css']
})
export class FuelLayoutComponent {

}
