<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Date-Wise Category Expense Report : {{month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>
    </div>

<div class="contentbox mt-3">

    <!-- <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Add New Driver</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button mat-raised-button matTooltip="Back" matTooltipPosition="left" (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                  <li class="breadcrumb-item" routerLink="/superAdmin/manageDriver">Manage Driver</li>
                  <li class="breadcrumb-item active" aria-current="page">Add New Driver</li>

                </ol>
              </nav>
        </div>
    </div> -->
    <div class="row customepadding">
        <div class="col-md-12 table-responsive ">

            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='categoryArray.length > 0'>
                <thead>
                    <tr>
                        <td>Date</td>
                        <!-- <td>LR Number</td> -->
                        <td>Company Name</td>
                        <td>Category</td>
                        <td>Supervisor</td>
                        <!-- <td>Destination</td> -->
                        <td>Expensed Log</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user1 of categoryArray">
                        <td>{{user1.date}}</td>
                        <!-- <td>{{user1.LrNumber}}</td> -->
                        <td>{{user1.companyName}}</td>
                        <td>{{user1.expenseSubCategory}}</td>
                        <td>{{user1.SupervisorName}}</td>
                        <!-- <td>{{user1.destination}}</td> -->
                        <td>{{user1.amount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>