import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddExpenseLogComponent } from './add-expense-log/add-expense-log.component';
import { HireVehicleFlatComponent } from './hire-vehicle-flat/hire-vehicle-flat.component';
import { HireVehicleMonthlyComponent } from './hire-vehicle-monthly/hire-vehicle-monthly.component';
import { OfficeExpanceListComponent } from './office-expance-list/office-expance-list.component';
import { OfficeExpenseComponent } from './office-expense/office-expense.component';
import { OurownVehicleExpenseComponent } from './ourown-vehicle-expense/ourown-vehicle-expense.component';
import { OwnVehicleExpenceListComponent } from './own-vehicle-expence-list/own-vehicle-expence-list.component';
import { VehicleMaintainanceListComponent } from './vehicle-maintainance-list/vehicle-maintainance-list.component';
import { VehicleMaintenanceComponent } from './vehicle-maintenance/vehicle-maintenance.component';
import { EditOfficeExpenseComponent } from './edit-office-expense/edit-office-expense.component';
import { EditVehicleMaintenanceComponent } from './edit-vehicle-maintenance/edit-vehicle-maintenance.component';
import { ViewExpanceComponent } from './view-expance/view-expance.component';
import { ViewVehicleMaintanceComponent } from './view-vehicle-maintance/view-vehicle-maintance.component';

const routes: Routes = [
  {path:'', redirectTo:"officeExpense", pathMatch:"full"},
  {path:'officeExpense', component:OfficeExpanceListComponent },
  {path:'officeExpense/AddOfficeExpense', component:OfficeExpenseComponent},
  {path:'officeExpense/editOfficeExpense/:id', component: EditOfficeExpenseComponent,},
  {path:'vehicleMaintenence', component:VehicleMaintainanceListComponent},
  // {
  //   path: 'expanceList', component:OfficeExpanceListComponent,
  // },
  {
    path: 'vehicleMaintenence/editVehicleMaintenance/:id', component: EditVehicleMaintenanceComponent,
  },
  {path: 'vehicleMaintenence/AddvehicleMaintenence', component:VehicleMaintenanceComponent},
  {path:'ownVehicleExpenceList', component:OwnVehicleExpenceListComponent},
  {path:'addOwnVehicleExpence', component:OurownVehicleExpenseComponent},
  {path:'officeExpense/view/:id', component:ViewExpanceComponent},
  {path:'vehicleMaintenence/viewMaintaince/:id', component:ViewVehicleMaintanceComponent}

  // {
  //   path: 'hireVicheleFlat', component:HireVehicleFlatComponent,
  // },
  // {
  //   path: 'hireVicheleMonthly', component:HireVehicleMonthlyComponent,
  // },
  // {
  //   path: 'AddOfficeExpense', component:OfficeExpenseComponent,
  // },
  // {
  //   path: 'ourownVehicleExpense', component:OurownVehicleExpenseComponent,
  // },
  

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageExpenseLogRoutingModule { }
