<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Maintenance</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipClass="example-tooltip-uppercase"
                    matTooltipPosition="left" mat-raised-button (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses </li>
                    <li class="breadcrumb-item" (click)="backTo()">Vehicle Maintenance</li>
                    <li class="breadcrumb-item" (click)="backTo()">View Maintenance</li>

                </ol>
            </nav>
        </div>
    </div>
    <div class="contentbox  mt-3">
        <div class="row ">
            <div class="col-xl-12">
                <div class="detailsBox">
                    <div class="row">
                        <div class="col-xl-3">
                            <label for="">Supervisor Name</label>

                            <h5>{{particularExpense.SupervisorName}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Expense Category</label>
                            <h5>{{particularExpense.expenseSubCategory}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Company Name</label>
                            <h5>{{particularExpense.companyName}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Expense Amount</label>
                            <h5>{{particularExpense.totalExpenseAmount}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Expense Date</label>
                            <h5>{{particularExpense.expenseDate|date}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Vehicle Number</label>
                            <h5>{{particularExpense.vehicleNumber}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Vehicle Type</label>
                            <h5>{{particularExpense.vehicleType}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Other Charges</label>
                            <h5>{{particularExpense.otherCharges}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Mechanic Name</label>
                            <h5>{{particularExpense.mechanicName}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Mechanic Number</label>
                            <h5>{{particularExpense.mechanicNumber}}</h5>
                        </div>

                        <div class="col-xl-6">
                            <label for="">Expense Description</label>
                            <h5>{{particularExpense.expenseDescription}}</h5>
                        </div>
                        <div class="col-xl-3">
                            <label for="">Attachment</label>
                            <div class="viewImage" *ngIf="particularExpense.attachReceipt1">
                                <img [id]=" apiUrl+'/uploads/'+particularExpense.attachReceipt1" onClick="viewImages(this, 'Expense Attachment')"  [src]="apiUrl+'/uploads/'+particularExpense.attachReceipt1" alt="Pan Card" />
                            </div>
                            <h5 *ngIf="!particularExpense.attachReceipt1">N/A</h5>
                            <!-- <div>
                                <button class="btn btn-dark btn-sm  px-4" type="button" (click)="preview()"
                                    mat-raised-button>Preview</button>
                                <a #showSlip [href]="slipURL" target="_blank"></a>
                            </div> -->
                            <a #showSlip [href]="slipURL" target="_blank"></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>