<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Advance Cash</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" (click)="backTo()" >Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">View Advance Cash</li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-xl-12">
                            <div class="detailsBox">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <label for="">Date</label>
                                        <h5>{{this.particular_advance?.Avance_date_of_expenses}}</h5>
                                    </div>
                                    <div class="col-xl-4">
                                        <label for="">Advance Amount</label>
                                        <h5>{{this.particular_advance?.advance_amount}}</h5>
                                    </div>
                                    <div class="col-xl-4">
                                        <label for="">Payment Mode</label>
                                        <h5>{{this.particular_advance?.Payment_mode}}</h5>
                                    </div>
                                    

                                    <div class="col-xl-4">
                                        <label for="">Description</label>
                                        <h5>{{this.particular_advance?.description}}</h5>
                                    </div>
                                    <!-- <div class="col-xl-4">
                                        <label for="">Attachment</label>
                                        <h5>{{this.particular_advance?.advance_slip}}</h5>
                                    </div> -->

                                    <!-- <div class="col-xl-3">
                                        <label for="">Advance Slip</label>
                                        <div class="viewImage" *ngIf="this.particular_advance?.advance_slip">
                                            <img [id]=" apiUrl+'/uploads/'+this.particular_advance?.advance_slip" onClick="viewImages(this, 'Expense Attachment')"  [src]="apiUrl+'/uploads/'+this.particular_advance?.advance_slip" alt="Advance Slip" />
                                        </div>
                                        <h5 *ngIf="!this.particular_advance?.advance_slip">N/A</h5>
                                        
                                    </div> -->

                                
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</div>