import { Component } from '@angular/core';

@Component({
  selector: 'app-otherexpence',
  templateUrl: './otherexpence.component.html',
  styleUrls: ['./otherexpence.component.css']
})
export class OtherexpenceComponent {
  fuelSlipPreview:any;

  fileChangeEventPODAttchment(e){

  }
  resetimg(e){

  }

  fuelExpenseSubmit(){
    
  }
}
