import { Component } from '@angular/core';

@Component({
  selector: 'app-truck-expence',
  templateUrl: './truck-expence.component.html',
  styleUrls: ['./truck-expence.component.css']
})
export class TruckExpenceComponent {
  fuelSlipPreview:any;

  fileChangeEventPODAttchment(e){

  }
  resetimg(e){

  }

  fuelExpenseSubmit(){
    
  }
}
