import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { data } from 'jquery';

@Component({
  selector: 'app-view-lr',
  templateUrl: './view-lr.component.html',
  styleUrls: ['./view-lr.component.css']
})
export class ViewLrComponent implements OnInit {
  particlarLR: any;
  vehiclesDetail: any;
  companyName:any;
  city:any;
  clientList:any;
  paramId;
  rawdata: any;
  companyNames: any;
  consignee_city: any;
  consignor_city: any;

  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,private router: Router ,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getUserDetails(this.paramId);
    })
  }

  ngOnInit(): void {
    this.getClientList()


  }


  getTotalWeight(invoices: any[]) {
    if (invoices.length > 0) {
      let totalWeight = 0;
      invoices.map((item: any) => totalWeight += Number(item.Weight));
      return totalWeight;
    }
    return 0;
  }

  getTotalHrs(totalHour: any, extraHour: any) {
    if (totalHour && extraHour) {
      return Number(extraHour) + Number(totalHour);
    }
    return 0;
  }

  sendEmail(divName) {
    var data = document.getElementById(divName);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png', 0.3)
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
      let binary = pdf.output();
      binary = binary ? btoa(binary) : '';
      this.superAdminService.sendEmail(this.paramId, binary).subscribe((res: any) => {
        if (res.status == true) {
          this.toastr.success(res.message);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
        }
      }, );
    });

  }
  onPrint(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    //window.print();
  }
  getUserDetails(id: string) {
    this.superAdminService.particlarLR(id).subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.rawdata = res
        console.log("rawdata",this.rawdata)
        this.particlarLR = res.data[0].receipts[0];
        this.vehiclesDetail = res.data[0].vehiclesDetail[0];
        console.log("particular ----LR",this.particlarLR)
       // console.log("vhicle Detail", this.vehiclesDetail)

      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });
  }
  backTo() {
    window.history.back()
  }


  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if(res.status == true){
          this.clientList = res.data
          // console.log("company List",this.clientList)
          for(let i = 0 ; i < this.clientList.length; i++){
            if(this.particlarLR?.clientId === this.clientList[i]._id){
              this.companyName = this.clientList[i].clientName
             // this.city = this.clientList[i].city

            }
          }
          console.log("company List",this.clientList)
          console.log("this.particlarLR",this.particlarLR)
          for(let i = 0; i<this.clientList.length;i++){
            if(this.particlarLR?.consignee_name == this.clientList[i].clientName){
              this.consignee_city = this.clientList[i].city
              console.log("consignee_namesconsignee_names",this.consignee_city)
            }
            if(this.particlarLR?.consignor_name == this.clientList[i].clientName){
              this.consignor_city = this.clientList[i].city
              console.log("consignor_cityconsignor_city",this.consignor_city)
            }
          }
                  
          
          
        }else {
          this.toastr.error(res.message)
        }

})
  }


  }
