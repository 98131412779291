<div class="container-fluid" [ngSwitch]="checkInvoiceType">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View Invoice</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View Invoice </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">


                    <button mat-raised-button type="button" class="btn btn-outline-dark btn-sm px-4" ngxPrint
                        [useExistingCss]="true" printSectionId="print-section3">Print</button>&nbsp;


                    <button routerLink="../../gst-invoice/{{this.paramId}}" mat-raised-button
                        class="btn btn-dark btn-sm px-4">GST Invoice</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendInvoice('print-section3')">Email</button>
                </div>

                <div class="row justify-content-center mt-3 ">
                    <div class="col-xl-10">
                        <div class="sgtcTable table-responsive" id="print-section3">
                            <table class="w-100">
                                <tr>
                                    <td>
                                        <table class="tableHeader">
                                            <tr>
                                                <td>
                                                    <img src="../../../../assets/images/jpg/sgtcBlue.png" width="120" />
                                                </td>
                                                <td>
                                                    <h1>SARAYYAN GOLDEN TRANSPORT COMPANY</h1>
                                                    <h2>SHOP NO-03 OPP-PLOT NO-80 SECTOR-25 FARIDABAD 121004-HARYANA
                                                    </h2>
                                                    <h2> PHONE NO-9899143886-9811376011 </h2>
                                                    <h3>Email-Sgtcmajid@Gmail.com</h3>
                                                </td>
                                                <td>
                                                    <h2 class="text-start">PAN NO-ATNPK1992P</h2>
                                                    <h2 class="text-start">GST NO- 06ATNPK1992P2ZU</h2>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="invoiceAdressDetails">
                                            <tr>
                                                <td><b>Billing Party </b></td>
                                                <td>
                                                    <div class="flexBoxnew">
                                                        <span>Invoice no.</span>
                                                        <span>SGTC/{{this.sys_inv}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="color: #090086; font-weight: 600;">{{this.dataaa?.clientName}} </td>
                                                <td>
                                                    <div class="flexBoxnew">
                                                        <span>Date</span>
                                                        <span>{{this.createddate | date:'dd-MM-yyyy'}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>GSTIN.NO. {{this.dataaa?.CGSTNumber}}</td>
                                                <td>
                                                    <div class="flexBoxnew">
                                                        <span>MODE&nbsp;BY&nbsp;TRANSPORT</span>
                                                        <span>BY ROAD</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{this.dataaa?.address}}</td>
                                                <td>
                                                    <div class="flexBoxnew">
                                                        <span>SUBJECTS</span>
                                                        <span>Transportation Charges</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{this.dataaa?.city}} - {{this.dataaa?.stateCode}}- {{this.dataaa?.state}}</td>
                                                <td>
                                                    <div class="flexBoxnew">
                                                        <span>State Code</span>
                                                        <span>{{this.companystatecode}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="invoiceDetails">
                                            <thead>
                                                <tr class="bggray">
                                                    <td>SR. NO.</td>
                                                    <td>G.R NO</td>
                                                    <td>G.R DATE</td>
                                                    <td>SAC CODE</td>
                                                    <td>VEHICLE NO</td>
                                                    <td>FROM</td>
                                                    <td>TO</td>
                                                    <td>INVOICE NO</td>
                                                    <td>G.R CHARGES</td>
                                                    <td>QTY</td>
                                                    <td>WEIGHT</td>
                                                    <td>RATE</td>
                                                    <td>FREIGHT</td>
                                                    <td>LABOUR</td>
                                                    <td>TOTAL AMOUNT</td>

                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let user of users; let j=index">
                                                <tr *ngFor=" let a of user.LR_no ;let i = index">
                                                    <td>{{user.manualLR_no[i]}}</td>
                                                    <td>{{user.LR_no[i]}} </td>
                                                    <td>{{user.created_date[i] | date:'dd-MM-yyyy'}}</td>
                                                    <td>996791 </td>
                                                    <td>{{user.vehicle_id[i]}}</td>
                                                    <td>{{user.from[i]}}</td>
                                                    <td>{{user.to[i]}}</td>
                                                    <td>{{user.InvoiceNo[i]}}</td>
                                                    <td>&nbsp;</td>
                                                    <td>{{user.no_of_package[i]}}</td>
                                                    <td>{{user.quantity[i]}}</td>
                                                    <td></td>
                                                    <td>{{user.total_value[i]}}</td>
                                                    <td>{{user.labourPaid[i]}}</td>
                                                    <td>{{user.total_invoice_amount
                                                        [i]}}</td>
                                                </tr>



                                                <tr class="bggray">

                                                    <td colspan="14" class="text-start font600">Total</td>
                                                    <td>{{this.invtot}}</td>
                                                </tr>
                                                <tr class="bggray">
                                                    <td colspan="14" class="text-start font600">CGST-06%</td>
                                                    <td>{{this.cgst}}</td>
                                                </tr>
                                                <tr class="bggray">
                                                    <td colspan="14" class="text-start font600">SGST-06%</td>
                                                    <td>{{this.sgst}}</td>
                                                </tr>
                                                <tr class="bggray">
                                                    <td colspan="14" class="text-start font600">Advance</td>
                                                    <td>-{{this.advance}}</td>
                                                </tr>
                                                <!-- <tr class="bggray">
                                                    <td colspan="14" class="text-start font600" >Rupees In Word: One thousand, three hundred sixty only</td>
                                                     <td>{{this.amount_in_words}}</td>
                                                 </tr> -->
                                                <tr class="bggray">
                                                    <td colspan="14" class="text-start font600">{{this.amount_in_words |
                                                        amountInWords}}
                                                    </td>
                                                    <td>{{this.amount_in_words}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="15" class="text-start font600">Decleration-ALL Material
                                                        & Pod Are In Good Condition</td>

                                                </tr>
                                                <tr>
                                                    <td colspan="15" class="text-start font600">REMARK</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="tableHeader footerText2">
                                            <tr>
                                                <td>
                                                    <h2>ACCOUNT NAME: SARYYAN GOLDEN TRANSPORT COMPANY</h2>
                                                    <h2>BANK NAME:HDFC BANK</h2>
                                                    <h2>Account. Number : 06192000003338 </h2>
                                                    <h2>Ifsc Code: HDFC0000619</h2>
                                                    <h2>Branch name : Sector 9, Faridabad</h2>

                                                </td>
                                                <td> &nbsp; </td>
                                            </tr>
                                            <tr>
                                                <td> &nbsp; </td>
                                                <td>
                                                    <h2 class="text-center">For. SARAYYAN GOLDEN TRANSPORT COMPANY</h2>
                                                    <h3 class="text-center">Authorised Signatory</h3>

                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>