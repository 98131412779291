<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">GST Invoice</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR</li>
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement/view-invoice">View Invoice
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">GST Invoice</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">

                    <!-- <button mat-raised-button class="btn btn-outline-dark btn-sm px-4"  printSectionId="print-section" >Print</button>&nbsp; -->
                    <button mat-raised-button type="button" class="btn btn-outline-dark btn-sm px-4" ngxPrint
                        [useExistingCss]="true" printSectionId="print-section">Print</button>&nbsp;
                </div>
                <div class="gstInvoice table-responsive" id="print-section">
                    <div class="newInvoiceBox">
                        <table>
                            <tr>
                                <td colspan="2" class="text-center">
                                    <h1>GST Invoice</h1>

                                </td>
                            </tr>

                            <tr>
                                <td style="padding: 0;" colspan="7">
                                    <table class="tableHeader">
                                        <tr>
                                            <td >
                                                <img src="../../../../assets/images/jpg/sgtcBlue.png" width="120" />
                                            </td>
                                            <td>
                                                <h1>SARAYYAN GOLDEN TRANSPORT COMPANY</h1>
                                                <h2>SHOP NO-03 OPP-PLOT NO-80 SECTOR-25 FARIDABAD 121004-HARYANA
                                                </h2>
                                                <h2> PHONE NO-9899143886-9811376011 </h2>
                                                <h3>Email-Sgtcmajid@Gmail.com</h3>
                                            </td>
                                            <td>
                                                <h2 class="text-start">PAN NO-ATNPK1992P</h2>
                                                <h2 class="text-start">GST NO- 06ATNPK1992P2ZU</h2>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            
                            <!-- <tr>
                                <td style="padding: 0;" colspan="7">
                                    <table class="tableHeader">
                                        <tr>
                                            <td>
                                                <img src="../../../../assets/images/jpg/sgtcBlue.png" width="120" />
                                            </td>
                                            <td>
                                                <h1>SARAYYAN GOLDEN TRANSPORT COMPANY</h1>
                                                <h2>SHOP NO-03 OPP-PLOT NO-80 SECTOR-25 FARIDABAD 121004-HARYANA
                                                </h2>
                                                <h2> PHONE NO-9899143886-9811376011 </h2>
                                                <h3>Email-Sgtcmajid@Gmail.com</h3>
                                            </td>
                                            <td>
                                                <h2 class="text-start">PAN NO-ATNPK1992P</h2>
                                                <h2 class="text-start">GST NO- 06ATNPK1992P2ZU</h2>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr> -->
                            
                            <tr>
                                <td style="padding: 0; border: 0;" colspan="7">
                                    <table class="invoiceAdressDetails">
                                        <tr>
                                            <td><b>Billing Party </b></td>
                                            <td>
                                                <div class="flexBoxnew">
                                                    <span>Invoice no.</span>
                                                    <span>SGTC {{this.sys_gen_no}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="color: #090086; font-weight: 600;">{{this.dataaa?.clientName}}
                                            </td>
                                            <td>
                                                <div class="flexBoxnew">
                                                    <span>Date</span>
                                                    <span>{{this.Inv_created_date | date :'dd-MM-yyyy'}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN.NO. {{this.dataaa?.CGSTNumber}}</td>
                                            <td>
                                                <div class="flexBoxnew">
                                                    <span>MODE&nbsp;BY&nbsp;TRANSPORT</span>
                                                    <span>BY ROAD</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{this.dataaa?.address}}</td>
                                            <td>
                                                <div class="flexBoxnew">
                                                    <span>SUBJECTS</span>
                                                    <span>Transportation Charges</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{this.dataaa?.city}}, {{this.dataaa?.company_state_code}}, {{this.dataaa?.state}}</td>
                                            <td>
                                                <div class="flexBoxnew">
                                                    <span>State Code</span>
                                                    <span>{{this.dataaa?.company_state_code}}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="p-0">
                                    <table class="gstBox">
                                        <tr>
                                            <td>No.</td>
                                            <td>Invoice No</td>
                                            <td>TOTAL (A)</td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>CGST (B)</div>
                                                    <div>6%</div>
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>SGST (C)</div>
                                                    <div>6%</div>
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div class="gridbox">
                                                    <div>IGST (D)</div>
                                                    <div>12%</div>
                                                </div>
                                            </td>
                                            <td>(A+B+C+D) TOTAL</td>
                                        </tr>
                                        <tr>
                                            <td>1. </td>
                                            <td>{{this.sys_gen_no}}</td>
                                            <td>{{this.invtot | number:'1.2-2'}}</td>
                                            <td>
                                                {{this.cgst | number:'1.2-2'}}
                                            </td>
                                            <td>
                                                {{this.sgst | number:'1.2-2'}}
                                            </td>
                                            <!-- <td>
                                                {{this.eightPercent | number:'1.2-2'}}
                                            </td> -->
                                            <td>
                                                {{this.igst | number:'1.2-2'}}
                                            </td>
                                            <td>{{this.over_all_amount | number:'1.2-2'}} </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6"><b>Total</b> </td>

                                            <td><b>{{this.over_all_amount | number:'1.2-2'}}</b> </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p class="mb-0 smallText">Note:</p>
                                    <p class="mb-0 smallText">1. For IGST: Transportation of goods by road is exempted
                                        by notification no 9/2017 hence no GST is applicable.</p>
                                </td>
                                <td class="text-center">

                                    <p class="smallText">SARAYYAN GOLDEN TRANSPORT COMPANY</p>
                                    <p class="mb-0 smallText">Signature</p>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>