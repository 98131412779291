import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-monthly-vehicle',
  templateUrl: './monthly-vehicle.component.html',
  styleUrls: ['./monthly-vehicle.component.css']
})
export class MonthlyVehicleComponent implements OnInit {


  monthArr: any = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthArrFull: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  paramId: any;
  month: any;
  vehicleExpenseArray: any = [];
  userSessionData: any = JSON.parse(sessionStorage.getItem('userSession'));
  myArr: any;
  expenseArr: any = [];
  year: any;
  lrCount: any;
  removeDuplicates: any;
  count: any = []
  vcount: any;
  duplicates: any[];
  vvcount: any;
  dtOptions: any = {};

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private toastr: ToastrService, private superadminService: SuperAdminService, private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.month;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.year = param.year;
    })
  }

  ngOnInit(): void {
    this.getVehicleExpenseGroup();
    this.dtOptions = {
      dom: 'Bfrtip',
      
        buttons: [
          {
            extend: 'csv',
            text: 'Export'  
          }
          
        ]
      }
  }

  getVehicleExpenseGroup() {
    this.superadminService.getVehicleExpenseParticularMonth()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          // console.log("res.data",res.data);
          this.superadminService.getClientList()
            .subscribe((client: { status: any, message: any, data: any }) => {
              if (client.status == true) {
                this.myArr = res.data;
                // console.log("this.myArrthis.myArr", this.myArr)
                this.myArr = this.myArr.filter((item) => {
                  return item._id.year == this.year
                })

                if (this.userSessionData.user_role_id[0] != 1) {
                  this.myArr = this.myArr.filter((item) => {
                    return item._id.supervisorId == this.userSessionData._id
                  })
                }
                //console.log("@this.myArr#", this.myArr);
                this.myArr = this.myArr.filter((item) => {
                  return this.monthArr[item._id.month - 1] == this.paramId
                })
                //  console.log("121212this.myArr$$$$$$", this.myArr)
                for (let i = 0; i < this.myArr.length; i++) {
                  // debugger;
                  this.expenseArr.push({ "supervisorId": this.myArr[i]._id.supervisorId, "companyId": this.myArr[i]._id.companyId, "TripID": this.myArr[i]._id.TripID, "Advance": this.myArr[i]._id.Advance, "amount": this.myArr[i].amount, "vehicleRate": this.myArr[i]._id.vehicleRate, "balanceDue": this.myArr[i]._id.balanceDue, "income": this.myArr[i]._id.totalIncome, "LR_no": this.myArr[i]._id.LR_no, "totalValue": this.myArr[i]._id.totalValue, "totalExpense": this.myArr[i]._id.totalExpense, "totalIncome": this.myArr[i]._id.totalIncome, "paymentDue": this.myArr[i]._id.paymentDue, "waitingCharge": this.myArr[i]._id.waitingCharge, "overLoadCharges": this.myArr[i]._id.overLoadCharges, "VehicleID": this.myArr[i]._id.vehicleNo, })
                }
                // console.log("#this.expenseArr", this.expenseArr)
                this.expenseArr.forEach((x, index) => {
                  client.data.filter(y => {
                    if (y._id == x.companyId) this.expenseArr[index].companyName = y.clientName
                  })
                })
                this.superadminService.getSuperVisorName().subscribe((supervisors: { status: any, message: any, data: any }) => {
                  if (supervisors.status == true) {
                    // this.spinner.show();
                    var supervisorsData = supervisors.data;
                    this.expenseArr.forEach((item, i) => {
                      this.expenseArr[i].SupervisorName = supervisorsData.filter((item) => this.expenseArr[i].supervisorId == item._id);
                      this.expenseArr[i].SupervisorName = this.expenseArr[i].SupervisorName[0] ? this.expenseArr[i].SupervisorName[0]['full_name'] : this.expenseArr[i].SupervisorName[0]
                    });

                  //  console.log("for c count", this.myArr);

                    this.lrCount = this.myArr.map((x: any) => x._id.TripID)
                    this.vcount = this.myArr.map((x: any) => x._id.vehicleNo)

                    const counts = {};
                    this.vcount.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                    this.vvcount = counts
                  //  console.log("ccccthis.vcount",counts);
                   // console.log("ccccthis.vcount",this.vvcount);

                   // console.log("vcount", this.vcount);
                    this.removeDuplicates = this.expenseArr.filter(({ TripID }, index) =>
                      !this.lrCount.includes(TripID, index + 1)
                    )
                    //console.log("this.lrCount", this.lrCount)
                    // console.log("this.removeDuplicates", this.removeDuplicates);
                    this.lrCount.forEach((x) => {

                      if (this.count[x]) {
                        this.count[x] += 1;

                      } else {
                        this.count[x] = 1;

                      }
                    })

                   // console.log("this.lrCount", this.lrCount);
                   // console.log("this.expenseArr123321", this.expenseArr);
                    let result = [];

                    this.expenseArr.forEach(function (a) {
                      if (!this[a.VehicleID]) {
                        this[a.VehicleID] = { VehicleID: a.VehicleID, supervisorId: a.SupervisorName, totalValue: 0, vehicleRate: 0, waitingCharge: 0, totalExpense: 0, Advance: 0, totalIncome: 0, paymentDue: 0, vehicle_no: a.VehicleID };
                        result.push(this[a.VehicleID]);
                      }

                      this[a.VehicleID].vehicleRate += a.vehicleRate;
                      this[a.VehicleID].totalValue += a.totalValue;
                      this[a.VehicleID].waitingCharge += a.waitingCharge;
                      this[a.VehicleID].totalExpense += a.totalExpense;
                      this[a.VehicleID].Advance += a.Advance;
                      this[a.VehicleID].totalIncome += a.totalIncome;
                      this[a.VehicleID].paymentDue += a.paymentDue;
                    }, Object.create(null));
                   // console.log("result", result);
                    this.vehicleExpenseArray = result


                    const map = new Map()


                    // this.vehicleExpenseArray = this.expenseArr;
                    //  console.log("@this.vehicleExpenseArray", this.vehicleExpenseArray)
                  } else {
                    // console.log(res.message);
                  }
                }, error => {
                  // console.log('error', error);
                })
              }
              else {
                if (res.status == 401) {
                  this.toastr.error(res.message);
                  window.sessionStorage.clear();
                  this.router.navigateByUrl('superAuth/login');

                } else {
                  this.toastr.error(res.message);
                }
              }

            }, error => {
            });
        } else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }
      )
  }



  backTo() {
    window.history.back()
  }

}
