import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.css']
})
export class NewInvoiceComponent implements OnInit {
  checkInvoiceType: 'Type3';
  totalGstSstSum: any;
  sixPercent: any;
  eightPercent: any;
  totalInvValueWithGST: any;
  invtot: any;
  tooltaxPaidtot: any;
  clientName: any;
  extrahour: any;
  vehicles: any;
  clientList = [];
  lrID: any;
  particlarLR: any;
  kmtot: any;
  users: any;
  paramId;
  sys_Invoice: any;

  editLR = new FormGroup({
    clientList: new FormControl(''),
    ConsigneeName: new FormControl(''),
    ConsigneeAddress: new FormControl(''),
    ConsignorName: new FormControl(''),
    ConsignorAddress: new FormControl(''),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl(''),
    to: new FormControl('',),
    GSTIN: new FormControl(''),
    date: new FormControl(''),
    RoadPermitNo: new FormControl(''),
    StateCode: new FormControl(''),
    DLY: new FormControl(''),
    DeclaredValue: new FormControl(''),
    fixAdhoc: new FormControl(''),
    quantity: new FormControl(''),
    transportorName: new FormControl(''),
    transportorMobile: new FormControl(''),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    Frieght: new FormControl(''),


    startmeterReading: new FormControl(''),
    endmeterReading: new FormControl(''),
    tripSDate: new FormControl(''),
    tripEDate: new FormControl(''),
    outtime: new FormControl(''),
    INtime: new FormControl(''),
    noofTools: new FormControl(''),
    tooltaxPaid: new FormControl(''),
    labourPaid: new FormControl(''),
    otherPayment: new FormControl(''),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl(''),
    detention: new FormControl(''),
    advance: new FormControl(''),
    balance: new FormControl(''),
    balancePaid: new FormControl(''),
    vehicleRate: new FormControl(''),
    LR_no: new FormControl('', [Validators.required]),

  })
  for_created_date: any;
  invoice_data: any;
  finl_data: any = [];
  sys_inv: any;
  createddate: any;
  cgst: number;
  sgst: number;
  advance: any;
  amount_in_words: any;
  allclientdata: any;
  invoiceclientdata: any;
  companystatecode: any;
  total_invoice_amount: any;
  total_amount: any;
  cons_gst: any;
  cons_add: any;
  consi_name: any;
  for_clie_name: any;
  clieeeeeee: any;
  com_pin_no: any;
  city: any;
  com_address: any;
  com_state: any;
  for_clie_names: any;
  dataaa: any;
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
    })
  }

  ngOnInit(): void {
    this.spinner.show();
    //this.getClientList()

  }

  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.getClientList();
          this.invoiceclientdata = rawdata;
          this.createddate = rawdata[0].created_date;
          this.sys_inv = rawdata[0].sys_invoice;
          this.checkInvoiceType = rawdata[0].invoiceType;
          this.users = rawdata[0].invoice;
          this.cons_gst = this.users[0].consignor_GSTIN;
          this.cons_add = this.users[0].consignor_address;
          this.consi_name = this.users[0].consignor_name
          this.for_created_date = rawdata.map((x) => x.created_date)
          this.checkInvoiceType = rawdata[0].invoiceType;
          this.users = rawdata[0].invoice;
          this.total_amount = this.users[0].total_invoice_amount
          this.lrID = rawdata[0].invoice[0]._id;



          //this.getLRDetail(this.lrID)
          // console.log(this.lrID)

          this.users.forEach((value: any) => {
            let total_invoice_amount
              = value.total_invoice_amount
              ;

            this.invtot = total_invoice_amount
              .reduce((a, b) => parseInt(a) + parseInt(b), 0)

            //console.log("this.invtot",this.invtot)
            let advance = value.advance;
            this.advance = advance.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            this.invtot = total_invoice_amount
              .reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);
            this.cgst = (this.invtot * 6) / 100;
            // console.log("%%%%%%",this.cgst)
            this.sgst = (this.invtot * 6) / 100;
            let amount_in_words = this.cgst + this.sgst + this.invtot - this.advance;
            this.amount_in_words = amount_in_words


            // console.log("amount_in_words",amount_in_words)
            // this.totalInvValueWithGST = this.invtot + 2*(this.fivePercent) + this.eightPercent;
            //this.totalGstSstSum = 2*(this.cgst)

            //this.totalInvValueWithGST = this.invtot + 2*(this.cgst);
          });
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);

            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);

          }
        }

      }, error => {
      });
  }



  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
         // this.spinner.hide();
          var rawdata = res.data;
          this.allclientdata = rawdata;
          this.for_clie_name = this.allclientdata;
          console.log("this.for_clie_name", this.for_clie_name);
          console.log("invoiceclientdata1", this.invoiceclientdata);
          this.for_clie_names = this.invoiceclientdata;
          console.log(this.for_clie_names);
          for (let i = 0; i < this.for_clie_name.length; i++) {
            console.log("ttt,,,",this.for_clie_name[i]._id, this.for_clie_names[0]?.clientName)
            if ((this.for_clie_name[i]._id) === (this.for_clie_names[0]?.clientName)) {
              this.dataaa = this.for_clie_name[i]
              console.log("working");
            }
          }
          console.log("dataaa", this.dataaa);
          this.spinner.hide();
          for (let i = 0; i < this.allclientdata.length; i++) {
            //debugger;
            if (this.allclientdata[i]._id == this.invoiceclientdata[0].clientName) {
              let companystatecode = this.allclientdata[i].company_state_code;
              this.companystatecode = companystatecode;
            }
          }
          this.clientList = rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          } else {
            this.toastr.success(res.message);
           // this.spinner.hide();
          }
        }

      }, error => {
      });

  }







  sendInvoice(divName) {
    var data = document.getElementById(divName);
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png', 0.3)
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
      let binary = pdf.output();
      binary = binary ? btoa(binary) : '';
      this.superAdminService.sendInvoice(this.paramId, binary).subscribe((res: any) => {
        if (res.status == true) {
          this.toastr.success(res.message);
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
        // console.log('error:');
        // console.log(error);
      });
    });

  }
  backTo() {
    window.history.back()
  }

}
