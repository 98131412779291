<div class="header">
    <h1>Advance</h1>
    <button mat-dialog-close>
        <img src="../../../../assets/images/svg/closeBtn.svg" alt="" srcset="">
    </button>
</div>
<div class="scrollHeightDialog">
    <div class="row m-0 py-4">
        <div class="col-xl-11 mx-auto" [formGroup]="add_advance_exp" (ngSubmit)="advanceExpenseSubmit()">
            <div class="row">
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Date of Expenses<sup text="danger">*</sup></label>
                        <input type="date" class="form-control" formControlName="Avance_date_of_expenses" />
                        <span class="text-danger"
                            *ngIf="ff.Avance_date_of_expenses.invalid && (ff.Avance_date_of_expenses.touched || submitBtn)">
                            Please Enter Advance Date
                        </span>
                    </div>
                </div>

                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Advance Amount<sup text="danger">*</sup></label>
                        <input type="text" class="form-control" formControlName="advance_amount"
                            placeholder="Expense" />
                            <span class="text-danger"
                            *ngIf="ff.advance_amount.invalid && (ff.advance_amount.touched || submitBtn)">
                            Please Enter Advance Amount
                        </span>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="form-group">
                        <label for="">Payment Mode<sup text="danger">*</sup></label>
                        <select name="" class="form-control form-select " id="" formControlName="Payment_mode">
                            <option disabled value="">Select Mode</option>
                            <option value="UPI">UPI</option>
                            <option value="Cash">Cash</option>
                            <option value="Petro Card">Petro Card</option>
                        </select>
                        <span class="text-danger"
                            *ngIf="ff.Payment_mode.invalid && (ff.Payment_mode.touched || submitBtn)">
                            Please Enter Advance Payment Mode
                        </span>
                    </div>
                </div>
                <div class="col-xl-12 mb-3">
                    <div class="form-group">
                        <label for="">Description</label>
                        <textarea type="text" class="form-control" formControlName="description"
                            placeholder="Description"></textarea>
                    </div>
                </div>


                <!-- <div class="col-lg-4 col-xl-4  col-sm-6 col-12 col-md-4">
                    <div class="form-group accountsinp  mb-3">
                        <label for="">Advance Slip </label>
                        <input type="file" accept="image/*,.pdf,.doc"
                            *ngIf="!fuelSlipPreview" id="cancelledChequectrl"
                            (change)="fileChangeEventPODAttchment($event)"
                            class="form-control shadow-none d-none"
                            placeholder="Enter advance_slip" formControlName="advance_slip">
                        <label class="uploadimgNewDesign" for="cancelledChequectrl"
                            >
                            <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                            <h5>Upload Fuel Slip </h5>
                        </label>
                        <div class="viewImage" >
                            <div class="imgBoxp">
                                <button class="resetImg" (click)="resetimg('fuelSlip')"><i
                                        class="fa-solid fa-xmark"></i></button>
                                <img [id]="fuelSlipPreview"
                                    onClick="viewImages(this, 'fuelSlip')"
                                    [src]="fuelSlipPreview" alt="Fuel Slip" />
                            </div>
                        </div>
                    </div>

                </div> -->

                <!-- <div class="col-xl-12">
                    <div class="detailsBox">
                        <h4>Attach Advance Slip</h4>
                        <div class="row">
                            <div class="col-lg-2 col-xl-2 col-sm-6 col-12 col-md-4">
                                <div class="form-group accountsinp  mb-3">
                                    <input type="file" accept="image/*,.pdf,.doc"
                                        (change)="fileChangeEventPODAttchment($event)" id="LRReceipt1ctrl"
                                        class="form-control shadow-none d-none" formControlName="advance_slip">
                                    <label class="uploadimgNewDesign" for="LRReceipt1ctrl" *ngIf="!Attachments">
                                        <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                        <h5>Upload Advance Expense Attachments</h5>
                                    </label>
                                    <div class="viewImage" *ngIf="Attachments">
                                        <div class="imgBoxp">
                                            <button class="resetImg" (click)="resetimg('attechment')"><i
                                                    class="fa-solid fa-xmark"></i></button>
                                            <img [id]="Attachments"
                                                onClick="viewImages(this, 'Vehicle Attachments')"
                                                [src]="Attachments" alt="Vehicle Attachments" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-4">
                    <div class="form-group accountsinp  mb-3">
                        <h4>Attach Advance Slip</h4>
                        <div class="row">
                            <div class="form-group accountsinp  mb-3">
                                <input type="file" accept="image/*,.pdf,.doc"
                                    (change)="fileChangeEventPODAttchment($event)" id="LRReceipt1ctrl"
                                    class="form-control shadow-none d-none" formControlName="advance_slip">
                                <label class="uploadimgNewDesign" for="LRReceipt1ctrl" *ngIf="!Attachments">
                                    <img src="../../../../assets/images/svg/uploadImageNew.svg" />
                                    <h5>Upload Advance Expense Attachments</h5>
                                </label>
                                <div class="viewImage" *ngIf="Attachments">
                                    <div class="imgBoxp">
                                        <button class="resetImg" (click)="resetimg('attechment')"><i
                                                class="fa-solid fa-xmark"></i></button>
                                        <img [id]="Attachments" onClick="viewImages(this, 'Advance Expense')"
                                            [src]="Attachments" alt="Vehicle Attachments" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->




                <div class="col-xl-12 text-center">
                    <button class="btn btn-dark py-2 px-5" mat-raised-button
                        (click)="advanceExpenseSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>