import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-add-driver-expense',
  templateUrl: './add-driver-expense.component.html',
  styleUrls: ['./add-driver-expense.component.css']
})
export class AddDriverExpenseComponent implements OnInit {

  paramId: any;
  particularDriverExpense: any;
  id: any;
  submitBtn: boolean = false;

  driver_cash = this.formBuilder.group({
    dateOfPayment: ['', Validators.required],
    driverName: ['', Validators.required],
    amountPaid: ['', Validators.required],
    payment: ['', Validators.required],
    lr_ID: this.data.add

  })


  constructor(public formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public activatedRoute: ActivatedRoute, public superAdminServices: SuperAdminService, public toaster: ToastrService, public router: Router,public dialogRef: MatDialogRef<AddDriverExpenseComponent>) {
    this.activatedRoute.params.subscribe((param) => {
      this.id = param.id;
      if(this.data.edit != undefined){
      this.getParticularDriverCash(this.data.edit)
      }
    })

  }

  ngOnInit(): void {
    // console.log(this.data.edit)

  }

  driverCashSubmit() {
    // console.log("workin")
    if (this.data.add) {
      this.submitBtn = true;
      if (this.driver_cash.valid) {
        let formdata = this.driver_cash.value
        this.superAdminServices.addDriverCash({ formdata }).subscribe((res: any) => {
          if (res.status == true) {
            this.toaster.success(res.message)
            this.dialogRef.close(res.status);
            // this.router.navigateByUrl('/superAdmin/lrmanagement/view-lr', this.data.add)

          } else {
            if (res.status == 401) {
              this.toaster.error(res.message)
            } else {
              this.toaster.error(res.message)
            }
          }
        })
      }
    } else {
      if (this.data.edit) {
        this.submitBtn = true;
        let formData = {

          dateOfPayment: this.driver_cash.get('dateOfPayment').value,
          driverName: this.driver_cash.get('driverName').value,
          amountPaid: this.driver_cash.get('amountPaid').value,
          payment: this.driver_cash.get('payment').value,
          id: this.data.edit


        }
        // console.log(formData)
        this.superAdminServices.editDriverExpense({ formData }).subscribe((res: any) => {
          if (res.status == true) {
            this.toaster.success(res.message)
            this.dialogRef.close(res.status);
          } else {
            if (res.status == 401) {
              this.toaster.error(res.message)
            } else {
              this.toaster.error(res.message)
            }
          }
        })
      }
    }
  }

  getParticularDriverCash(id: any) {
    this.superAdminServices.getParticularDriverCash(id).subscribe((res: any) => {
      if (res.status == true) {
        this.particularDriverExpense = res.data[0]
        // console.log(this.particularDriverExpense)
        this.initForm()
      }else{
        if(res.status == 401){
          this.toaster.error(res.message)
        }else{
          this.toaster.error(res.message)
        }
      }
    })
  }

  initForm() {
    // console.log("amount",this.particularDriverExpense.payment_mode)
    this.driver_cash.patchValue({

      dateOfPayment: this.particularDriverExpense.date_of_payment,
      driverName: this.particularDriverExpense.driver_name,
      amountPaid: this.particularDriverExpense.amount_paid,
      payment: this.particularDriverExpense.payment_mode,

    })
  }

}
