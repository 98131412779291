<div class="mainebox">
    <div class="leftpart">

    </div>

    <div class="container-fluid">
        <div class="row justify-content-between manageaccount mt-3">
            <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
                <div class="d-flex align-items-center justify-content-between">
                    <h1 class="fs-4 fw-bold">{{pageName}}</h1>
                    <button class="btn btn-dark btn-sm" matTooltip="Back" matTooltipPosition="left" mat-raised-button
                        (click)="backTo()">
                        <i class="fa-solid fa-arrow-left"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="contentbox  shadow-none mt-3">
            <div class="row justify-content-end">
                <div class="col-md-3">
                    <div class="table-responsive">
                        <input class="form-control search-box" #data placeholder="search" type="text"
                            (keyup)="filterData(data.value)" />
                    </div>
                </div>
                <div class="row customepadding">
                    <div class="col-md-12">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort matSortActive="created_date"
                                matSortDirection="desc" (matSortChange)="sortData($event)"
                                class="row-border hover tablelist">
                                <ng-container matColumnDef="sno">
                                    <th mat-header-cell *matHeaderCellDef>S.no</th>
                                    <td mat-cell *matCellDef="let ids; let i = index">
                                        {{ paginator.pageIndex * paginator.pageSize + (i + 1) }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="tripID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        TripID
                                    </th>
                                    <td mat-cell *matCellDef="let ids">{{ ids.TripID }}</td>
                                </ng-container>

                                <ng-container matColumnDef="created_date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                                    <td mat-cell *matCellDef="let ids">
                                        {{ ids.created_date | date : "yyyy-MM-dd" }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                    <td mat-cell *matCellDef="let receipt">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <button [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"
                                                class="btn btn-block p-0 shadow-none"><i matTooltip="View"
                                                    class="fas fa-print"></i></button>
                                            <button [routerLink]="['/superAdmin/lrmanagement/invoice', receipt._id]"
                                                class="btn btn-block p-0  shadow-none"> <i matTooltip="Detail"
                                                    class="fas fa-eye"></i>
                                            </button>

                                            <button (click)="deleteInvoice(receipt._id)" class="btn btn-block">
                                                <i matTooltip="Delete" class="fa fa-trash"></i>
                                            </button>

                                        </div>
                                    </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>

                            <mat-paginator #paginator [length]="100" [pageSize]="10"
                                [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
    </div>