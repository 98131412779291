<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">View LR</li>

                </ol>
            </nav>
        </div>

    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="editLR">
                <div class="contentbox">
                    <div class="row account">

                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Basic Details ({{ this.particlarLR?.LR_no }})</h4>
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Manual LR No.</label>
                                        <h5>{{particlarLR?.manualLR_no ? particlarLR?.manualLR_no : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">System LR No.</label>
                                        <h5>{{particlarLR?.LR_no ? particlarLR?.LR_no : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Supervisor Name</label>
                                        <h5>{{particlarLR?.supervisorId ? particlarLR?.supervisorId : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Company Name</label>
                                        <h5>{{clientNames}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignor Name</label>
                                        <h5>{{particlarLR?.consignor_name ? particlarLR?.consignor_name : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignor Address</label>
                                        <h5>{{particlarLR?.consignor_address ? particlarLR?.consignor_address : 'N/A' }}
                                        </h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignee Name</label>
                                        <h5>{{particlarLR?.consignee_name ? particlarLR?.consignee_name : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Consignee Address</label>
                                        <h5>{{particlarLR?.consignee_address ? particlarLR?.consignee_address : 'N/A' }}
                                        </h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle No</label>
                                        <h5>{{particlarLR?.vehicle_id ? particlarLR?.vehicle_id : 'N/A' }}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle Type</label>
                                        <h5>{{particlarLR?.vehicleType ? particlarLR?.vehicleType : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">From</label>
                                        <h5>{{particlarLR?.from ? particlarLR?.from : 'N/A'}}</h5>
                                    </div>


                                    <div class="col-xl-3 mb-3">
                                        <label for="">To</label>
                                        <h5>{{particlarLR?.to ? particlarLR?.to : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">No. Of Packages</label>
                                        <h5>{{particlarLR?.no_of_package ? particlarLR?.no_of_package : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Description</label>
                                        <h5>{{particlarLR?.description? particlarLR?.description : 'N/A'}}</h5>
                                    </div>


                                    <div class="col-xl-3 mb-3">
                                        <label for="">Date</label>
                                        <h5>{{particlarLR?.date | date}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Invoice No.</label>
                                        <h5>{{particlarLR?.InvoiceNo? particlarLR?.InvoiceNo : 'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">E-way Bill Number</label>
                                        <h5>{{particlarLR?.road_permit_no? particlarLR?.road_permit_no : 'N/A'}}</h5>
                                    </div>
                                    <!-- <div class="col-xl-3 mb-3">
                                        <label for="">E-way Bill Expire Date</label>
                                        <h5>{{particlarLR?.ewaybillexpirydate | date}}</h5>
                                    </div> -->
                                    <div class="col-xl-3 mb-3">
                                        <label for="">State Code</label>
                                        <h5>{{particlarLR?.stateCode? particlarLR?.stateCode : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">FIX / Adhoc</label>
                                        <h5>{{particlarLR?.fixAdhoc? particlarLR?.fixAdhoc : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Quantity/Weight</label>
                                        <h5>{{particlarLR?.quantity ? particlarLR?.quantity : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Transporter Name</label>
                                        <h5>{{particlarLR?.transportorName? particlarLR?.transportorName : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Transporter Mobile</label>
                                        <h5>{{particlarLR?.transportorMobile ? particlarLR?.transportorMobile : 'N/A'}}
                                        </h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Name</label>
                                        <h5>{{particlarLR?.driverName ? particlarLR?.driverName : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Mobile</label>
                                        <h5>{{particlarLR?.driverMobile ? particlarLR?.driverMobile : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Freight</label>
                                        <h5>{{particlarLR?.Frieght ? particlarLR?.Frieght : 'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">POD</label>
                                        <!-- <h5>{{particlarLR?.driverName}}</h5> -->
                                        <div class="viewImage">
                                            <img [id]="apiUrl+'/'+particlarLR?.loadingSlipAttchment[0]"
                                                onClick="viewImages(this, 'POD Front')"
                                                *ngIf="particlarLR?.loadingSlipAttchment[0]"
                                                [src]="sanitizeImageUrl(apiUrl+'/'+particlarLR?.loadingSlipAttchment[0])"
                                                alt="POD Front" />
                                            <img [id]="apiUrl+'/'+particlarLR?.loadingSlipAttchmentBack[0]"
                                                onClick="viewImages(this, 'POD Back')"
                                                *ngIf="particlarLR?.loadingSlipAttchmentBack[0]"
                                                [src]="sanitizeImageUrl(apiUrl+'/'+particlarLR?.loadingSlipAttchmentBack[0])"
                                                alt="POD Back" />
                                        </div>
                                        <h5 *ngIf="!particlarLR?.loadingSlipAttchment[0]">N/A</h5>
                                        <!-- <div>
                                            <button class="btn btn-dark btn-sm  px-4" type="button"
                                                (click)="previewLoadingSlip(particlarLR?.loadingSlipAttchment[0], particlarLR?.loadingSlipAttchmentBack[0])"
                                                mat-raised-button>Preview</button>
                                        </div> -->
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Use Same TripID:</label>
                                        <h5>{{particlarLR?.TripID}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Information For office use only</h4>
                                <div class="row">

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Bill Ch. No.</label>
                                        <h5>{{particlarLR?.billchno ? particlarLR?.billchno :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Extra Hours</label>
                                        <h5>{{particlarLR?.extrahour ? particlarLR?.extrahour :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Extra KM charge</label>
                                        <h5>{{particlarLR?.extraKmCharges ? particlarLR?.extraKmCharges : 'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Waiting charges</label>
                                        <h5>{{particlarLR?.waitingCharge ? particlarLR?.waitingCharge :'N/A'}}</h5>
                                    </div>



                                    <div class="col-xl-3 mb-3">
                                        <label for="">Vehicle Rate</label>
                                        <h5>{{particlarLR?.vehicleRate ? particlarLR?.vehicleRate :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Overload charges</label>
                                        <h5>{{particlarLR?.overLoadCharges ? particlarLR?.overLoadCharges :'N/A' }}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Advance</label>
                                        <h5>{{particlarLR?.advance ? particlarLR?.advance :'N/A'}}</h5>
                                    </div>
                                    <!-- <div class="col-xl-3 mb-3">
                                        <label for="">Advance</label>
                                        <h5>{{ (this.advance_sum ? this.advance_sum  :  particlarLR?.advance)}}</h5>
                                        <h5>{{ particlarLR?.advance ? particlarLR?.advance :  (this.advance_sum ? this.advance_sum :'N/A')}}</h5>
                                    </div> -->

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Actual Balance</label>
                                        <h5>{{particlarLR?.actual_balance ? particlarLR?.actual_balance :'N/A'}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 mb-4">
                            <div class="detailsBox">
                                <h4>Expense Details</h4>
                                <div class="row">
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Number of Tolls</label>
                                        <h5>{{particlarLR?.noofTools ? particlarLR?.noofTools:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Total Toll Tax Paid</label>
                                        <h5>{{particlarLR?.tooltaxPaid ? particlarLR?.tooltaxPaid:'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Labour Loading / Unloading</label>
                                        <h5>{{particlarLR?.labourPaid ? particlarLR?.labourPaid :'N/A'}}</h5>
                                    </div>
                                    <div class="col-xl-3 mb-3">
                                        <label for="">Other payment</label>
                                        <h5>{{particlarLR?.otherPayment ? particlarLR?.otherPayment :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Detention</label>
                                        <h5>{{particlarLR?.detention ? particlarLR?.detention :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Diesel Expense</label>
                                        <h5>{{particlarLR?.diesel_expense ? particlarLR?.diesel_expense :'N/A'}}</h5>
                                    </div>

                                    <!-- <div class="col-xl-3 mb-3">
                                        <label for="">Diesel Expense</label>
                                        <h5>{{Fuelsum? Fuelsum : particlarLR?.diesel_expense}}</h5>
                                    </div> -->

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Driver Cash</label>
                                        <h5>{{particlarLR?.driver_cash ? particlarLR?.driver_cash :'N/A'}}</h5>
                                    </div>

                                    <!-- <div class="col-xl-3 mb-3">
                                        <label for="">Driver Cash</label>
                                        <h5>{{driverSum ? driverSum :particlarLR?.driver_cash}}</h5>
                                    </div> -->

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Truck Expense</label>
                                        <h5>{{particlarLR?.truck_expense ? particlarLR?.truck_expense :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Commission</label>
                                        <h5>{{particlarLR?.commission ? particlarLR?.commission :'N/A'}}</h5>
                                    </div>

                                    <div class="col-xl-3 mb-3">
                                        <label for="">Challan</label>
                                        <h5>{{particlarLR?.challan ? particlarLR?.challan :'N/A'}}</h5>
                                    </div>





                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 mb-4">
                            <div class="expanceBox">
                                <div class="expancetabs">
                                    <button [ngClass]="{'active' : isTab === 'fuelExpense'}"
                                        (click)="expenseTab('fuelExpense')">Fuel Expense History</button>
                                    <button [ngClass]="{'active' : isTab === 'driverExpense'}"
                                        (click)="expenseTab('driverExpense')">Driver Expense</button>
                                    <!-- <button [ngClass]="{'active' : isTab === 'truckExpense'}"
                                    (click)="expenseTab('truckExpense')">Truck Expense</button> -->

                                    <button [ngClass]="{'active' : isTab === 'advance_expense'}"
                                        (click)="expenseTab('advance_expense')">Advance</button>

                                    <!-- <button [ngClass]="{'active' : isTab === 'otherExpense'}"
                                    (click)="expenseTab('otherExpense')">Other Expense</button>  -->

                                </div>
                                <div class="filterBox">
                                    <div class="filterinner">
                                        <button class="btn border-0"><img
                                                src="../../../../assets/images/svg/filterIcon.svg" /></button>
                                        <h5>Date</h5>
                                        <input type="date" class="form-control" placeholder="from" name="fromDateFilter"
                                            id="fromDate" #fromDate>
                                        <input type="date" class="form-control" placeholder="to" name="toDateFilter"
                                            id="toDate" value="" #toDate>
                                        <button class="btn border-0" (click)="clear(fromDate,toDate)"><img
                                                src="../../../../assets/images/svg/clearfilter.svg" /></button>
                                        <button class="btn btn-dark btn-sm px-4" mat-raised-button
                                            (click)="checkin(fromDate.value, toDate.value )"> Apply</button>

                                    </div>

                                    <button *ngIf="isTab === 'fuelExpense'" class="btn btn-dark btn-sm"
                                        (click)="addExpence()" matTooltipPosition="left" mat-raised-button><i
                                            class="fa-solid fa-plus"></i> Add Fuel Expense</button>
                                    <button *ngIf="isTab === 'driverExpense'" class="btn btn-dark btn-sm"
                                        (click)="driverExpence()" matTooltipPosition="left" mat-raised-button><i
                                            class="fa-solid fa-plus"></i> Add Driver Cash</button>
                                    <!-- <button *ngIf="isTab === 'truckExpense'" class="btn btn-dark btn-sm"  (click)="truckExpence()" matTooltipPosition="left" mat-raised-button><i class="fa-solid fa-plus"></i> Add Truck Expense</button> -->
                                    <button *ngIf="isTab === 'advance_expense'" class="btn btn-dark btn-sm"
                                        (click)="advanceexp()" matTooltipPosition="left" mat-raised-button><i
                                            class="fa-solid fa-plus"></i> Add Advance</button>
                                    <!-- <button *ngIf="isTab === 'otherExpense'" class="btn btn-dark btn-sm"  (click)="addotherexpence()" matTooltipPosition="left" mat-raised-button><i class="fa-solid fa-plus"></i> Other  Expense</button>  -->
                                </div>
                                <div class="expanceContentBox p-3">
                                    <div class="table-responsive" *ngIf="isTab === 'fuelExpense'">

                                        <table mat-table [dataSource]="fuelExpenseDataSource" matSort
                                            matSortActive="date" matSortDirection="desc"
                                            (matSortChange)="sortData($event)" class="row-border hover tablelist">

                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let fuelEx; let i = index">
                                                    {{fuelEx.date_of_expenses}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="Fuel Quantity">
                                                <th mat-header-cell *matHeaderCellDef>Fuel Quantity</th>
                                                <td mat-cell *matCellDef="let fuelEx; let i = index">
                                                    {{fuelEx.fuel_quantity}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Fuel Type">
                                                <th mat-header-cell *matHeaderCellDef>Fuel Type</th>
                                                <td mat-cell *matCellDef="let fuelEx"> {{fuelEx.fuel_type}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Amount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
                                                <td mat-cell *matCellDef="let fuelEx"> {{fuelEx.fuel_expense_amount}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Driver Name">
                                                <th mat-header-cell *matHeaderCellDef>Drive Name</th>
                                                <td mat-cell *matCellDef="let fuelEx">{{fuelEx.driver_name}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="Action">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                                <td mat-cell *matCellDef="let fuelEx">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-fuel-expence', fuelEx._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editExpence(fuelEx._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>

                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteFuelExpense(fuelEx._id)"><i
                                                                matTooltip="Delete" class="fa fa-trash"
                                                                aria-hidden="true"></i></button>

                                                        <!-- <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-lr', receipt._id]"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Print"
                                                                class="fas fa-print"></i></button> -->
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                        </table>

                                    </div>
                                    <div class="table-responsive" *ngIf="isTab === 'driverExpense'">

                                        <table mat-table [dataSource]="dataSource2" matSort matSortActive="date"
                                            matSortDirection="desc" (matSortChange)="sortData($event)"
                                            class="row-border hover tablelist">

                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let dc; let i = index"> {{dc.date_of_payment}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Amount Paid">
                                                <th mat-header-cell *matHeaderCellDef>Amount Paid</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.amount_paid}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Payment Mode">
                                                <th mat-header-cell *matHeaderCellDef>Payment Mode</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.payment_mode}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Drive Name">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Drive Name</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Action">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                                                <td mat-cell *matCellDef="let dc">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-driver-cash', dc._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editdriverExpence(dc._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>


                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteDriverCash(dc._id)"><i matTooltip="Delete"
                                                                class="fa fa-trash" aria-hidden="true"></i></button>

                                                        <!-- <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-lr', dc._id]"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Print"
                                                                class="fas fa-print"></i></button> -->
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="driverExpenseColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: driverExpenseColumns;"></tr>

                                        </table>

                                    </div>
                                    <div class="table-responsive d-none" *ngIf="isTab === 'truckExpense'">

                                        <table mat-table [dataSource]="truckexpense" matSort matSortActive="date"
                                            matSortDirection="desc" (matSortChange)="sortData($event)"
                                            class="row-border hover tablelist">

                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let dc; let i = index"> {{dc.date_of_payment}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Expense Type">
                                                <th mat-header-cell *matHeaderCellDef>Expense Type</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.amount_paid}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Expense Amount">
                                                <th mat-header-cell *matHeaderCellDef>Expense Amount</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.payment_mode}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Expense location">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Expense location
                                                </th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Description">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Payment Mode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="Action">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Attachment
                                                </th>
                                                <td mat-cell *matCellDef="let dc">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-driver-cash', dc._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editdriverExpence(dc._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>


                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteDriverCash(dc._id)"><i matTooltip="Delete"
                                                                class="fa fa-trash" aria-hidden="true"></i></button>

                                                        <!-- <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-lr', dc._id]"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Print"
                                                                class="fas fa-print"></i></button> -->
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="driverExpenseColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: driverExpenseColumns;"></tr>

                                        </table>

                                    </div>
                                    <div class="table-responsive" *ngIf="isTab === 'advance_expense'">

                                        <table mat-table [dataSource]="advance_expenses" matSort matSortActive="date"
                                            matSortDirection="desc" (matSortChange)="sortData($event)"
                                            class="row-border hover tablelist">

                                            <ng-container matColumnDef="date">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">
                                                    {{dc.Avance_date_of_expenses | date : 'dd-MM-YYYY'}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="advance_amount">
                                                <th mat-header-cell *matHeaderCellDef>Advance Amount</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.advance_amount}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="description">
                                                <th mat-header-cell *matHeaderCellDef>Description</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.description}}</td>
                                            </ng-container>

                                            <ng-container matColumnDef="payment_mode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode
                                                </th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.Payment_mode}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Action
                                                </th>
                                                <td mat-cell *matCellDef="let dc">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-advance-cash', dc._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editadvanceexpense(dc._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>
                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteadvanceExpense(dc._id,dc.lr_ID)"><i matTooltip="Delete"
                                                                class="fa fa-trash" aria-hidden="true" ></i></button>
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="action">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> action
                                                </th>
                                                <td mat-cell *matCellDef="let dc">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-driver-cash', dc._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editdriverExpence(dc._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>


                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteDriverCash(dc._id)"><i matTooltip="Delete"
                                                                class="fa fa-trash" aria-hidden="true"></i></button>

                                                        </div>
                                                </td>
                                            </ng-container> -->
                                            <tr mat-header-row *matHeaderRowDef="advanceExpenseColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: advanceExpenseColumns;"></tr>
                                        </table>
                                    </div>
                                    <div class="table-responsive d-none" *ngIf="isTab === 'otherExpense'">

                                        <table mat-table [dataSource]="otherexpence" matSort matSortActive="date"
                                            matSortDirection="desc" (matSortChange)="sortData($event)"
                                            class="row-border hover tablelist">

                                            <ng-container matColumnDef="Date">
                                                <th mat-header-cell *matHeaderCellDef>Date</th>
                                                <td mat-cell *matCellDef="let dc; let i = index"> {{dc.date_of_payment}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Expense type">
                                                <th mat-header-cell *matHeaderCellDef>Expense type</th>
                                                <td mat-cell *matCellDef="let dc; let i = index">{{dc.amount_paid}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Description">
                                                <th mat-header-cell *matHeaderCellDef>Description</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.payment_mode}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Expense Amount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Expense Amount
                                                </th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Payment Mode">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
                                                <td mat-cell *matCellDef="let dc">{{dc.driver_name}}</td>
                                            </ng-container>
                                            <ng-container matColumnDef="Attachments">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Attachments
                                                </th>
                                                <td mat-cell *matCellDef="let dc">
                                                    <div class="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-driver-cash', dc._id]"
                                                            class="btn btn-block p-0  shadow-none">
                                                            <i matTooltip="View" class="fas fa-eye"></i>
                                                        </button>
                                                        <button (click)="editdriverExpence(dc._id)"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Edit"
                                                                class="fa-solid fa-pen-to-square"></i></button>


                                                        <button class="btn btn-block p-0  shadow-none"
                                                            (click)="deleteDriverCash(dc._id)"><i matTooltip="Delete"
                                                                class="fa fa-trash" aria-hidden="true"></i></button>

                                                        <!-- <button
                                                            [routerLink]="['/superAdmin/lrmanagement/view-lr', dc._id]"
                                                            class="btn btn-block p-0 shadow-none"><i matTooltip="Print"
                                                                class="fas fa-print"></i></button> -->
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="driverExpenseColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: driverExpenseColumns;"></tr>

                                        </table>

                                    </div>
                                    <!-- <mat-paginator #paginator [length]="100" [pageSize]="10"
                                        [pageSizeOptions]="[5, 10, 25,100]">
                                    </mat-paginator> -->
                                    <mat-paginator #paginator [length]="100" [pageSize]="10"
                                        [pageSizeOptions]="[5, 10, 25, 100]">
                                    </mat-paginator>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </form>
        </div>


    </div>
</div>